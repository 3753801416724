// 页面操作的对象
import biz from "./biz";
import business from "./business";
import common from "./common";
import noticeMessage from "./noticeMessage";
import {setConsumeList, setShowSelectContentType} from "../reducers/selectContent";
import {setModalShowType} from "../reducers/app";
import {
  setShowCheckoutContentType,
  setActiveServiceType,
  setPreActiveServiceType, setCheckoutBills,
} from "../reducers/checkoutContent";
import {setSelectedCardList, setPayCardOrderTotalFee, setPayCardOrderDiscount} from "../reducers/checkoutPayCard";
import {
  setOrder,
  setExistPaymentList
} from "../reducers/checkoutPay";
import print from "./print";
import {
  setMemberShowType,
  setMemberShowMemberId
} from "../reducers/member";
import {baseApi} from "../config-local";
import axios from "axios";

let businessAction = {}

// 加钟
businessAction.billAddClock = {
  bill: '',

  bill_data: function () {
    var arr = businessAction.billAddClock.bill;
    if (arr.length === 0) {
      return arr;
    }

    //处理手牌
    var handNumber = biz.indexOfReturnInObj(arr['hand_id'], business.handNumberObjById)

    arr = {
      ...arr,
      hand_number: handNumber ? handNumber['number'] : '-'
    }

    var projectObjById = business.projectObjById

    var roomObjById = business.roomObjById

    var employeeObjByEmployeeId = business.employeeObjByEmployeeId

    var starLevelObjById = {}
    if (business.employee_star_level_list) {
      for (let nn = 0; nn < business.employee_star_level_list.length; nn++) {
        starLevelObjById[business.employee_star_level_list[nn]['id']] = business.employee_star_level_list[nn]
      }
    }

    //处理消费数据
    var tempConsume = [];
    for (var j = 0; j < arr['consume'].length; j++) {
      var t = arr['consume'][j];

      //处理钟类型
      var clockTypeString = business.clock_type_obj[t['clock_type']];
      if (clockTypeString) {
        clockTypeString = clockTypeString['name'];
      } else {
        clockTypeString = '-';
      }
      t.clockTypeString = clockTypeString;

      //项目名称
      var project = biz.indexOfReturnInObj(t['project_id'], projectObjById)
      t.project_string = project ? project['name'] : '';

      //项目照片
      t.project_cover = project ? project['cover_url'] : '';

      //房间编号
      var room = biz.indexOfReturnInObj(t['room_id'], roomObjById);
      t.room_number = room ? room['number'] : '';

      //技师编号
      var employee_info = '';
      for (var k = 0; k < t['work'].length; k++) {
        let ttTempEmployeeId = t['work'][k]['employee_id']
        var employee = biz.indexOfReturnInObj(ttTempEmployeeId, employeeObjByEmployeeId);
        var starLevelString = biz.indexOfReturnInObj(employee['star_level'], starLevelObjById)

        var tempData = '';
        tempData = employee['number'] + ' ' + employee['name'];

        if (starLevelString) {
          tempData = tempData + ' ' + starLevelString['value'];
        } else {
          tempData = tempData + ' -';
        }

        employee_info = employee_info + tempData + ',';
      }

      if (employee_info.toString().length > 0) {
        employee_info = employee_info.substring(0, employee_info.length - 1);
      }
      t.employee_info = employee_info;

      //加钟数量
      t.add_quantity = 0;

      tempConsume.push(t);
    }

    arr = {
      ...arr,
      'consume': tempConsume
    }

    return arr;
  }
}

// 开单选手牌
businessAction.beginBill = {
  batch_id: '',
  initNumber: '',
  sell_employee_id: 0,
  selected_service_type: '10', //服务方式
  selected_hand_numbers: [],

  //更新批次ID
  updateBatchId: function (value) {
    businessAction.beginBill.batch_id = value;
  },

  //更新服务方式
  updateServiceType: function (value) {
    businessAction.beginBill.selected_service_type = value;
  },

  //删除其中一个手牌
  deleteHandNumber: function (handNumber) {
    var number = handNumber['number'];

    biz.removeArrayByColumn('number', number, businessAction.beginBill.selected_hand_numbers);
  },

}

// 开单过程：添加消费
businessAction.selectContent = {
  data: {
    hand_number: '', //当前开单的手牌号 对象 手牌的所有数据
    show: 'project', //默认为项目
    consume_list: [], //消费项目

    select_project_id: '', //此次流程中的已选项目ID

    remark: '', //备注

    clock: biz.dateTime().clock,

    check_many_project: {'check': false}, //是否为多人增加同一个项目

    beginBillRoomContent: business.beginBillRoomContentList.number,
  },

  computed: {
    // begin_bill_room_content: function() {
    //   return businessAction.selectContent.data.beginBillRoomContent
    // },

    // check_many_project_data: function () {
    //   return businessAction.selectContent.data.check_many_project;
    // },

    //当前开单过程的消费数据
    consume_list_data: function () {
      var projectObjById = business.projectObjById

      var roomObjById = businessAction.selectContent.computed.rooms_data['obj']

      var employeeObjByEmployeeId = business.employeeObjByEmployeeId

      var starLevelObjById = {}
      if (business.employee_star_level_list) {
        for (let nn = 0; nn < business.employee_star_level_list.length; nn++) {
          starLevelObjById[business.employee_star_level_list[nn]['id']] = business.employee_star_level_list[nn]
        }
      }

      var arr = businessAction.selectContent.data.consume_list;
      var data = [];
      for (var i = 0; i < arr.length; i++) {
        var project = biz.indexOfReturnInObj(arr[i]['project_id'], projectObjById);
        var room = biz.indexOfReturnInObj(arr[i]['room_id'], roomObjById);

        if (arr[i]['room_id'] && (!room)) {
          common.alert("房间数据状态异常");
          return;
        }

        var work = [];
        var clock_type = false;
        var clock_type_string = false;

        if (arr[i]['work'].length > 0) {
          for (var j = 0; j < arr[i]['work'].length; j++) {
            var tempEmployeeId = arr[i]['work'][j]['employee_id']
            var employee = false;
            if (typeof employeeObjByEmployeeId[tempEmployeeId] !== "undefined") {
              employee = employeeObjByEmployeeId[tempEmployeeId]
            }
            // var employee = biz.inArrayByColumn('employee_id', tempEmployeeId, business.employees);
            var star_level = false
            if (typeof starLevelObjById[employee['star_level']] !== "undefined") {
              star_level = starLevelObjById[employee['star_level']]
            }
            // var star_level = biz.inArrayByColumn('id', employee['star_level'], business.employee_star_level_list);
            var tt = {
              star_level: star_level,
              employee: employee
            };

            work.push(tt);
          }

          if (arr[i]['work'][0]['clock_type']) {
            clock_type = arr[i]['work'][0]['clock_type']
            clock_type_string = '';
            if (clock_type in business.clock_type_obj) {
              clock_type_string = business.clock_type_obj[clock_type];
            }
          }
        }

        var roomStr = ''

        if (room) {

          // 20220419: 废弃，房间名和房间号的同时展示处理，改为由配置项决定显示的是房间名还是房间号
          // if ((/^[0-9]+$/.test(room['name'])) && ((parseInt(room['number'])) == (parseInt(room['name'])))) {
          //   roomStr = room['number']
          // } else {
          //   roomStr = room['number'] + ' ' + room['name']
          // }

          if (businessAction.selectContent.data.beginBillRoomContent == business.beginBillRoomContentList.number) {
            roomStr = room['number']
          } else {
            roomStr = room['name']
          }

        }

        // 已选品项的消费时长
        var consumeServiceTime = business.functions.getServiceTimeForCreateConsume(project, clock_type)

        var temp = {
          project: project,
          quantity: arr[i]['quantity'],
          service_type: arr[i]['service_type'],
          work: work,
          room: room,
          roomStr: roomStr,
          service_begin: arr[i]['service_begin'],
          clock_type_string: clock_type_string,
          price: arr[i]['price'],
          service_time: consumeServiceTime
        };

        data.push(temp);
      }

      return data;
    },

    //当前开单过程的手牌号
    hand_number_data: function () {
      return businessAction.selectContent.data.hand_number;
    },

    //正常状态的品项
    projects_data: function () {
      return business.projectNormalList
    },

    //正常状态的房间  {'obj': obj, 'arr': arr}  obj是根据房间ID为key的，arr是数组，用来前端展示的
    rooms_data: function () {
      var arr = business.roomNormalList

      // 房间排序
      return business.functions.sortRoomList(arr, business.room_type_list);
    },

    //正常状态的技师
    employees_data: function () {
      var arr = [];

      let employees = business.employees;

      for (var i = 0; i < employees.length; i++) {
        if (employees[i]['status'] == business.employeeStatus.status_yes && employees[i]['type'] == business.employeeTypeList.employee) {
          var temp = employees[i];
          if (temp['sex'] == '1') {
            temp['sex_string'] = '男';
          } else if (temp['sex'] == '2') {
            temp['sex_string'] = '女';
          } else {
            temp['sex_string'] = '未知';
          }
          arr.push(temp);
        }
      }

      return arr;
    }
  },
  methods: {
    minus: function (consumeKey) {
      let consume = businessAction.selectContent.data.consume_list[consumeKey]
      if (parseFloat(consume['quantity']) > parseFloat(0.5)) {
        consume = {
          ...consume,
          quantity: biz.minus(consume['quantity'], 0.5, 1),
        }

        businessAction.selectContent.data.consume_list[consumeKey] = {...consume};
        common.dispatch(setConsumeList([...businessAction.selectContent.data.consume_list]))
      } else {
        common.confirm("确认删除项目吗？", () => {
          businessAction.selectContent.methods.removeProject(consumeKey)
        })
      }
    },

    plus: function (consumeKey) {
      let consume = businessAction.selectContent.data.consume_list[consumeKey]
      consume = {
        ...consume,
        quantity: biz.plus(consume['quantity'], 0.5, 1),
      }
      businessAction.selectContent.data.consume_list[consumeKey] = {...consume};
      common.dispatch(setConsumeList([...businessAction.selectContent.data.consume_list]))
    },

    // 提交之前的检测待确认的数据
    checkConfirmBeforeSubmit: function (callback, loadingStop) {
      callback = callback || function () {
      }
      loadingStop = loadingStop || function () {
      }

      let consumeParams = [];

      for (let i = 0; i < businessAction.selectContent.data.consume_list.length; i++) {
        let tempItem = {...businessAction.selectContent.data.consume_list[i]}

        if (typeof tempItem['room_id'] !== "undefined") {
          tempItem['room_id'] = tempItem['room_id'] ? tempItem['room_id'] : 0;
        } else {
          tempItem['room_id'] = 0;
        }

        tempItem['room_id'] = parseInt(tempItem['room_id']);

        consumeParams.push(tempItem)
      }

      consumeParams = JSON.stringify(consumeParams);

      common.axios('post', common.apiUrl('FINANCE/bill/check-before-create'), {
        'consumeList': consumeParams, //消费数据
      }).then((resultData) => {
        var check = resultData['check_confirm']

        var message = ''

        if (check) {
          message = resultData['message'];
        }

        callback(message)
      }).catch((errorObj) => {
        // 失败
        loadingStop();

        common.toast("开单前检测预约数据失败:" + errorObj.error);

        callback("");

        return false;
      })
    },

    // 提交之前的检测数据
    checkBeforeSubmit: function () {
      //判断是否是在选择项目的界面进行操作  其他选择不可以进行该操作
      if (businessAction.selectContent.data.show != 'project') {
        common.toast('开单过程未结束，不可进行此操作');
        return false;
      }

      //判断消费信息中是否有数据
      if (businessAction.selectContent.data.consume_list.length === 0) {
        common.toast('没有消费数据，不可进行完成保存');
        return false;
      }

      return true;
    },

    //清空消费数据
    deleteConsumeList: function () {
      businessAction.selectContent.data.consume_list = []
      common.dispatch(setConsumeList([...businessAction.selectContent.data.consume_list]))
    },

    //修改本次开单过程的手牌
    updateHandNumber: function (value) {
      businessAction.selectContent.data.hand_number = value;
    },

    //修改此次开单项目流程中的选择项目
    updateSelectProjectId: function (value) {
      businessAction.selectContent.data.select_project_id = value;
    },

    //控制左边显示什么内容
    showContent: function (key) {
      businessAction.selectContent.data.show = key;

      if (key === 'project') {
        if (businessAction.selectContent.data.consume_list.length > 0) {
          businessAction.selectContent.methods.handleConsumePrice(0);
        }
      }
    },

    // 处理项目价格
    handleConsumePrice: function (i) {
      if (i >= businessAction.selectContent.data.consume_list.length) {
        return;
      }

      var consume = businessAction.selectContent.data.consume_list[i];

      if ((typeof consume.price == "undefined") || (consume.price === undefined) || (consume.price.toString().length <= 0)) {

        var starLevelTemp = '';
        var clockTypeTemp = '';

        if (consume['work'].length > 0) {
          var employee = biz.indexOfReturnInObj(consume['work'][0]['employee_id'], business.employeeObjByEmployeeId, 'status', business.employeeStatusList.delete);

          if (!employee) {
            common.toast('开单有问题，请刷新重试');
            return;
          }

          starLevelTemp = employee['star_level'];
          clockTypeTemp = consume['work'][0]['clock_type'];
        }

        // 获取项目价格
        common.axios('get', common.apiUrl('FINANCE/project/price'), {
          "projectId": consume['project_id'],
          "starLevel": starLevelTemp ? starLevelTemp : 0,
          "clockType": clockTypeTemp ? clockTypeTemp : business.clock_type_keys.round, // 默认是轮钟
          "serviceType": consume['service_type'],
          'handId': businessAction.selectContent.data.hand_number['id'], //手牌ID
        }).then((resultData) => {
          consume = {
            ...consume,
            price: resultData
          }
          businessAction.selectContent.data.consume_list[i] = consume
          common.dispatch(setConsumeList([...businessAction.selectContent.data.consume_list]))

          i++;
          businessAction.selectContent.methods.handleConsumePrice(i);
        }).catch((errorObj) => {
          // 失败
          common.toast(errorObj.error);

          i++;
          businessAction.selectContent.methods.handleConsumePrice(i);

        }).finally(() => {

        })

      } else {
        i++;
        businessAction.selectContent.methods.handleConsumePrice(i);
      }
    },

    //初始化数据
    intData: function (handNumber, remark) {
      //判断是否已有手牌
      if (handNumber) {
        businessAction.selectContent.methods.updateHandNumber(handNumber);
      } else {
        //分配第一个手牌号过去
        businessAction.selectContent.methods.updateHandNumber(businessAction.beginBill.selected_hand_numbers[0]);
      }

      businessAction.selectContent.methods.deleteConsumeList(); //清空消费数据

      common.dispatch(setShowSelectContentType('project')) //选择项目

      businessAction.selectContent.data.remark = remark;
    },

    //判断是否为房间开牌，如果是房间开牌则直接将房间号放置消费项目上
    roomCreateBillSelectContent: function (consume) {
      //根据编号查找手牌
      var number = businessAction.selectContent.data.hand_number['number'];
      number = parseInt(number)
      var room = biz.inObjByColumn('number', number, business.roomObjByNumber, 'status', business.roomStatus.status_delete);
      if (!room) {
        common.toast('消费账单有问题，请刷新重试');
        return;
      }

      //将技师信息添加上去
      consume = {
        ...consume,
        room_id: room['id']
      }

      return consume;
    },

    //将项目从已选中删除
    removeProject: function (key) {
      businessAction.selectContent.data.consume_list.splice(key, 1);
      common.dispatch(setConsumeList([...businessAction.selectContent.data.consume_list]))
    },
  },
}

// 做右键、左边菜单
businessAction.mainLeft = {
  backBillClockConfirm: function (billId) {
    common.confirm("退单是否将技师打回原位？", function () {
      businessAction.mainLeft.backBillAction(billId, 1)
    }, function () {
      businessAction.mainLeft.backBillAction(billId, 0)
    }, {
      'okValue': '是',
      'cancelValue': '否',
    })
  },

  // 退单操作
  backBillAction: function (billId, employeeBack) {
    employeeBack = employeeBack || 0 // 技师是否打回原位

    //退牌操作
    common.loadingStart()

    common.axios('post', common.apiUrl('FINANCE/bill/back-v2'), {
      "billId": billId,
      "employeeBack": employeeBack,
    }).then((resultData) => {

      common.toast(resultData['message']);

      // 触发实时通知立即处理
      noticeMessage.triggerHandleMessage();

    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {
      common.loadingStop();
    })

  },

  // 手牌模式下，以房间为维度的消费转移
  consumeTransferByRoomForHandBillType: function (item) {
    if (business.begin_bill_type != business.beginBillTypeList.handNumber) {
      // 非手牌模式
      return;
    }

    // 手牌模式

    /**
     * 手牌模式下：如果点击的是手牌，则根据手牌走；如果点击的房间，则是以手牌模式下的房间维度进行转移
     */

    if (item.length === false || (item.item_type !== 'room')) {
      common.toast('请正确选择房间进行操作');
      return;
    }

    // 查找房间中关联的账单的消费
    var roomId = item['id'];

    var billList = [];

    var allBillList = business.bills;

    for (let i = 0; i < allBillList.length; i++) {
      if (typeof allBillList[i]['consume'] === "undefined") {
        continue;
      }

      var consumeList = allBillList[i]['consume'];

      if (consumeList.length <= 0) {
        continue;
      }

      var checkInRoom = false; // 检测手排下的消费是否有在当前房间里的消费，默认否
      for (let j = 0; j < consumeList.length; j++) {
        if (!consumeList[j]['room_id']) {
          // 没有房间的跳过
          continue;
        }

        // 已支付的跳过
        if (consumeList[j]['pay'] == business.consumePayStatus.pay_yes) {
          continue;
        }

        // 状态异常的跳过
        if (consumeList[j]['status'] != business.consumeStatus.status_yes) {
          continue;
        }

        if (consumeList[j]['room_id'] == roomId) {
          checkInRoom = true;
          break;
        }
      }

      if (checkInRoom === true) {
        billList.push(allBillList[i])
      }
    }

    if (billList.length <= 0) {
      common.toast("房间内没有账单");
      return;
    }

    var waitConsumeList = [];
    for (let i = 0; i < billList.length; i++) {
      var tempConsumeList = billList[i]['consume']

      for (let j = 0; j < tempConsumeList.length; j++) {
        // 没有房间的跳过
        if (!tempConsumeList[j]['room_id']) {
          continue;
        }

        // 已支付的跳过
        if (tempConsumeList[j]['pay'] == business.consumePayStatus.pay_yes) {
          continue;
        }

        // 状态异常的跳过
        if (tempConsumeList[j]['status'] != business.consumeStatus.status_yes) {
          continue;
        }

        waitConsumeList.push({...tempConsumeList[j]})
      }
    }

    // 将数据传给消费转移，并弹框
    businessAction.consumeTransferByRoom.roomId = roomId;
    businessAction.consumeTransferByRoom.consumeList = waitConsumeList;

    common.dispatch(setModalShowType('consumeTransferByRoom'))
  },

  //锁定价格
  checkoutContentConsumeLockPrice: (consumeId) => {
    common.confirm("确认锁定价格吗？", () => {
      common.loadingStart()

      common.axios('post', common.apiUrl('FINANCE/consume/lock-price'), {
        'consumeId': consumeId,
      }).then((resultData) => {
        common.toast(resultData);

        // 触发实时通知立即处理
        noticeMessage.triggerHandleMessage();
      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);
      }).finally(() => {
        common.loadingStop();
      })
    })
  },

  // 解锁价格而
  checkoutContentConsumeUnLockPrice: (consumeId) => {
    if(common.isOfflineMode()) {
      common.toast("边缘计算节点门店暂不支持解锁价格哦");
      return;
    }

    common.confirm("确认解锁价格吗？", () => {
      common.loadingStart()

      common.axios('post', common.apiUrl('FINANCE/consume/un-lock-price'), {
        'consumeId': consumeId,
      }).then((resultData) => {
        common.toast(resultData);

        // 触发实时通知立即处理
        noticeMessage.triggerHandleMessage();
      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);
      }).finally(() => {
        common.loadingStop();
      })
    })
  },

  //修改单价
  checkoutContentUpdateConsumePrice: (consume) => {
    businessAction.checkoutContentUpdatePrice.consume_id = consume.id
    businessAction.checkoutContentUpdatePrice.consume = {...consume}
  },

  // 展示结账页面
  showCheckout: function (bill, isMany) {
    bill = bill || false;
    isMany = isMany || false // 是否多个账单，表示bill是单个还是数组进来的，默认不是

    //显示结账界面
    common.dispatch(setModalShowType('checkoutContent'))

    //初始化结账数据
    businessAction.checkoutContent.init();
    businessAction.checkoutContent.initCheckoutData();

    //判断如果选了有账单的手牌，则将该账单信息放入到结账明细中
    if (bill) {

      if (isMany) {
        // 数组
        for (let i = 0; i < bill.length; i++) {
          if (!bill[i]) {
            continue;
          }

          //将账单添加到待结账的账单中
          businessAction.checkoutContent.addBill(bill[i]);
        }
      } else {
        // 单个
        //将账单添加到待结账的账单中
        businessAction.checkoutContent.addBill(bill);
      }
    }
  },

  // 转完成后的打印小票
  printForToFinish: function (billId) {
    if (common.isOfflineMode()) {
      // todo离线的暂时不打印
      return;
    }

    common.axios('post', common.apiUrl('FINANCE/print/bill-for-shoot'), {
      "billId": billId
    }, 5000, 'no').then((resultData) => {

      // 打印

      if (!resultData) {
        return;
      }

      // 将打印的内容，JSON解析后，再encode，因为java返回的和PHP的不太一样，要处理成一样的
      try {
        resultData = biz.jsonEncode(JSON.parse(resultData));
      } catch (e) {

      }

      var print2Result = {'error': ''};

      // 鞋吧小票

      //打印小票
      print.print(resultData, ['printerName=print2', 'printWidth=' + print.printWidth], 1, print2Result);

      if (print2Result.error) {
        common.toast("预结转完成小票打印失败:" + print2Result.error);
      }

    }).catch((errorObj) => {

    })

  },

  // 鞋柜二维码
  doorQrcodeForFinish: function (handNumber) {
    if (business.begin_bill_type != business.beginBillTypeList.handNumber) {
      return;
    }

    // 没有启用门柜锁，不用处理
    if (business.hasDoorLock == business.hasDoorLockList.no) {
      // 没有门柜锁，直接返回
      return;
    }

    // 不支持鞋柜二维码的不处理
    if (business.doorQrcode != business.doorQrcodeList.yes) {
      return;
    }

    // 帝派、爱莎v2
    if (business.doorLockType == business.doorLockTypeList.A ||
      business.doorLockType == business.doorLockTypeList.C) {
      // 打印
      business.functions.doorQrcodeByHand(handNumber, true);
      return;
    }

  },
}

// 修改单价
businessAction.checkoutContentUpdatePrice = {
  consume_id: '',
  consume: ''
}

// 消费转移
businessAction.billTransfer = {
  show: false,
  sendHandId: '', // 要转移的手牌ID

  initTransferData: function () {

  },
}

// 消费转移-以房间为维度
businessAction.consumeTransferByRoom = {
  roomId: 0, // 操作的房间ID
  consumeList: [], // 要转移操作的消费数据
}

// 编辑消费
businessAction.checkoutContentUpdatePrice = {}

// 结账的消费明细
businessAction.checkoutContent = {
  btn_show: true,

  bills: [], //账单

  pcCheckoutRoomContent: business.pcCheckoutRoomContent.number,

  remaining_consume_ids: [], // 页面中还留下未结账的消费数据
  checkApartCaptcha: 0, // 是否要为拆单验证同一个验证码

  init: function (checkApartCaptcha) {
    businessAction.checkoutContent.checkApartCaptcha = checkApartCaptcha ? checkApartCaptcha : 0;
  },

  //初始化结账数据
  initCheckoutData: function () {
    let tempBillList = businessAction.checkoutContent.bills;
    tempBillList.splice(0, tempBillList.length);
    common.dispatch(setCheckoutBills(tempBillList))

    businessAction.checkoutContent.btn_show = true;

  },

  //将结账信息放入到待结账的账单中
  addBill: function (bill) {
    //查看该手牌是否有团体开牌的数据
    var batchId = bill['batch_id'];
    var tempBillList = [];

    if (batchId in business.billListGroupBatchId) {
      tempBillList = business.billListGroupBatchId[batchId]
    }

    if (tempBillList.length > 1) {

      common.confirm("需要团体结账吗？", function () {
        for (var j = 0; j < tempBillList.length; j++) {
          // 加入待结账
          businessAction.checkoutContent.addBillAction(tempBillList[j]);
        }
      }, function () {
        // 只加入一个账单

        // 加入待结账
        businessAction.checkoutContent.addBillAction(bill);
      }, {'autofocus': true});
    } else {
      // 加入待结账
      businessAction.checkoutContent.addBillAction(bill);
    }
  },

  // 将账单加入待结账的账单里
  addBillAction(bill) {
    let tempBillList = [...businessAction.checkoutContent.bills]
    var temp = biz.inArrayByColumn('id', bill['id'], tempBillList);

    // 账单存在，则不动
    if (temp) {
      return;
    }

    // 不存在：将该账单放入已选账单中
    var tempBill = {...bill}
    tempBill['is_prepay'] = false // 非预结
    tempBillList.push({...tempBill})
    common.dispatch(setCheckoutBills(tempBillList))

    // 将消费名字中的服务方式处理为第一个账单的
    common.dispatch(setActiveServiceType(businessAction.checkoutContent.bills[0]['service_type']))
    common.dispatch(setPreActiveServiceType(businessAction.checkoutContent.bills[0]['service_type']))
  }
}

// 结账付款
businessAction.checkoutPay = {
  hand_numbers: "", //结账的手牌号
  order: "", //订单
  order_id: '', //订单ID
  is_pre_pay: 0, // 是否是预结

  exist_payments: [], //已经添加的支付方式
  exists_special_pay_types: [], //已经添加的特殊支付方式 暂时只有折扣券需处理

  manual_discount: '0', //手动优惠金额
  manual_percentage: 100, //手动优惠的比例
  front_discount_key: "", // 员工授权手动优惠的识别码

  discountCard: '',

  surcharge_fee: 0, //附加费

  pay_success: 0, // 是否支付成功，默认为0；1表示成功

  leaveHandNumberList: [], // 离场的手牌号
  check_apart: 0, // 是否为拆单结账特殊验证码

  card_project_list_data: function () {

    var data = [];

    let tempExistPaymentList = [...businessAction.checkoutPay.exist_payments]
    for (var i = 0; i < tempExistPaymentList.length; i++) {
      //判断是否有会员卡支付出现
      if (tempExistPaymentList[i]['tag'] == business.payTypeTag.tag_card) {
        data.push({
          ...tempExistPaymentList[i]
        });
      }
    }

    return data;
  },

  //初始化付款界面数据
  initCheckoutPayData: function (hand_numbers, order, payments, consumeIds, isPrePay, leaveHandNumberList) {
    businessAction.checkoutPay.hand_numbers = hand_numbers;
    businessAction.checkoutPay.leaveHandNumberList = leaveHandNumberList;
    common.dispatch(setOrder(order))
    businessAction.checkoutPay.order_id = order['id'];
    businessAction.checkoutPay.is_pre_pay = (isPrePay ? isPrePay : 0);
    businessAction.checkoutPay.manual_discount = 0;
    businessAction.checkoutPay.manual_percentage = 100;
    businessAction.checkoutPay.exists_special_pay_types.splice(0, businessAction.checkoutPay.exists_special_pay_types.length);
    common.dispatch(setExistPaymentList([]))

    businessAction.checkoutPay.discountCard = '';
    businessAction.checkoutPay.surcharge_fee = 0;

    businessAction.checkoutPay.pay_success = 0;

    //处理支付方式
    if (payments && payments.length > 0) {
      for (let i = 0; i < payments.length; i++) {
        var temp = {};

        //处理数据
        temp.payment_id = payments[i]['id']; //支付记录ID
        temp.id = payments[i]['pay_type_id']; //支付方式ID
        temp.money = payments[i]['money']; //金额
        temp.remark = payments[i]['remark']; //备注
        temp.change_money = payments[i]['change_money']; //找零

        var payType = biz.inArrayByColumn('id', payments[i]['pay_type_id'], business.pay_types);
        temp.tag = payType['tag']; //支付方式的标记
        temp.name = payType['name']; //支付方式名

        if (temp.tag == business.payTypeTag.tag_card) {

          if (typeof payments[i]['card_category_use'] != "undefined") {
            temp.card_category_use = payments[i]['card_category_use'];
            temp.card_category_name_use = payments[i]['card_category_use']['name'];
            temp.card_category_discount_int_use = payments[i]['card_category_use']['discount'];
            temp.card_id = payments[i]['card_id'];
            temp.card_info = payments[i]['card_info'];
            temp.member_name = payments[i]['member_name'];
          }
        }

        //显示
        temp.show = false;
        temp.input_show = false;

        let tempExistPaymentList = [...businessAction.checkoutPay.exist_payments]
        tempExistPaymentList.push(temp);
        common.dispatch(setExistPaymentList(tempExistPaymentList))
      }
    }

    //处理订单备注
    var remarkList = [];
    for (let j = 0; j < consumeIds.length; j++) {
      if (consumeIds[j] in business.billAllConsumeObjById) {
        let tempConsumeOne = business.billAllConsumeObjById[consumeIds[j]]

        if (tempConsumeOne['bill_id'] in business.billObjById) {
          let tempBillOne = business.billObjById[tempConsumeOne['bill_id']]

          let tempRemark = tempBillOne['remark']

          if (tempRemark) {
            if(remarkList.indexOf(tempRemark) === -1) {
              remarkList.push(tempRemark)
            }
          }
        }
      }
    }

    var remark = remarkList.join(';');

    setTimeout(function () {
      if (remark && remark.length > 0) {
        common.alert(remark);
      }
    }, 1000);

    let tempOrderInfo = businessAction.checkoutPay.order
    tempOrderInfo = {
      ...tempOrderInfo,
      remark: remark
    }
    common.dispatch(setOrder(tempOrderInfo))
  },

  //结账操作事件
  payAction: function (payments) {
    common.loadingStart();

    //组合AJAX需要的数据
    var orderId = businessAction.checkoutPay.order['id'];
    var smallChange = businessAction.checkoutPay.order['small_change']; //抹零
    var surcharge = businessAction.checkoutPay.order['surcharge_fee']; //附加费
    var remark = businessAction.checkoutPay.order['remark']; //备注
    var discountAmount = businessAction.checkoutPay.manual_discount; //手动优惠金额
    var percentage = businessAction.checkoutPay.manual_percentage; //折扣
    var frontDiscountKey = businessAction.checkoutPay.front_discount_key; //员工授权手工优惠的识别码
    var cardId = businessAction.checkoutPay.card_id_data(); //会员卡ID

    //支付方式
    var tempPayTypeList = [];
    if (payments) {
      tempPayTypeList = payments;
    } else {
      tempPayTypeList = businessAction.checkoutPay.exist_payments;
    }

    var payTypeList = businessAction.checkoutPay.getAjaxPayTypeList(tempPayTypeList);

    // 20200903: 非普通账户、默认现金标识的不能有找零金额
    for (let j = 0; j < payTypeList.length; j++) {
      if (typeof payTypeList[j]['change_money'] == "undefined") {
        continue;
      }

      if (!payTypeList[j]['change_money']) {
        continue;
      }

      if (payTypeList[j]['change_money'] == 0) {
        continue;
      }

      if (payTypeList[j]['change_money'] < 0) {
        common.toast("找零金额不能小于0");
        common.loadingStop();
        return;
      }

      if (typeof payTypeList[j]['tag'] == "undefined") {
        common.toast("支付方式参数有误，请联系技术支持");
        common.loadingStop();
        return;
      }

      // 普通支付方式
      if (payTypeList[j]['tag'] == business.payTypeTag.tag_normal) {
        continue;
      }

      // 默认现金
      if (payTypeList[j]['tag'] == business.payTypeTag.tag_cash) {
        continue;
      }

      common.toast("非现金或普通支付方式不能有找零");
      common.loadingStop();
      return;
    }

    //支付方式的标记
    var payTypeTags = [];
    for (var i = 0; i < payTypeList.length; i++) {
      payTypeTags.push(payTypeList[i]['tag']);
    }

    // 将change_money转为string
    for (let i = 0; i < payTypeList.length; i++) {
      if (typeof payTypeList[i]['change_money'] !== "undefined") {
        payTypeList[i]['change_money'] = payTypeList[i]['change_money'].toString()
      }
    }

    // 检测是否要走云端
    var isCloud = false; // 是否要走云端
    for (let i = 0; i < payTypeList.length; i++) {
      if (typeof payTypeList[i]['tag'] === "undefined") {
        continue;
      }

      // 非  现金和普通账号
      if ((payTypeList[i]['tag'] != business.payTypeTag.tag_cash) && (payTypeList[i]['tag'] != business.payTypeTag.tag_normal)) {
        isCloud = true;
        break;
      }
    }

    // 支付之前做的事
    businessAction.checkoutPay.actionBeforePay(isCloud, orderId).then(() => {

      // 支付请求API
      businessAction.checkoutPay.payApiAction(isCloud, orderId, smallChange, surcharge, remark, payTypeList, payTypeTags, cardId, frontDiscountKey)

    }).catch(() => {
      common.loadingStop();
    })

  },

  card_id_data: function () {
    let existPaymentList = businessAction.checkoutPay.exist_payments;

    for (var i = 0; i < existPaymentList.length; i++) {
      //判断是否有会员卡支付出现   打折卡出现
      if (existPaymentList[i]['tag'] == business.payTypeTag.tag_card) {
        // 20201225：大环境的储值卡类型下
        if (existPaymentList[i]['card_category_use']['type'] == business.cardCategoryTypeList.storeValue) {
          // 20190720 折扣类型 非"不打折"  即为打折卡
          if (existPaymentList[i]['card_category_use']['discount_type'] != business.cardCategoryDiscountTypeList.no) {
            return existPaymentList[i]['card_id'];
          }
        }
      }
    }

    return 0;
  },

  //已经添加的支付方式
  exist_payments_data: function () {
    var specialPayment = [];

    let tempExistPaymentList = [...businessAction.checkoutPay.exist_payments]

    // 已存在的支付方式，是否发生改变
    let existPaymentChange = false;

    for (var i = 0; i < tempExistPaymentList.length; i++) {
      // 处理展示的金额数据
      let showMoneyStr = tempExistPaymentList[i]['money']

      // 处理一下打折券的展示金额
      if (tempExistPaymentList[i]['tag'] == business.payTypeTag.tag_ticket) {
        if (("ticket_detail" in tempExistPaymentList[i]) && ("ticket_info" in tempExistPaymentList[i]['ticket_detail'])) {
          if (tempExistPaymentList[i]['ticket_detail']["ticket_info"]['type'] == business.ticketTypeList.discount) {
            // 折扣券
            showMoneyStr = tempExistPaymentList[i]['ticket_detail']["ticket_info"]['title']
          }
        }
      }

      if ((typeof tempExistPaymentList[i]['show_money_str'] === "undefined") || showMoneyStr !== tempExistPaymentList[i]['show_money_str']) {
        tempExistPaymentList[i] = {
          ...tempExistPaymentList[i],
          show_money_str: showMoneyStr
        }
        existPaymentChange = true;
      }

      //判断是否有代金券出现
      if (tempExistPaymentList[i]['tag'] == business.payTypeTag.tag_voucher) {
        specialPayment.push(tempExistPaymentList[i]);
        continue;
      }
    }

    businessAction.checkoutPay.exists_special_pay_types = specialPayment

    if (existPaymentChange === true) {
      common.dispatch(setExistPaymentList(tempExistPaymentList))
    }

    return tempExistPaymentList;
  },

  //应收
  should_pay_money: function () {
    var order = businessAction.checkoutPay.order;
    if (order.length === 0) {
      return '0';
    }

    var project_fee = order['project_fee'] || 0;
    var service_fee = order['service_fee'] || 0;
    var room_fee = order['room_fee'] || 0;
    var tip_fee = order['tip_fee'] || 0;
    var surcharge_fee = order['surcharge_fee'] || 0;
    var discount = order['discount'] || 0;
    var small_change = order['small_change'] || 0;

    var money = biz.plus(project_fee, service_fee);
    money = biz.plus(money, room_fee)
    money = biz.plus(money, tip_fee)
    money = biz.plus(money, surcharge_fee)
    money = biz.minus(money, discount)
    money = biz.minus(money, small_change)

    // var money = parseFloat(project_fee) + parseFloat(service_fee) + parseFloat(room_fee) + parseFloat(tip_fee) + parseFloat(surcharge_fee) - parseFloat(discount) - parseFloat(small_change);
    // money = biz.formatFloat(money, 2);

    return money;
  },

  //实收
  actual_pay_money: function () {
    var money = 0;
    for (var i = 0; i < businessAction.checkoutPay.exist_payments.length; i++) {
      var temp = businessAction.checkoutPay.exist_payments[i]['money'];
      if (temp.length === 0) {
        temp = 0;
      }

      money = biz.plus(money, temp)
    }

    return money;
  },

  // 一个辅助订单数据的计算属性
  assist_order_data: function () {
    var orderId = businessAction.checkoutPay.order_id; //订单ID

    if (!orderId || parseInt(orderId) <= 0) {
      // 没有订单id，不进行计算
      return 0;
    }

    var cardId = businessAction.checkoutPay.card_id_data(); //会员卡ID
    var percentage = businessAction.checkoutPay.manual_percentage; //手动优惠折扣率
    var discountAmount = businessAction.checkoutPay.manual_discount; //手动优惠金额
    var surcharge = businessAction.checkoutPay.surcharge_fee;
    let existPaymentList = businessAction.checkoutPay.exist_payments;

    var payTypeList = []; //支付方式  数组

    if (existPaymentList && existPaymentList.length > 0) {
      for (var i = 0; i < existPaymentList.length; i++) {
        payTypeList.push(existPaymentList[i]);
      }
    }

    var ajaxPayTypeList = businessAction.checkoutPay.getAjaxPayTypeList(payTypeList)

    // 20211204: 获取订单的计算的APi
    var orderApiUrl = business.functions.getOrderInfoApi(ajaxPayTypeList)

    let tempOrder = {...businessAction.checkoutPay.order}

    if (orderId.toString().length > 0) {
      common.axios('post', orderApiUrl, {
        'orderId': orderId,
        'cardId': cardId,
        'percentage': percentage,
        'discountAmount': discountAmount,
        'payTypeList': JSON.stringify(ajaxPayTypeList),
        'surcharge': surcharge.toString(),
      }).then((resultData) => {

        tempOrder['discount'] = resultData['order']['discount'];
        tempOrder['percentage'] = resultData['order']['percentage'];
        tempOrder['project_fee'] = resultData['order']['project_fee'];
        tempOrder['small_change'] = resultData['order']['small_change'];
        tempOrder['surcharge_fee'] = resultData['order']['surcharge_fee'];
        tempOrder['total_fee'] = resultData['order']['total_fee'];

        tempOrder['consumes'] = resultData['consumes'];

        //客显

        var tempCardId = 0;
        var tempCardInfo = '';
        var tempCardPayData = '';

        var paymentList = businessAction.checkoutPay.exist_payments_data();

        for (var i = 0; i < paymentList.length; i++) {
          if (paymentList[i]['card_id'] && parseInt(paymentList[i]['card_id']) > 0) {
            tempCardId = paymentList[i]['card_id'];
            tempCardPayData = paymentList[i];

            if (!paymentList[i]['card_info'] || paymentList[i]['card_info'].length <= 0) {
              common.toast('会员卡支付有问题，请刷新重试');
              return;
            }

            tempCardInfo = {
              ...paymentList[i]['card_info']
            };

            tempCardInfo['discount'] = paymentList[i]['card_category_discount_int_use']; //优惠比例
            tempCardInfo['cardCategoryString'] = paymentList[i]['card_category_name_use']; //卡类名
            tempCardInfo['member_name'] = paymentList[i]['member_name']; //会员名
            tempCardInfo['actual_money'] = paymentList[i]['money']; //本次抵扣金额
            tempCardInfo['discount_money'] = biz.minus(tempOrder['discount'], businessAction.checkoutPay.manual_discount); //优惠金额
            tempCardInfo['residual_money'] = biz.minus(tempCardInfo['money'], tempCardInfo['actual_money']); //扣款后余额

          }
        }

        common.dispatch(setOrder(tempOrder))

        businessAction.checkoutPay.padShow(resultData['consumes'], tempOrder, businessAction.checkoutPay.actual_pay_money(), tempCardId, tempCardInfo, tempCardPayData);

      }).catch((errorObj) => {

        // 失败
        common.toast(errorObj.error);

      }).finally(() => {

      })

      return 0;
    }

    return 0;
  },

  payApiAction: function (isCloud, orderId, smallChange, surcharge, remark, payTypeList, payTypeTags, cardId, frontDiscountKey) {
    // 20211204: 获取订单支付的API
    var payApiUrl = business.functions.getOrderPayApi(isCloud)

    var timeout = 10000; // 默认10秒

    // 离线版本，且是新版支付的，则超时时间调整为15秒，因为可能有多个消费数据，会超时
    if (common.isOfflineMode() && (business.payVersion == 2)) {
      timeout = 15000;
    }

    let leaveHandNumberList = businessAction.checkoutPay.leaveHandNumberList

    //付款结账
    common.axios('post', payApiUrl, {
      "orderId": orderId.toString(),
      "smallChange": smallChange.toString(),
      "surcharge": surcharge.toString(),
      "remark": remark,
      "payTypeList": JSON.stringify(payTypeList),
      "payTypeTags": payTypeTags,
      "cardId": cardId,
      "frontDiscountKey": frontDiscountKey,
      "isPrePay": businessAction.checkoutPay.is_pre_pay,
    }, timeout).then((resultData) => {
      // 触发实时通知立即处理
      noticeMessage.triggerHandleMessage();

      // 付款成功
      businessAction.checkoutPay.pay_success = 1;

      // 20190610 通知客显展示评论页
      businessAction.checkoutPay.padCommentShow()

      //关闭付款界面
      common.dispatch(setModalShowType(''))

      //判断是否是拆单
      let tempRemainingConsumeIds = [...businessAction.checkoutContent.remaining_consume_ids]
      if (tempRemainingConsumeIds.length > 0) {
          //打开结账明细界面
          common.dispatch(setModalShowType('checkoutContent'))

          businessAction.checkoutContent.init(1);
      }

      common.toast("结账成功");

      // 打印数据
      businessAction.checkoutPay.printAfterPaySuccess(orderId, isCloud, leaveHandNumberList)
    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {
      common.loadingStop();
    })
  },

  // 支付成功之后的打印
  printAfterPaySuccess: function (orderId, isCloud, leaveHandNumberList) {
    let printUrl = ''

    if (business.payVersion == 2) {
      // 新版支付的打印
      printUrl = common.apiUrl('FINANCE/order/print-after-pay')
    } else {
      // 老版支付的打印
      isCloud = isCloud || false

      // 判断是走远端的打印小票还是本地的小票，因为如果是云端的支付，就需要走远端的打印，有会员卡或其他的云端支付相关的处理在云端
      printUrl = common.apiUrl('FINANCE/bill/print-after-pay')

      if (isCloud) {
        printUrl = common.apiUrl('bill/print-after-pay')
      }
    }

    common.axios('get', printUrl, {
      "orderId": orderId
    }).then(async (resultData) => {
      // 判断是否需要打印，不需要则直接停止
      if (!resultData['needPrint']) {

        // 二维码打印数据
        businessAction.checkoutPay.doorQrcodePrint(leaveHandNumberList);

        return;
      }

      // 将打印的内容，JSON解析后，再encode，因为java返回的和PHP的不太一样，要处理成一样的
      try {
        if (resultData['data']) {
          resultData['data'] = biz.jsonEncode(JSON.parse(resultData['data']));
        }

        if (resultData['secondPrintArr']) {
          resultData['secondPrintArr'] = biz.jsonEncode(JSON.parse(resultData['secondPrintArr']));
        }
      } catch (e) {

      }

      var errorData = resultData['error']; // 需要提示的信息

      //打印小票
      var printResult = {'error': ''};

      var print2Result = {'error': ''};

      // 需要打印

      //准备打印小票的数据
      if (resultData['data']) {
        var num = business.checkoutPrintNum;

        print.print(resultData['data'], ['printWidth=' + print.printWidth], num, printResult);
      }

      //打印第二个小票
      if (resultData['secondPrintArr']) {
        //打印小票
        print.print(resultData['secondPrintArr'], ['printerName=print2', 'printWidth=' + print.printWidth], 1, print2Result);
      }

      var messageList = []

      if (printResult.error) {
        messageList.push(printResult.error)
      }

      if (errorData) {
        messageList.push(errorData)
      }

      let noticeMessage = '结账成功'

      if (messageList.length > 0) {
        noticeMessage += ':' + messageList.join(';');
      }

      common.toast(noticeMessage);

      // 二维码打印数据
      await businessAction.checkoutPay.doorQrcodePrint(leaveHandNumberList);

    }).catch(async (errorObj) => {
      common.toast('结账成功: 打印失败:' + errorObj.error);

      // 二维码打印数据
      await businessAction.checkoutPay.doorQrcodePrint(leaveHandNumberList);
    })

  },

  // 结账之后，打印门柜锁的二维码数据 - 放在结账之后操作的最后
  doorQrcodePrint: async (allLeaveHandNumberList) => {
    try {
      // 打印离场的鞋柜二维码

      // 只有手牌开单模式有效
      if (business.begin_bill_type != business.beginBillTypeList.handNumber) {
        return;
      }

      // 没有启用门柜锁，不用处理
      if (business.hasDoorLock == business.hasDoorLockList.no) {
        // 没有门柜锁，直接返回
        return;
      }

      // 不支持鞋柜二维码的不处理
      if (business.doorQrcode != business.doorQrcodeList.yes) {
        return;
      }

      // 帝派、爱莎v2，可以进行二维码打印
      if (business.doorLockType != business.doorLockTypeList.A &&
        business.doorLockType != business.doorLockTypeList.C) {
        return;
      }

      if (allLeaveHandNumberList.length === 0) {
        return;
      }

      // 根据手牌号排序
      let leaveHandNumberList = []
      for (let i = 0; i < allLeaveHandNumberList.length; i++) {
        if (!allLeaveHandNumberList[i]) {
          continue;
        }

        leaveHandNumberList.push(parseInt(allLeaveHandNumberList[i]))
      }

      // 手牌号，从小到大排序
      leaveHandNumberList.sort((a, b) => {
        return a - b;
      })

      for (let i = 0; i < leaveHandNumberList.length; i++) {
        // 爱莎的，每个延时100毫秒
        if (business.doorLockType == business.doorLockTypeList.C) {
          // 延时
          await common.sleep(200) // 200毫秒
        }

        business.functions.doorQrcodeByHand(leaveHandNumberList[i], true);
      }

    } catch (e) {
      common.toast("门柜锁二维码打印失败:" + e.toString())
      console.log("结账离场鞋柜二维码打印失败:" + e.toString())
    }

  },

  // 发起结账之前的操作
  actionBeforePay: function (isCloud, orderId) {
    return new Promise((resolve, reject) => {
      // 当必须走云端的时候，判断如果启用了离线模式，则需要通知店端，锁定订单；否则不用动

      // 支付API不固定走云端
      if (isCloud !== true) {
        resolve('');
        return;
      }

      // 支付API，固定走云端，则需要判断是否启用了离线模式
      if (!common.isOfflineMode()) {
        resolve('');
        return;
      }

      // 走离线版本

      // 请求店端
      common.axios('post', common.apiUrl("FINANCE/order-lock/create"), {
        "orderId": orderId.toString(),
      }).then((resultData) => {

        resolve('')

      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);
        reject(errorObj.error);
      })

    })
  },

  //取消订单
  cancelOrder: function () {
    //取消订单
    common.loadingStart();

    var timeout = 10000; // 默认10秒

    // 离线版本，且是新版支付的，则超时时间调整为15秒，因为可能有多个消费数据，会超时
    if (common.isOfflineMode() && (business.payVersion == 2)) {
      timeout = 15000;
    }

    common.axios('post', common.apiUrl('FINANCE/bill/cancel'), {
      "orderId": businessAction.checkoutPay.order['id']
    }, timeout).then((result) => {
      // 触发实时通知立即处理
      noticeMessage.triggerHandleMessage();

      businessAction.checkoutPay.clear();
    }).catch((errorObj) => {
      common.toast(errorObj.error);
    }).finally(() => {
      common.loadingStop();
    })
  },

  //通知pad显示
  padShow: function (consumes, order, actualPayMoney, cardId, cardInfo, cardPayData) {
    var padCode = business.ipadCode;

    if (padCode.toString().length > 0) {
      //准备客显数据
      var tempConsumes = [...consumes];

      for (let i = 0; i < tempConsumes.length; i++) {
        let tempConsumeItem = {...tempConsumes[i]}

        //房间信息
        var tempRoom = biz.indexOfReturnInObj(tempConsumeItem['room_id'], business.roomObjById);
        if (tempRoom) {
          tempConsumeItem['room_info'] = tempRoom['number'] + '  ' + tempRoom['name'];
        }

        //技师信息
        var tempEmployeeInfo = [];

        var tempConsume = biz.indexOfReturnInObj(tempConsumeItem['id'], business.billAllConsumeObjById)
        if (tempConsume) {
          for (var n = 0; n < tempConsume['work'].length; n++) {
            var tempEmployee = biz.indexOfReturnInObj(tempConsume['work'][n]['employee_id'], business.employeeObjByEmployeeId)

            if (tempEmployee) {

              var tempEmployeeData = {
                'employee_id': tempEmployee['employee_id'],
                'code': tempEmployee['code'],
                'number': tempEmployee['number'],
                'name': tempEmployee['name'],
                'name_small': tempEmployee['name_small'],
                'sex': tempEmployee['sex'],
                'sex_string': tempEmployee['sex_string'],
                'star_level': tempEmployee['star_level'],
                'star_level_string': tempEmployee['star_level_string'],
                'type': tempEmployee['type'],
              };

              if (tempEmployee['employee_clock_info']) {
                tempEmployeeData.employee_clock_info = tempEmployee['employee_clock_info'];
              }

              tempEmployeeInfo.push(tempEmployeeData);
            }
          }
        }

        if (tempEmployeeInfo) {
          tempConsumeItem['employee_info'] = tempEmployeeInfo;
        }

        tempConsumes[i] = tempConsumeItem;
      }

      //组装会员信息
      var card = '';
      if (parseInt(cardId) > 0) {

        //本次优惠金额
        var discount = order['discount'];
        var manualDiscountMoney = businessAction.checkoutPay.manual_discount;
        var discountMoney = 0.00;

        if (parseFloat(discount) > 0) {
          discountMoney = discount;

          if (parseFloat(manualDiscountMoney) > 0) {
            discountMoney = biz.minus(discount, manualDiscountMoney);
          }
        }

        var cardMoney = cardInfo['money'];
        var actualMoney = cardPayData['money'];

        var residual_money = biz.minus(cardMoney, actualMoney)
        card = {
          'info': cardInfo, //会员卡信息
          'discount_money': discountMoney, //会员卡优惠金额
          'actual_money': actualMoney, //实际扣款
          'residual_money': residual_money
        };
      }

      var userCover = (business.userInfo && (typeof business.userInfo['cover_url'] !== "undefined")) ? business.userInfo['cover_url'] : '';

      var padData = {
        'order': order, //订单信息
        'actual_pay_money': actualPayMoney, //实收金额
        'consumes': tempConsumes, //消费信息
        'card': card,
        'user_cover': userCover
      };

      padData = JSON.stringify(padData);

      var data = {"api": "checkout", "node": padCode, "content": padData};

      //通知ipad显示
      business.functions.customNotice(JSON.stringify(data))
    }
  },

  // 通知pad客显展示评论页
  padCommentShow: function () {
    var padCode = business.ipadCode;

    if (padCode.length <= 0) {
      return;
    }

    var padData = {
      'type': 'success', // 支付成功
    };

    padData = JSON.stringify(padData);

    var data = {"api": "checkout", "node": padCode, "content": padData};
    business.functions.customNotice(JSON.stringify(data))
  },

  // 清空订单信息
  clear: function () {
    businessAction.checkoutPay.hand_numbers = "";
    businessAction.checkoutPay.leaveHandNumberList = [];
    common.dispatch(setOrder(''))
    businessAction.checkoutPay.order_id = "";
    businessAction.checkoutPay.is_pre_pay = 0;
    common.dispatch(setExistPaymentList([]))
    businessAction.checkoutPay.exists_special_pay_types = [];
    businessAction.checkoutPay.manual_discount = 0;
    businessAction.checkoutPay.manual_percentage = 100;
    businessAction.checkoutPay.front_discount_key = "";
    businessAction.checkoutPay.discountCard = "";
    businessAction.checkoutPay.surcharge_fee = 0;
    businessAction.checkoutPay.pay_success = 0;
    businessAction.checkoutPay.check_apart = 0
  },

  // 为请求接口处理支付方式数据
  getAjaxPayTypeList: function (payTypeList) {
    // 处理成给接口使用的参数，跳过一些前端用来判断的参数
    var ajaxPayTypeList = [];
    for (var i = 0; i < payTypeList.length; i++) {
      var temp = payTypeList[i]

      var tempInfo = {}

      for (var tempKey in temp) {
        if (tempKey == 'ticket_detail') {
          continue;
        }

        if (tempKey == 'card_category_use') {
          continue;
        }

        if (tempKey == 'card_info') {
          continue;
        }

        if (tempKey == 'show_money_str') {
          continue;
        }

        tempInfo[tempKey] = temp[tempKey]
      }

      ajaxPayTypeList.push(tempInfo)
    }

    // 将change_money转为string
    for (let i = 0; i < ajaxPayTypeList.length; i++) {
      if (typeof ajaxPayTypeList[i]['change_money'] !== "undefined") {
        ajaxPayTypeList[i]['change_money'] = ajaxPayTypeList[i]['change_money'].toString()
      }
    }

    return ajaxPayTypeList
  }
}

// 会员卡结账
businessAction.checkoutPayCard = {
  'windowShow': '', //控制弹层显示

  'pay_type': '', //支付方式

  'discountCard': '', //折扣卡

  'selectedCardList': [], //已选择使用的会员卡
  'selectedTicketList': [], //已选择使用的卡券

  'orderDiscount': 0, //订单优惠金额
  'orderTotalFee': 0, //订单的消费金额
  'orderCardPayMoney': 0, // 订单剩余需支付的金额-会员卡

  card_ref_select_ticket_list: [], // 已选的卡券，临时使用，和card组件里的保持一致的

  //订单剩余需支付金额--辅助的计算属性
  assist_order_card_pay_money: function () {
    //订单数据
    var orderId = businessAction.checkoutPay.order_id; //订单ID

    if (!orderId || parseInt(orderId) <= 0) {
      businessAction.checkoutPay.orderCardPayMoney = 0;

      return 0;
    }

    // 会员卡页面没打开，不计算
    if (!businessAction.checkoutPayCard.windowShow) {
      businessAction.checkoutPay.orderCardPayMoney = 0;

      return 0;
    }

    businessAction.checkoutPayCard.getOrderNeedPayMoney('').then((money) => {
      businessAction.checkoutPayCard.orderCardPayMoney = money;
    })

    return 0;
  },

  //获取订单剩余需支付金额
  getOrderNeedPayMoney: function (tempUseCard) {
    return new Promise((resolve, reject) => {
      tempUseCard = tempUseCard || '';

      businessAction.checkoutPayCard.getNewOrderInfo(tempUseCard).then((resultData) => {

        let orderMoney = resultData['order']['total_fee'];

        let tempMoney = businessAction.checkoutPayCard.afterGetOrderNeedPayMoney(orderMoney);

        resolve(tempMoney);

      }).catch((errObj) => {

        if (errObj['code'] == 'noId') {
          resolve(0);
          return;
        } else {
          common.toast(errObj['error']);

          console.log(errObj)

          reject(errObj['error']);
        }
      })
    })
  },

  // 获取订单最新支付信息
  getNewOrderInfo: function (tempUseCard) {
    return new Promise((resolve, reject) => {
      tempUseCard = tempUseCard || '';

      var orderMoney = businessAction.checkoutPay.order['total_fee'];

      var discountCardId = businessAction.checkoutPayCard.getNowUseDiscountCardId(tempUseCard);

      //订单数据
      var orderId = businessAction.checkoutPay.order_id; //订单ID

      if (!orderId || parseInt(orderId) <= 0) {
        reject({
          "error": "订单有误[no id]",
          'code': 'noId',
        })
        return;
      }

      orderId = orderId.toString();

      if (orderId.length <= 0) {
        reject({
          "error": "订单有误[no id]",
          'code': 'noId',
        })
        return;
      }

      var cardId = discountCardId; //折扣会员卡ID
      var percentage = businessAction.checkoutPay.manual_percentage; //手动优惠折扣率
      var discountAmount = businessAction.checkoutPay.manual_discount; //手动优惠金额
      var surcharge = businessAction.checkoutPay.surcharge_fee;

      var payTypeList = businessAction.checkoutPayCard.getPayTypeListForOrder(); //支付方式  数组

      // 20200903: 如果有临时要使用的会员卡，则放入到后端进行计算，因为要处理会员卡累计余额的情况
      if (tempUseCard) {
        payTypeList.push({
          'id': businessAction.checkoutPayCard.pay_type['id'],
          'tag': businessAction.checkoutPayCard.pay_type['tag'],
          'name': businessAction.checkoutPayCard.pay_type['name'],
          'money': 0,  //抵扣金额 这是临时的，所以没有使用的金额
          'deduct_times': 0, //抵扣次数
          'remark': '',
          'payment_id': '',
          'change_money': "0",
          'show': true,
          'input_show': false,
          'card_category_name_use': tempUseCard['card_category_use']['name'],
          'card_category_discount_int_use': tempUseCard['card_category_use']['discount'],
          'card_id': tempUseCard['id'],
          'card_category_use': tempUseCard['card_category_use'],
          'project_content': "",
          'card_info': tempUseCard,
          'discount_money': 0,
          'member_name': tempUseCard['member_name']
        })
      }

      // 处理成给接口使用的参数，跳过一些前端用来判断的参数
      var ajaxPayTypeList = businessAction.checkoutPay.getAjaxPayTypeList(payTypeList);

      // 20211204: 获取订单计算信息的API
      var orderApiUrl = business.functions.getOrderInfoApi(ajaxPayTypeList)

      common.axios('post', orderApiUrl, {
        'orderId': orderId,
        'cardId': cardId,
        'percentage': percentage,
        'discountAmount': discountAmount,
        'payTypeList': JSON.stringify(ajaxPayTypeList),
        'surcharge': surcharge.toString(),
      }).then((resultData) => {
        common.dispatch(setPayCardOrderDiscount(resultData['order']['discount']))
        common.dispatch(setPayCardOrderTotalFee(resultData['order']['total_fee']))

        resolve(resultData);

      }).catch((errorObj) => {
        // 失败
        reject({
          'error': errorObj.error,
          'code': ''
        });
      }).finally(() => {

      })
    })
  },

  // 订单剩余支付金额之后的处理
  afterGetOrderNeedPayMoney: function (orderMoney) {
    //已支付金额
    var money = 0;

    let tempExistPaymentList = businessAction.checkoutPay.exist_payments
    for (let i = 0; i < tempExistPaymentList.length; i++) {
      if (tempExistPaymentList[i]['money'] && (tempExistPaymentList[i]['money'].toString().length > 0)) {
        money = parseFloat(money) + parseFloat(tempExistPaymentList[i]['money']);
      }
    }

    let tempSelectTicketList = businessAction.checkoutPayCard.card_ref_select_ticket_list
    if (tempSelectTicketList && tempSelectTicketList.length > 0) {
      for (var k = 0; k < tempSelectTicketList.length; k++) {
        money = parseFloat(money) + parseFloat(tempSelectTicketList[k]['money']);
      }
    }

    for (var j = 0; j < businessAction.checkoutPayCard.selectedCardList.length; j++) {
      if (businessAction.checkoutPayCard.selectedCardList[j]['money'] && (businessAction.checkoutPayCard.selectedCardList[j]['money'].toString().length > 0)) {
        money = parseFloat(money) + parseFloat(businessAction.checkoutPayCard.selectedCardList[j]['money']);
      }
    }

    var temp = biz.minus(orderMoney, money);
    return temp;
  },

  // 获取使用的折扣卡
  getNowUseDiscountCardId: function (tempUseCard) {
    tempUseCard = tempUseCard || '';

    var discountCardId = 0;

    if (businessAction.checkoutPay.discountCard) {
      discountCardId = businessAction.checkoutPay.discountCard['id'];
    }

    if (parseInt(discountCardId) <= 0) {
      if (businessAction.checkoutPayCard.discountCard) {
        discountCardId = businessAction.checkoutPayCard.discountCard['id'];
      }
    }

    if (tempUseCard) {
      // 20201225 ，卡类型为 储值卡的大环境下

      if (tempUseCard['card_category_use']['type'] == business.cardCategoryTypeList.storeValue) {

        // 20190720 折扣类型 非"不打折"  即为打折卡
        if (tempUseCard['card_category_use']['discount_type'] != business.cardCategoryDiscountTypeList.no) {
          if (parseInt(discountCardId) > 0) {
            // common.toast('不允许使用两张折扣会员卡');
            // return;
          } else {
            discountCardId = tempUseCard['id'];
            businessAction.checkoutPayCard.discountCard = tempUseCard;
          }
        }

      }

    }

    return discountCardId;
  },

  // 获取支付方式
  getPayTypeListForOrder: function () {
    var payTypeList = []; //支付方式  数组

    let tempExistPaymentList = businessAction.checkoutPay.exist_payments
    if (tempExistPaymentList && tempExistPaymentList.length > 0) {
      for (let i = 0; i < tempExistPaymentList.length; i++) {
        payTypeList.push(tempExistPaymentList[i]);
      }
    }

    // 待确认的支付卡券
    let tempSelectTicketList = businessAction.checkoutPayCard.card_ref_select_ticket_list
    if (tempSelectTicketList && tempSelectTicketList.length > 0) {
      for (let i = 0; i < tempSelectTicketList.length; i++) {
        payTypeList.push(tempSelectTicketList[i]);
      }
    }

    // 待确认的支付会员卡
    let tempSelectCardList = businessAction.checkoutPayCard.selectedCardList
    if (tempSelectCardList && tempSelectCardList.length > 0) {
      for (let i = 0; i < tempSelectCardList.length; i++) {
        payTypeList.push(tempSelectCardList[i]);
      }
    }

    return payTypeList;
  },

  //更新折扣卡
  removeDiscountCard: function (cardId) {
    if (businessAction.checkoutPayCard.discountCard && (businessAction.checkoutPayCard.discountCard['id'] == cardId)) {
      businessAction.checkoutPayCard.discountCard = ''
    }

    if (businessAction.checkoutPay.discountCard && (businessAction.checkoutPay.discountCard['id'] == cardId)) {
      businessAction.checkoutPay.discountCard = ''
    }

    // 使用新的会员卡ID 20190806
    var payTypeList = businessAction.checkoutPayCard.getPayTypeListForOrder(); //支付方式
    for (var i = 0; i < payTypeList.length; i++) {
      if (payTypeList[i]['tag'] == business.payTypeTag.tag_card) {

        // 20201225 ，卡类型为 储值卡的大环境下

        if (payTypeList[i]['card_category_use']['type'] == business.cardCategoryTypeList.storeValue) {
          if (payTypeList[i]['card_category_use']['discount_type'] != business.cardCategoryDiscountTypeList.no) {
            //保存折扣卡
            businessAction.checkoutPayCard.discountCard = payTypeList[i]['card_info']
            break;
          }
        }

      }
    }

    businessAction.checkoutPayCard.getOrderNeedPayMoney();
  },

  //保存储值卡的支付方式
  saveStoreCardPayList: function (card) {
    //获取会员卡支付金额
    common.loadingStart();
    businessAction.checkoutPayCard.getCardPayMoney(card).then((cardPayMoney) => {
      businessAction.checkoutPayCard.saveCardPayList(card, cardPayMoney, 0, '');
    }).catch(() => {
      // 失败了， 检测并将当前的这个会员卡从折扣卡里移除
      businessAction.checkoutPayCard.removeDiscountCard(card['id']);
    }).finally(() => {
      common.loadingStop();
    })
  },

  //保存记次卡的支付方式
  saveTimeCardPayList: function (card, deductTimesData, deduct_project_list_data) {

    deductTimesData = deductTimesData || '';
    deduct_project_list_data = deduct_project_list_data || [];

    //获取会员卡支付金额
    var cardPayMoney = 0;

    var deductTimes = 0;

    var project_content = [];

    var temp = '';

    if (deductTimesData) {

      if (parseFloat(deductTimesData.times) > 0) {
        temp = {
          'projectId': 0,
          'deduct_times': deductTimesData.times,
          'money': deductTimesData.money,
        };
        project_content.push(temp);

        cardPayMoney = biz.plus(cardPayMoney, deductTimesData.money);

        deductTimes = biz.plus(deductTimes, deductTimesData.times, 1);
      }

    }

    //抵扣项目
    if (deduct_project_list_data && deduct_project_list_data.length > 0) {

      for (var i = 0; i < deduct_project_list_data.length; i++) {

        if (parseFloat(deduct_project_list_data[i]['times']) > 0) {

          temp = {
            'consumeId': deduct_project_list_data[i]['consumeId'],
            'projectId': deduct_project_list_data[i]['projectId'],
            'projectName': deduct_project_list_data[i]['name'],
            'deduct_times': deduct_project_list_data[i]['times'],
            'money': deduct_project_list_data[i]['money']
          };

          project_content.push(temp);

          cardPayMoney = biz.plus(cardPayMoney, deduct_project_list_data[i]['money'])

          deductTimes = biz.plus(deductTimes, deduct_project_list_data[i]['times'], 1);
        }

      }

    }

    if (parseFloat(deductTimes) <= 0) {
      common.toast('非法操作，抵扣次数不能小于等于0，请刷新重试');
      return;
    }

    businessAction.checkoutPayCard.saveCardPayList(card, cardPayMoney, deductTimes, project_content);
  },

  // 保存 专享卡 的支付方式
  saveLimitProjectCardPayList: function (card) {
    //获取会员卡支付金额
    common.loadingStart();
    businessAction.checkoutPayCard.getLimitProjectCardPayMoney(card).then((cardPayMoney) => {
      businessAction.checkoutPayCard.saveCardPayList(card, cardPayMoney, 0, '');
    }).finally(() => {
      common.loadingStop();
    })
  },

  //保存会员卡支付方式
  saveCardPayList: function (card, cardPayMoney, deduct_times, project_content) {

    // 20201104：如果会员卡已经存在，则不能保存
    if (!businessAction.checkoutPayCard.checkCardUsed(card)) {
      return;
    }

    project_content = project_content || [];
    deduct_times = deduct_times || 0;

    if (!cardPayMoney || cardPayMoney.length <= 0 || parseFloat(cardPayMoney) < 0) {
      // common.toast('会员卡支付金额非法');
      common.toast('会员卡支付金额必须大于0');
      return;
    }

    if (!card || card.length <= 0) {
      common.toast('非法操作会员支付');
      return;
    }

    //添加会员卡支付记录
    var tempCard = {
      'id': businessAction.checkoutPayCard.pay_type['id'],
      'tag': businessAction.checkoutPayCard.pay_type['tag'],
      'name': businessAction.checkoutPayCard.pay_type['name'],
      'money': cardPayMoney,  //抵扣金额
      'deduct_times': deduct_times, //抵扣次数
      'remark': businessAction.checkoutPayCard.pay_type['remark'],

      'payment_id': '',

      'change_money': "0",
      'show': true,
      'input_show': false,

      'card_category_name_use': card['card_category_use']['name'],
      'card_category_discount_int_use': card['card_category_use']['discount'],
      'card_id': card['id'],

      'card_category_use': card['card_category_use'],

      'project_content': project_content,

      'card_info': card,

      'discount_money': 0,
      'member_name': card['member_name']
    };

    if (typeof card['money_pay_type'] !== "undefined") {
      tempCard['money_pay_type'] = card['money_pay_type']
    }

    let tempSelectCardList = [...businessAction.checkoutPayCard.selectedCardList]
    tempSelectCardList.push(tempCard);
    common.dispatch(setSelectedCardList(tempSelectCardList))

    //当会员卡为折扣卡的时候， 将数据保存下来

    // 20201225: 在卡类为储值卡的大环境下
    if (card['card_category_use']['type'] == business.cardCategoryTypeList.storeValue) {
      // 20190720 折扣类型 非"不打折"  即为打折卡
      if (card['card_category_use']['discount_type'] != business.cardCategoryDiscountTypeList.no) {
        //保存折扣卡

        // 20190806 当多个折扣卡的时候，只是用第一个
        if ((!businessAction.checkoutPayCard.discountCard) || (typeof businessAction.checkoutPayCard.discountCard['id'] == undefined)) {
          businessAction.checkoutPayCard.discountCard = card;
        }
      }
    }
  },

  //判断卡是否已经使用过
  checkCardUsed: function (card) {
    var temp = '';
    temp = biz.inArrayByColumn('card_id', card['id'], businessAction.checkoutPay.exist_payments);

    if (temp) {
      common.toast('该会员卡已经使用过');
      return false;
    }

    temp = biz.inArrayByColumn('card_id', card['id'], businessAction.checkoutPayCard.selectedCardList);

    if (temp) {
      common.toast('该会员卡已经使用过');
      return false;
    }

    return true;
  },

  // 保存积分支付方式
  saveMemberPointPayForCardPayList: function (memberId, payType, money, point) {
    // 先移走会员积分支付记录
    // businessAction.checkoutPayCard.$refs['card'].removePointPay() // todo 20230601

    //添加积分支付记录
    var tempCard = {
      'id': payType['id'],
      'tag': payType['tag'],
      'name': payType['name'],
      'money': money,  //抵扣金额
      'use_point': point,  //抵扣积分
      'member_id': memberId, // 会员id
      'check_is_point_pay': true, // 是否为积分支付
      'remark': payType['remark'],
      'payment_id': '',
      'change_money': "0",
      'show': true,
      'input_show': false,
      'discount_money': 0,
    };

    let tempSelectCardList = [...businessAction.checkoutPayCard.selectedCardList]
    tempSelectCardList.push(tempCard);
    common.dispatch(setSelectedCardList(tempSelectCardList))
  },

  //获取会员卡扣的金额
  getCardPayMoney: function (card) {
    return new Promise((resolve, reject) => {
      if (!card || card.length <= 0) {
        common.toast('会员卡有问题');
        reject('会员卡有问题');
        return false;
      }

      //判断会员卡
      // 20190720 折扣类型 非"不打折"  即为打折卡
      if (parseFloat(card['money']) == 0 && (card['card_category_use']['discount_type'] == business.cardCategoryDiscountTypeList.no)) {
        common.toast('该会员卡不是打折卡，且余额为零!');
        reject('该会员卡不是打折卡，且余额为零!');
        return false;
      }

      if (parseInt(card['money'] * 100) == 0 && parseInt(card['allow_no_money_discount']) == business.allow_no_money_discount.no) {
        common.toast("该卡余额为零，且不允许空卡打折");
        reject('该卡余额为零，且不允许空卡打折');
        return false;
      }

      var cardMoney = card['money']; // 会员卡金额

      // 验证是否有特殊处理的抵扣金额，有的话，直接使用；无：则正常流程，使用会员卡进行计算订单可支付金额
      businessAction.checkoutPayCard.checkAndGetOrderCardCanPayMoneyForGetCardPayMoney(card['card_category_use']['id']).then((checkResult) => {
        if (checkResult['check'] === 'no') {
          // 无抵扣规则，使用正常流程计算
          businessAction.checkoutPayCard.getOrderNeedPayMoney(card).then((orderMoney) => {
            let money = businessAction.checkoutPayCard.afterGetCardPayMoney(orderMoney, cardMoney, card);
            resolve(money);
          }).catch((tempData) => {
            reject(tempData);
          })

          return;
        } else {
          var orderMoney = checkResult['money'];
        }

        let money = businessAction.checkoutPayCard.afterGetCardPayMoney(orderMoney, cardMoney, card);

        resolve(money);
      }).catch((tempData) => {
        reject(tempData);
      })
    })
  },

  // 获取 专享卡 抵扣使用的金额
  getLimitProjectCardPayMoney: function (card) {
    return new Promise((resolve, reject) => {
      if (!card || card.length <= 0) {
        common.toast('会员卡有问题');
        reject('会员卡有问题');
        return false;
      }

      //判断会员卡
      if (parseFloat(card['money']) <= 0) {
        common.toast('专享卡余额为0');
        reject('专享卡余额为0');
        return false;
      }

      if (card['card_category_use']['use_money'] == business.category_use_money.no) {
        common.toast('该卡不允许使用余额结算');
        reject('该卡不允许使用余额结算');
        return false;
      }

      businessAction.checkoutPayCard.getNewOrderInfo(card).then((resultData) => {
        let list = resultData['limitProjectCardPayList']

        let cardMoney = (typeof list[card['id']] === "undefined") ? 0 : list[card['id']]

        resolve(cardMoney);

      }).catch((errObj) => {
        common.toast(errObj['error'])

        reject(errObj['error']);
      })

    })
  },

  afterGetCardPayMoney: function (orderMoney, cardMoney, card) {
    var money = orderMoney;

    //判断会员卡余额和订单总金额的大小
    if (parseFloat(cardMoney) < parseFloat(orderMoney)) {
      money = cardMoney;
    }

    if (card['card_category_use']['use_money'] == business.category_use_money.no) {
      money = 0;
    }

    return money
  },

  // 为 会员卡支付获取金额 处理 订单可用来会员卡支付的金额
  checkAndGetOrderCardCanPayMoneyForGetCardPayMoney: function (cardCategoryId) {
    return new Promise((resolve, reject) => {

      common.loadingStart()

      common.axios('post', common.apiUrl('order/card-category-deduct-config'), {
        "cardCategoryId": cardCategoryId
      }).then((resultData) => {

        // 判断是否根据规则走
        var check = resultData['check']
        if (check === 'no') {
          resolve({
            'check': 'no',
          })
          return;
        }

        // 计算金额
        var config = resultData['info'];

        var basicMoney = config['money']; // 每满多少减多少
        var deductMoney = config['deduct_money'];

        if (basicMoney <= 0) {
          common.toast("满减金额必须大于0");
          reject("满减金额必须大于0");
          return;
        }

        businessAction.checkoutPayCard.getOrderNeedPayMoney('').then((orderMoney) => {
          var temp = Math.floor(orderMoney / basicMoney);
          temp = parseInt(temp);

          var totalDeductMoney = temp * parseFloat(deductMoney);
          totalDeductMoney = biz.formatFloat(totalDeductMoney, 2);

          resolve({
            'check': 'yes',
            'money': totalDeductMoney
          })

        }).catch((tempData) => {
          reject(tempData)
        })
      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);

        reject(errorObj.error);
      }).finally(() => {
        common.loadingStop();
      })

    })
  },

  //检测是否有记次卡出现
  checkNoStoreCardExist: function () {

    let tempExistPaymentList = businessAction.checkoutPay.exist_payments

    //在使用打折卡之前不允许有非储值卡的支付类型出现
    for (let i = 0; i < tempExistPaymentList.length; i++) {
      if (!tempExistPaymentList[i]) {
        continue;
      }

      if (tempExistPaymentList[i]['card_id'] && parseInt(tempExistPaymentList[i]['card_id']) > 0) {
        if (tempExistPaymentList[i]['card_category_use']['type'] != business.cardCategoryTypeList.storeValue) {
          return true;
        }
      }
    }

    //在使用打折卡之前不允许有记次卡的支付类型出现
    let tempSelectCardList = businessAction.checkoutPayCard.selectedCardList
    for (var i = 0; i < tempSelectCardList.length; i++) {
      if (!tempSelectCardList[i]) {
        continue;
      }

      if (tempSelectCardList[i]['card_id'] && parseInt(tempSelectCardList[i]['card_id']) > 0) {
        if (tempSelectCardList[i]['card_category_use']['type'] != business.cardCategoryTypeList.storeValue) {
          return true;
        }
      }
    }

    return false;
  },

  //清空数据
  clearData: function () {
    businessAction.checkoutPayCard.pay_type = '';
    businessAction.checkoutPayCard.discountCard = '';
    common.dispatch(setSelectedCardList([]))
    common.dispatch(setPayCardOrderDiscount(0))
    common.dispatch(setPayCardOrderTotalFee(0))

    businessAction.checkoutPayCard.windowShow = '';
    businessAction.checkoutPayCard.orderCardPayMoney = 0;
  },

  //读卡
  readCard: function () {
    // todo 20230601
    // this.$refs.card.getInfoByCode();
  },

  //会员卡支付界面的清空
  clearCard: function () {
    // todo 20230601
    // this.$refs.card.clear();
  },

  // 为会员卡或卡券抵扣项目时，排除已经被抵扣的项目数量
  handleDeductProductInExistPay: function (data) {
    if (!data) {
      return data;
    }

    // 在已存在的支付记录中，排除已抵扣的项目数据量
    data = businessAction.checkoutPayCard.handleDeductProductInExistPayItem(data, businessAction.checkoutPay.exist_payments);

    // 在本地已使用的支付记录中，排除已抵扣的项目数量  --- 会员卡
    data = businessAction.checkoutPayCard.handleDeductProductInExistPayItem(data, businessAction.checkoutPayCard.selectedCardList);

    // 在本地已使用的支付记录中，排除已抵扣的项目数量  --- 卡券
    let tempSelectTicketList = businessAction.checkoutPayCard.card_ref_select_ticket_list
    if (tempSelectTicketList && (typeof tempSelectTicketList != "undefined")) {
      data = businessAction.checkoutPayCard.handleDeductProductInExistPayItem(data, tempSelectTicketList);
    }

    return data;
  },

  // 在已存在的支付记录 里 排除已抵扣的项目数量
  handleDeductProductInExistPayItem: function (data, existPaymentList) {
    if (!data) {
      return data;
    }

    if (!existPaymentList) {
      return data;
    }

    //判断抵扣的消费是否在已存在的支付记录中存在，对应的减去
    for (let i = 0; i < data.length; i++) {

      var consumeId = data[i]['consumeId'];

      for (let j = 0; j < existPaymentList.length; j++) {
        if (!existPaymentList[j]) {
          continue;
        }

        // 卡券
        if (existPaymentList[j]['ticket_detail_id'] && parseInt(existPaymentList[j]['ticket_detail_id']) > 0) {
          if (!(existPaymentList[j]['project_content'])) {
            continue;
          }

          let project_content = existPaymentList[j]['project_content'];

          if (!project_content) {
            continue;
          }

          for (let q = 0; q < project_content.length; q++) {
            if (project_content[q]['consumeId'] == consumeId) {
              data[i]['quantity'] = parseFloat(data[i]['quantity']) - parseFloat(project_content[q]['deduct_times']);
              data[i]['quantity'] = biz.formatFloat(data[i]['quantity'], 1);
            }
          }
        } else if (existPaymentList[j]['card_id'] && parseInt(existPaymentList[j]['card_id']) > 0) {
          // 会员卡
          if (!(existPaymentList[j]['project_content'])) {
            continue;
          }

          let project_content = existPaymentList[j]['project_content'];

          if (!project_content) {
            continue;
          }

          for (let q = 0; q < project_content.length; q++) {
            if (project_content[q]['consumeId'] == consumeId) {
              data[i]['quantity'] = parseFloat(data[i]['quantity']) - parseFloat(project_content[q]['deduct_times']);
              data[i]['quantity'] = biz.formatFloat(data[i]['quantity'], 1);
            }
          }
        }
      }
    }

    return data;
  },

  // 验证支付方式是不是项目抵扣券
  checkPayTypeIsProjectTicket(ajaxPayType) {
    // 没有 ticket_detail_id 的，不是卡券
    if (typeof ajaxPayType['ticket_detail_id'] == "undefined") {
      return false;
    }

    if (ajaxPayType['ticket_detail_id'] == undefined) {
      return false;
    }

    if (!ajaxPayType['ticket_detail_id']) {
      return false;
    }

    // 没有抵扣项目的，也不是 项目抵扣券
    if (typeof ajaxPayType['project_content'] == "undefined") {
      return false;
    }

    if (ajaxPayType['project_content'] == undefined) {
      return false;
    }

    if (!ajaxPayType['project_content']) {
      return false;
    }

    return true;
  },

}

// 移动支付
businessAction.checkoutPayMobile = {
  windowShow: '', //控制弹层显示
  pay_type: '', //支付方式
  type: '', //移动支付类型 'wechat' 微信 'alipay' 支付宝

  //订单剩余需支付金额
  order_pay_money: function () {
    var orderMoney = businessAction.checkoutPay.order['total_fee'];

    //已支付金额
    var money = 0;
    for (var i = 0; i < businessAction.checkoutPay.exist_payments.length; i++) {
      money = biz.plus(money, businessAction.checkoutPay.exist_payments[i]['money']);
    }

    var temp = biz.minus(orderMoney, money);
    return temp;
  },

  //初始化数据
  initMobilePayData: function (type, payType) {
    businessAction.checkoutPayMobile.type = type;
    businessAction.checkoutPayMobile.pay_type = payType;
  },
}

// 美团选择
businessAction.checkoutPayMeituan = {
  payTypeInfo: {},
  init: (payType) => {
    businessAction.checkoutPayMeituan.payTypeInfo = payType
  },

}

// 美团支付
businessAction.checkoutPayMeituanPay = {
  couponId: 0,
  init: (couponId) => {
    businessAction.checkoutPayMeituanPay.couponId = couponId
  }
}

businessAction.checkoutPayPayType = {
  windowShow: '', //控制弹层显示
  payType: '', //支付方式
  billMoney: '', //免单金额

  //初始化数据
  initPayTypeData: function (money) {
    businessAction.checkoutPayPayType.billMoney = money;
    businessAction.checkoutPayPayType.payType = '';
  },

  //更新免单金额
  updateBillMoney: function (money) {
    businessAction.checkoutPayPayType.billMoney = money;
  },

}

// 结账优惠授权
businessAction.checkoutPayPayTypeCheck = {
  // 将数据保存下来
  saveDiscountData: function (money, percentage, frontDiscountKey) {
    //处理数据
    businessAction.checkoutPay.manual_discount = money; //手动优惠金额
    businessAction.checkoutPay.manual_percentage = percentage; //手动优惠比例

    businessAction.checkoutPay.front_discount_key = frontDiscountKey; //验证优惠的key
  }
}

// 会员卡余额使用选择
businessAction.checkoutPayCardMoneyUse = {
  card : false
}

// 计次卡结账
businessAction.checkoutPayCardConsume = {
  card: '', //当前使用的会员卡

  'deduct_project_list': [], //本次抵扣的项目
  'deduct_consume_list': [],

  deduct_consume_list_data: function () {
    var data = businessAction.checkoutPayCardConsume.deduct_project_list_data();

    return businessAction.checkoutPayCardConsume.deduct_consume_list;
  },

  //用来计算可以抵扣的消费数据
  deduct_project_list_data: function () {
    var projectList = businessAction.checkoutPayCardConsume.deduct_project_list;

    var data = [];

    var consumes = businessAction.checkoutPay.order['consumes'];

    if (consumes) {
      for (let i = 0; i < consumes.length; i++) {
        var projectId = consumes[i]['project_id'];

        var project = biz.inArrayByColumn('projectId', projectId, projectList);

        if (!project) {
          continue;
        }

        data.push({
          'consumeId': consumes[i]['id'],
          'projectId': projectId,
          'name': consumes[i]['project_name'] + '[' + consumes[i]['star_level_string'] + '][' + consumes[i]['clock_type_string'] + ']',
          'quantity': consumes[i]['quantity'],
          'price': consumes[i]['price'],
          'times': 0, //抵扣的次数
          'money': 0, //抵扣的金额
        });
      }
    }

    // 在已存在的支付记录中，排除已抵扣的项目数据量
    data = businessAction.checkoutPayCard.handleDeductProductInExistPay(data);

    var newData = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i]['quantity'] <= 0) {
        continue;
      }

      newData.push(data[i]);
    }

    businessAction.checkoutPayCardConsume.deduct_consume_list = newData

    return data;
  },

  updateProjectList: function (projectList) {
    //记次卡可以抵扣的项目数据
    var cardProjectList = projectList || [];

    var data = [];

    var orderData = businessAction.checkoutPay.order;

    if (orderData && orderData['consumes'] && orderData['consumes'].length > 0) {

      if (cardProjectList && cardProjectList.length > 0) {
        for (let i = 0; i < cardProjectList.length; i++) {
          var tempConsume = biz.inArrayByColumn('project_id', cardProjectList[i]['id'], orderData['consumes']);

          if (tempConsume) {
            var temp = {
              'name': cardProjectList[i]['name'],
              'projectId': cardProjectList[i]['id'],

              'consumeData': [],

              'times': 0,
              'money': 0
            };

            data.push(temp);
          }
        }
      }

    }

    businessAction.checkoutPayCardConsume.deduct_project_list = data;
  },
}

// 消费编辑
businessAction.consumeUpdate = {
  show: '',
  consume: '',
  overtimeConsumeSetting: business.overtimeConsumeSettingList.no,

  updateConsume: function (consume) {
    businessAction.consumeUpdate.consume = consume;
  },

  clear: function () {
    businessAction.consumeUpdate.consume = '';
  },
}

// 合并团体
businessAction.billGroup = {
  bill: '', //账单

  initTransferData: function () {
  },

}

// 撤销团体
businessAction.unbindGroupBill = {
  batchId: '', // 批次ID
  billList: [], // 团体账单

  removeByHandId: function (handId) {
    let billList = [...businessAction.unbindGroupBill.billList]
    biz.removeArrayByColumn('hand_id', handId, billList)

    businessAction.unbindGroupBill.billList = [...billList]
  }
}

// 门柜锁
businessAction.doorClock = {
  typeList: business.doorClockTypeList
}

// 整单修改
businessAction.billUpdate = {
  show: '',
  billId: '', //正在修改的账单ID
  bill: '',

  //清空数据
  clear: function () {
    businessAction.billUpdate.billId = '';
    businessAction.billUpdate.bill = '';
  },

}


//头部
businessAction.mainTop = {
  fullShow: true, // 模块是否展示，默认展示

  //会员开卡
  memberCreate: function () {
    common.dispatch(setMemberShowType('openCard'));
  },

  //会员充值
  memberRecharge: function () {
    common.dispatch(setMemberShowType('showMember'));
  },

  //会员查询
  memberQuery: function () {
    common.dispatch(setMemberShowType('showMember'));
  },
};

// 房间排钟
businessAction.roomAppointOrderCreate = {
  roomId: 0,
}

// 开房
businessAction.roomUseOpen = {
  roomId: 0,
}

// 备注
businessAction.roomRemarkManage = {
  roomId: 0,
  billId: '',
  type: '',
  originalRemark: '', // 初始备注
}

// 叫钟队列的手牌开单
businessAction.mainRightAppointOrderHandNumber = {
  appointOrderId: 0, // 叫钟队列Id
}

// 右边叫钟队列的处理
businessAction.mainRightAppointOrder = {

  createBillAction: (id, handId) => {
    if (!id) {
      common.alert('非法操作');
      return;
    }

    handId = handId || 0

    let okCb = function () {
      common.loadingStart()

      common.axios('post', common.apiUrl('FINANCE/appoint-order/create-bill-from-front'), {
        'id': id,
        'handId': handId
      }).then((resultData) => {
        common.toast("开单成功");

        // 触发实时通知立即处理
        noticeMessage.triggerHandleMessage();
      }).catch((errorObj) => {
        // 失败
        common.toast(errorObj.error);
      }).finally(() => {
        common.loadingStop()
      })

    }

    if(handId === 0) {
      // 无手牌开单，要确认
      //确认开单吗
      common.confirm("确定立即开单吗？", function () {
        okCb();
      }, function () {

      });
    } else {
      // 有手牌直接开单
      okCb();
    }

  },

}

// 换房间
businessAction.mainRightAppointOrderChangeRoom = {
  appointOrderId: '', // 排钟
  roomId: 0, // 房间

}

// 排钟叫起
businessAction.appointOrderCall = {
  appointOrderId: '', // 排钟Id
  roomId: 0, // 房间ID
  sellEmployeeId: 0, // 营销人员ID
}

// 编辑排钟
businessAction.mainRightAppointOrderUpdateAppoint = {
  appointOrderId: '', // 排钟
  roomId: 0, // 房间

}

// 手牌模式下的房间拼为团体
businessAction.roomGroup = {
  room: false, // 当前房间
}

// 手牌模式下新型房间的忙碌房间备注
businessAction.roomRemarkForNewRoom = {
  room: false, // 当前房间
  remark: '', // 初始的备注
}

// 202312： 新版的房间备注，不分开单等配置
businessAction.roomRemarkV2 = {
  room: false, // 当前房间
  remark: '', // 初始的备注
}

// 批量管理账单备注
businessAction.batchManageBillRemark = {
  room: false, // 当前房间
  billRemarkList: [], // 原来的备注数据
}

// 切换线路
businessAction.networkLine = {

}

// 手牌模式，新型房间模式，房间解除团体
businessAction.roomUnbindGroup = {
  room: false,
}

// 项目抵扣的卡券的支付
businessAction.checkoutPayTicketConsume = {
  ticketDetail: '', //当前使用 的  卡券

  'deduct_project_list': [], //本次可以抵扣的消费数据
  'deduct_consume_list': [], // 本次抵扣的数据

  updateProjectList: function (projectList) {
    // 卡券 可以抵扣的项目数据
    var ticketProjectList = projectList || [];

    var data = [];

    var orderData = businessAction.checkoutPay.order;

    if (orderData && orderData['consumes'] && orderData['consumes'].length > 0) {

      if (ticketProjectList && ticketProjectList.length > 0) {
        for (let i = 0; i < ticketProjectList.length; i++) {
          var tempConsume = biz.inArrayByColumn('project_id', ticketProjectList[i]['id'], orderData['consumes']);

          if (tempConsume) {
            var temp = {
              'name': ticketProjectList[i]['name'],
              'projectId': ticketProjectList[i]['id'],

              'consumeData': [],

              'times': 0,
              'money': 0
            };

            data.push(temp);
          }
        }
      }

    }

    businessAction.checkoutPayTicketConsume.deduct_project_list = data;
  },
}

// 支付卡券单品折扣券
businessAction.checkoutPayTicketDiscount = {
  ticketDetail: '', //当前使用 的  卡券

  consumeList: [], // 可以抵扣的消费数据
}

// 来电信息
businessAction.callInfo = {
  last_call_info: {
    mobile: '',
    created_at: 0,
  }, //上一次电话记录
}

// 结账使用积分结账
businessAction.checkoutPayCardMemberPoint = {
  memberId: 0, // 会员id
  payType: {}, // 积分支付方式的信息
  basic: 100, // 1元消耗积分数，默认消耗100
  usePoint: '', // 要使用的积分
}

// 在房间上操作手牌开单 - 手牌模式
businessAction.handCreateInRoom = {
  roomId: 0, // 房间ID
}

export default businessAction