import axios from "axios";
import qs from 'qs';
import md5 from 'md5';
import {baseApi, artifactId, mqttUrl, env, domainStr} from "../config-local";
import business from "./business";
import biz from "./biz";
import mqttClient from "./mqttClient";
import noticeMessage from "./noticeMessage";

axios.defaults.retry = 5; // 重试5次
axios.defaults.retryDelay = 1200; // 重试延时。1.2秒。目前没有使用这个值，是在重试的地方进行1.1秒到1.5秒之间的随机

axios.defaults.shouldRetry = (err) => { // 重试条件
  // console.log(err.response.data);
  // console.log(err.response.status);
  if (err.response && err.response.status && err.response.status == 429) {
    return true; // 重试
  } else {
    return false; // 跳过
  }
}

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config;

  // 如果配置不存在或未设置重试选项，则拒绝
  if (!config || !config.retry || !config.shouldRetry(err)) return Promise.reject(err);

  // 设置变量以跟踪重试次数
  config.__retryCount = config.__retryCount || 0;

  // 判断是否超过总重试次数
  if (config.__retryCount >= config.retry) {
    // 返回错误并退出自动重试
    return Promise.reject(err);
  }

  // 增加重试次数
  config.__retryCount += 1;

  //打印当前重试次数
  //console.log(config.url +' 自动重试第' + config.__retryCount + '次');

  // 创建新的Promise
  var backoff = new Promise(function (resolve) {

    // 20241209: 改为1.2秒到1.5秒之间随机
    let tempDelayTime = common.getRoundNum(1100, 1500)

    setTimeout(function () {
      resolve();
    // }, config.retryDelay || 1);
    }, tempDelayTime);
  });

  // 返回重试请求
  return backoff.then(function () {
    return axios(config);
  });
});

let common = {
  localExeUrl: 'http://127.0.0.1:2048'
}

let tokenKey = 'employee-token'
if (env === 'test') {
  tokenKey = env + "-" + tokenKey
}

// 设置token
common.setToken = function (token) {

  // 酷鼠的token和管理端保持一致，用cookie
  if (token) {
    // 设置
    if (domainStr) {
      window.$.cookie(tokenKey, token, {'path': "/", domain: domainStr})
    } else {
      window.$.cookie(tokenKey, token, {'path': "/"})
    }
  } else {
    // 删除
    window.$.cookie(tokenKey, null, {path: '/', expires: -1}); // expires: -1，可以删除cookie
    window.$.cookie(tokenKey, null, {path: '/', expires: -1, domain: domainStr}); // expires: -1，可以删除cookie  老的和新的2个都清掉
  }
}

// 获取token
common.getToken = function () {
  // 和酷鼠的管理端采用一套，用的是cookie里的token。酷鼠项目特殊，这样处理
  let token = window.$.cookie(tokenKey)

  if (!token) {
    return ""
  }

  if (token === 'null') {
    return ""
  }

  return token
}

// oem信息
common.oemInfo = {
  name: '上海酷鼠信息科技有限公司',
  tel: '400-870-2711',
  email: 'hcx@ku52.cn',
  address: '上海市青浦区华徐公路628号奇点科创园A座5楼505',
  icp: '沪ICP备16021061号-1',
  copyrightBeginTime: "2013", // 版权开始时间
  copyrightEndTime: new Date().getFullYear(), // 版权结束时间
}

common.navigate = null

// 跳转 登录页
common.redirectToLogin = function () {
  if (common.navigate) {
    common.navigate('/login')
  } else {
    common.toast("登录有误，请联系技术支持")
  }

}

// 跳转 进入账号充值中心
common.redirectToAccount = function () {
  common.redirectToUrl("account");
}

// 新窗口打开跳转 进入账号充值中心
common.openToAccount = function () {
  common.openToUrl("account");
}

// 进入官网
common.redirectToHome = function () {
  if (env === "dev") {
    // 开发环境
    window.location.href = "http://localhost/kushu/web/"
    return;
  }

  common.redirectToUrl("/")
}

// 返回旧版
common.redirectToOldPage = function () {
  if (env === "dev") {
    // 开发环境
    window.location.href = "http://localhost/kushu-front/login2.html"
    return;
  }

  window.location.href = "../v2/login2.html"
}

// 退出
common.logout = function () {
  // 先拿到token，不然清理之后，再退出就没用了
  let token = common.getToken()

  // 清掉token
  common.setToken("")

  common.redirectToLogin() // 去登录

  common.axios('post', common.apiUrl('BASIC/token/delete', "no"), {
    'employee-token': token,
  }).then((resultData) => {
  }).catch((errorObj) => {
    // 失败
  }).finally(() => {

  })
}

// 跳转URL  params: xxx=xxx&xxx=xxx
common.redirectToUrl = function (url, params) {
  window.location.href = common.handleUrlForOldManage(url, params);
}

// 新窗口打开URL  params: xxx=xxx&xxx=xxx
common.openToUrl = function (url, params) {
  // window.open(common.handleUrlForOldManage(url, params))

  // 20240930: 新去掉新窗体
  window.location.href = common.handleUrlForOldManage(url, params)
}

common.handleUrlForOldManage = (url, params) => {
  url = baseApi + "/" + url

  if (params) {
    if (url.indexOf('?') === -1) {
      url = url + '?'
    } else {
      url = url + '&'
    }

    url += params;
  }

  return url;
}

// 跳转到v4新版管理页
common.redirectToV4ManageUrl = (url, params) => {
  window.location.href = common.handleUrlForV4Manage(url, params);
}

// 跳转到v4新版管理页 - 新窗体
common.openToV4ManageUrl = (url, params) => {
  // window.open(common.handleUrlForV4Manage(url, params));

  // 20240930: 新去掉新窗体
  window.location.href = common.handleUrlForV4Manage(url, params)
}

// 为v4处理url
common.handleUrlForV4Manage = (url, params) => {
  params = params || ""

  let tempUrl = baseApi + "/v2/v4/index.html"
  tempUrl += '?v=' + (new Date().getTime())

  tempUrl += '#' + url;

  if (params) {
    tempUrl += '?' + params
  }

  return tempUrl
}

// 版本号
common.version = '20240311'

// 打印机宽带
common.printWidth = 500;

// 普通的api的url
common.getNormalApiUrl = function (api, param = {}) {
  let queryArr = []
  for (let k in param) {
    queryArr.push(encodeURIComponent(k) + '=' + encodeURIComponent(param[k]))
  }

  let queryStr = ''
  if (queryArr.length > 0) {
    const mark = api.indexOf('?') >= 0 ? '&' : '?'
    queryStr = mark + queryArr.join('&')
  }

  if (api.startsWith('http')) {
    return api + queryStr
  }

  return baseApi + api + queryStr
}

// 和API交互
common.ajax = function (method, api, data, config = {}) {
  data = data || {}

  // 添加版本号
  // 当url中有2048的，不加版本号参数
  if (api.indexOf(':2048/') === -1) {
    if (((typeof data) == "undefined") || (!data)) {
      data = {}
    }

    data['version'] = '20230327';
  }

  data['deviceTag'] = common.getDeviceTag();

  const isGet = method.toLowerCase() === 'get'

  const configDefault = {
    'contentType': 'application/x-www-form-urlencoded', // application/x-www-form-urlencoded、multipart/form-data、application/json
    'timeout': 5000,                  // 调用api超时时间为5秒
    'displayError': true,              // 调用api出错时，是否显示错误消息
    'useToken': true,                  // api是否需要使用token。如果需要token而本地没有token时，将重定向到登录页
    'interceptInvalidToken': true,     // api返回token无效时，是否拦截。如果拦截，将重定向到登录页
  }

  config = Object.assign(configDefault, config)

  if (!isGet && config['contentType'].toLowerCase() === 'application/x-www-form-urlencoded') {
    data = qs.stringify(isGet ? null : data)
  }

  let headers = {
    'Content-Type': config['contentType'],
  }

  let tempParamData = {}

  if (config.useToken) {

    let tempToken = common.getToken()

    if ((!tempToken) || (tempToken.length === 0)) {

      // 去登录
      common.redirectToLogin()

      return new Promise((resolve, reject) => {
        reject({code: 'MISS_TOKEN', error: "请登录", data: null})
      })
    }

    headers['employee-token'] = tempToken
  }

  tempParamData['apiVersion'] = '20240409'; // API的版本
  tempParamData['api_front_from'] = 'pc_v4'

  // api的完整路径
  var apiFullUrl = common.getNormalApiUrl(api, isGet ? {...data, ...tempParamData} : tempParamData)

  return new Promise((resolve, reject) => {

    axios({
      method: method,
      url: apiFullUrl,
      data: data,
      headers: headers,
      timeout: config.timeout
    }).then((response) => {

      common.handleApiResponse(response).then((resultData) => {
        resolve(resultData)
      }).catch((errorObj) => {

        switch (errorObj.error) {
          case 'INVALID_TOKEN':
            if (config.interceptInvalidToken) {

              if (config.displayError) {
                common.toast('请登录')
              }

              common.setToken("") // 清除token
              common.redirectToLogin()

            }
            break;

          case 'invalid token':
            if (config.interceptInvalidToken) {

              if (config.displayError) {
                common.toast('请登录')
              }

              common.setToken("") // 清除token
              common.redirectToLogin()

            }
            break;

          default:
            if (config.displayError) {
              common.alert(errorObj.error ? errorObj.error : "出错了")
            }

            break;
        }

        reject({"code": ((typeof errorObj.code !== "undefined" && errorObj.code) ? errorObj.code : "ERROR"), "error": (errorObj.error ? errorObj.error : '出错了'), "data": null})
      })
    }).catch((error) => {
      config.displayError && common.alert("" + error) // 显示错误消息时，才展示弹窗
      reject({code: 'ERROR', error: '' + error, data: null})
      // common.alert(error)
    })

  })
}

common.apiUrl = function (api, needToken) {
  var result = common.handleApiForApiUrl(api)
  var locationPre = common.handleLocationPreForApiUrl(api)

  api = result['api']
  var preStr = result['preStr']

  needToken = needToken || "yes"

  let tempUrl = locationPre + preStr + '/app.php/front-api/' + api;
  // 如果api是/app.php开头的，则直接使用
  if(api.indexOf("/app.php/") === 0) {
    tempUrl = locationPre + preStr + api;
  }

  if (needToken === "yes") {

  } else {
    if(tempUrl.indexOf('?') !== -1) {
      tempUrl += '&';
    } else {
      tempUrl += '?';
    }

    tempUrl += 'temp_need_t=no';
  }

  return tempUrl
};

common.saveOffline = function (offlineData, actionMqtt) {
  actionMqtt = actionMqtt || "yes" // 是否启用mqtt

  /**
   * 'is_offline' => $isOffline,
   'offline_ip' => 'xxx', // 默认的服务地址
   'app_version' => $appVersion, // 店端的版本
   'mqtt_url' => 'xxx', // 默认的mqtt地址
   'offline_ip_list' => $offlineIpList, // 服务地址数组，用来可尝试的
   'mqtt_url_list' => $mqttUrlList, // mqtt地址数组，用来可尝试更换的
   */

  // 将店端的IP保存到 localStorage 中
  if (offlineData.is_offline === 1 && offlineData.offline_ip) {
    console.log("店端模式")
    // $(".js-user-mode-info").html("边缘计算节点")
    window.localStorage.setItem("offline_ip", offlineData.offline_ip) // http://localhost:8000
  } else {
    console.log("云端模式")
    // $(".js-user-mode-info").html("云计算")
    window.localStorage.removeItem("offline_ip");  // 不是离线模式，清空IP
  }

  // 处理mqtt的地址
  if (offlineData.is_offline === 1 && offlineData.mqtt_url) {
    window.localStorage.setItem("offline_mqtt_url", offlineData.mqtt_url) // ws://xxxxx:xxx
  } else {
    window.localStorage.removeItem("offline_mqtt_url");
  }

  // 检测mqtt和ip是否要重头来：
  // 如果本次拿到的数据和之前的一样，则不需要重头来；否则重头来
  let checkRefreshOfflineIpAction = false; // 默认ip不需要重头来
  let checkRefreshOfflineMqttAction = false; // 默认mqtt不需要重头来

  // 处理mqtt和服务地址的数组，offline_ip_list、mqtt_url_list是同时加的，所以判断一个，做适配即可
  if (offlineData.is_offline === 1 && (typeof offlineData.offline_ip_list !== "undefined") && (offlineData.offline_ip_list)) {
    let offlineIpListJsonStr = JSON.stringify(offlineData.offline_ip_list)
    let offlineMqttListJsonStr = JSON.stringify(offlineData.mqtt_url_list)

    // 获取上一次的数据对比
    let lastOfflineIpListJsonStr = window.localStorage.getItem("offline_ip_list");
    let lastOfflineMqttListJsonStr = window.localStorage.getItem("offline_mqtt_url_list");

    if (offlineIpListJsonStr !== lastOfflineIpListJsonStr) {
      // 不一样，则需要重头来
      checkRefreshOfflineIpAction = true;
    }

    if (offlineMqttListJsonStr !== lastOfflineMqttListJsonStr) {
      // 不一样，则需要重头来
      checkRefreshOfflineMqttAction = true;
    }

    window.localStorage.setItem("offline_ip_list", offlineIpListJsonStr)
    window.localStorage.setItem("offline_mqtt_url_list", offlineMqttListJsonStr)
  } else {
    window.localStorage.removeItem("offline_ip_list");
    window.localStorage.removeItem("offline_mqtt_url_list");

    // 清除上一次的记录
    common.removeUseOfflineData()
  }

  // 保存最新数据的时候，则直接用默认的数据作为使用的
  if (offlineData.is_offline === 1) {
    common.useOfflineData = {
      ip: offlineData.offline_ip, // 正在使用的边缘ip
      ipTime: biz.getNowTime(), // 边缘ip切换时间，时间戳，单位秒
      ipIndex: 0, // 正在使用的边缘ip是第几个
      mqttUrl: offlineData.mqtt_url, // 正在使用的mqtt的url
      mqttTime: biz.getNowTime(), // 切换mqtt的时间，时间戳，单位秒
      mqttIndex: 0, // 正在使用的mqtt地址是第几个
    }

    // 上一次的记录
    let lastOfflineData = common.getUseOfflineData()

    // 不需要重头来，则去拿上一次的数据出来进行替换
    if (checkRefreshOfflineIpAction === false) {
      if (typeof lastOfflineData.ip !== "undefined") {
        common.useOfflineData.ip = lastOfflineData.ip
      }

      if (typeof lastOfflineData.ipTime !== "undefined") {
        common.useOfflineData.ipTime = lastOfflineData.ipTime
      }

      if (typeof lastOfflineData.ipIndex !== "undefined") {
        common.useOfflineData.ipIndex = lastOfflineData.ipIndex
      }
    }

    if (checkRefreshOfflineMqttAction === false) {
      if (typeof lastOfflineData.mqttUrl !== "undefined") {
        common.useOfflineData.mqttUrl = lastOfflineData.mqttUrl
      }

      if (typeof lastOfflineData.mqttTime !== "undefined") {
        common.useOfflineData.mqttTime = lastOfflineData.mqttTime
      }

      if (typeof lastOfflineData.mqttIndex !== "undefined") {
        common.useOfflineData.mqttIndex = lastOfflineData.mqttIndex
      }
    }

    common.saveUseOfflineData(); // 保存正在使用的边缘数据，用来下次进入检测使用

    // 检测一个可用
    common.handleOfflineConnectIp().then((tempData) => {
      if (tempData) {
        try {
          tempData = JSON.parse(tempData)

          // 没变则不动更新，否则更新
          if (tempData.ip != common.useOfflineData.ip) {
            // 切换
            common.saveOfflineIpByIndex(tempData.index)

            // 切换之后，要重新初始化一下
            window.location.reload()
          }
        } catch (e) {
        }
      }
    })

  } else {
    // 不是边缘的，则清空数据
    common.useOfflineData = {
      ip: '', // 正在使用的边缘ip
      ipTime: '', // 边缘ip切换时间，时间戳，单位秒
      ipIndex: 0, // 正在使用的边缘ip是第几个
      mqttUrl: '', // 正在使用的mqtt的url
      mqttTime: '', // 切换mqtt的时间，时间戳，单位秒
      mqttIndex: 0, // 正在使用的mqtt地址是第几个
    }

    common.removeUseOfflineData(); // 移除之前的使用的边缘数据
  }

  common.offlineCheckTime = biz.getNowTime() // 保存的时候，就是检测的时候

  // 20220124重新启动通知
  if (actionMqtt === 'yes') {
    mqttClient.startMqtt()
  }
}

// 检测一个可用的边缘ip和index
common.handleOfflineConnectIp = () => {
  return new Promise((resolve, reject) => {
    // 非边缘，空字符串
    if (!common.isOfflineMode()) {
      resolve("")
      return;
    }

    // 边缘的，要从头检测一个可用的ip
    let ipList = common.offlineIpList()

    // 没有，返回空字符串，不动
    if (ipList.length === 0) {
      resolve("")
      return;
    }

    // 只有一个，就把这个返回即可
    if (ipList.length === 1) {
      resolve(JSON.stringify({'index': 0, 'ip': ipList[0]}))
      return;
    }

    // 多个则开始检测
    var pList = [];
    for (let i = 0; i < ipList.length; i++) {
      pList.push(common.getTouchTimeByIpUrl(ipList[i]))
    }

    var tempIndex = 0;
    var tempIp = ipList[0] // 默认第一个

    Promise.all(pList).then((allResult) => {

      for (let i = 0; i < ipList.length; i++) {
        if (typeof allResult[i] !== "undefined") {
          if (allResult[i] !== "超时") {
            // 可连接的
            tempIp = ipList[i]
            tempIndex = i;
            break;
          }
        }
      }

      resolve(JSON.stringify({'index': tempIndex, 'ip': tempIp}))
    })
  })
}

// 正在使用的边缘数据(不用存在storage里，因为每次刷新会重新处理的)
common.useOfflineData = {
  ip: '', // 正在使用的边缘ip
  ipTime: '', // 边缘ip切换时间，时间戳，单位秒
  ipIndex: 0, // 正在使用的边缘ip是第几个
  mqttUrl: '', // 正在使用的mqtt的url
  mqttTime: '', // 切换mqtt的时间，时间戳，单位秒
  mqttIndex: 0, // 正在使用的mqtt地址是第几个
}

common.saveUseOfflineData = () => {
  window.localStorage.setItem("offline_use_data", JSON.stringify(common.useOfflineData))
}

common.getUseOfflineData = () => {
  let obj = window.localStorage.getItem("offline_use_data")

  if (!obj) {
    return {};
  }

  try {
    obj = JSON.parse(obj)
  } catch (e) {
    console.log("使用的边缘数据解析失败:" + e.toString())

    obj = {}
  }

  return obj;
}

common.removeUseOfflineData = () => {
  window.localStorage.removeItem("offline_use_data")
}

// 边缘的ip地址数组
common.offlineIpList = () => {
  let ipList = window.localStorage.getItem("offline_ip_list")

  try {
    ipList = JSON.parse(ipList)
  } catch (e) {
    ipList = []
  }

  if ((!ipList) || (ipList === "undefined")) {
    ipList = []
  }

  return ipList
}

// 边缘的mqtt地址数组
common.offlineMqttUrlList = () => {
  // mqtt跟着一起换
  let mqttUrlList = window.localStorage.getItem("offline_mqtt_url_list")

  try {
    mqttUrlList = JSON.parse(mqttUrlList)
  } catch (e) {
    mqttUrlList = []
  }

  if ((!mqttUrlList) || (mqttUrlList === "undefined")) {
    mqttUrlList = []
  }

  return mqttUrlList;
}

// 默认的 边缘地址
common.offlineIp = function () {
  return window.localStorage.getItem("offline_ip")
}

// 默认的 mqtt地址
common.offlineMqttUrl = function () {
  return window.localStorage.getItem("offline_mqtt_url")
}

// 默认的 mqtt的连接地址
common.mqttUrl = function () {
  // 连接地址：默认使用现有的
  // 当门店为边缘的时候，且有mqtt_url的时候，则使用边缘的mqtt_url

  var url = mqttUrl

  if (common.isOfflineMode()) {
    var tempUrl = common.useOfflineData.mqttUrl

    if (tempUrl) {
      url = tempUrl
    }
  }

  return url
}

// 处理一下刚进入页面的边缘数据 --- 防止无网络的时候，能够继续延续上一次的数据(或延续上一次尝试过的可用的边缘ip)
common.offlineUseDataInitForComing = () => {
  // 先把默认的边缘使用的数据，读出来 --- 防止边缘的同步不可用，拿不到之前的数据，用之前的去用
  // 上一次的记录
  let lastOfflineData = common.getUseOfflineData()

  if (lastOfflineData) {
    for (let key in lastOfflineData) {
      common.useOfflineData[key] = lastOfflineData[key]
    }
  }
}

common.isOfflineMode = function () {
  if (common.useOfflineData.ip) {
    return true;
  }
  return false;
}

// 获取对应的API的时间
common.getTouchTimeByIpUrl = function (url) {
  return new Promise((resolve, reject) => {
    var time = new Date().getTime()
    var tempTime = '超时'; // 时间差

    let api = '/health'
    if (common.isOfflineMode()) {
      api = "/taishan/basic/version"
    }

    // 请求测试
    const config = {
      'contentType': 'application/json', // application/x-www-form-urlencoded、multipart/form-data、application/json
      'timeout': 5000,                  // 调用api超时时间为5秒
    }

    let headers = {
      // 'Content-Type': config['contentType'] // 不能加这个，加了就变成了复杂查询，回去查询OPTION，请求不到就跨域了
    }

    axios({
      method: 'get',
      url: url + api,
      // data: {},
      // headers: headers,
      timeout: config.timeout
    }).then((response) => {
      // 请求成功

      var nowTime = new Date().getTime()
      tempTime = nowTime - time

      tempTime = tempTime + 'ms'

      resolve(tempTime)

    }).catch((err) => {
      // console.log(err.response)

      // 请求失败

      resolve(tempTime);
    });

  });
}

// 访问离线的模块
common.offLineModularList = {
  basic: 'BASIC', // 基础模块
  finance: 'FINANCE', // 收银模块
  schedule: 'SCHEDULE', // 排钟模块
}

// 检测 切换边缘数据
common.offlineCheckDefault = () => {
  // 非边缘，不处理
  if (!common.isOfflineMode()) {
    return;
  }

  // 每天9:30之后的第一次打开需要检测切换
  // 边缘的，检测对应的ip、mqtt，自动检测使用最新的可用的ip
  let nowTime = biz.getNowTime();

  // 对应的这个时间的09点30分0秒的时间戳
  let tempDate = new Date(nowTime * 1000)
  tempDate.setHours(9, 30, 0, 0);
  let checkTime = parseInt(tempDate.getTime() / 1000)

  // 检测ip
  if ((common.useOfflineData.ipTime < checkTime) && (nowTime > checkTime)) {
    // 检测一个可用
    common.handleOfflineConnectIp().then((tempData) => {
      if(!tempData) {
        return;
      }

      try {
        tempData = JSON.parse(tempData)

        // 切换
        common.saveOfflineIpByIndex(tempData.index)
      } catch (e) {
        console.log("检测处理边缘ip失败:" + e.toString())
      }
    })
  }
}

// 根据ip的索引，保存数据
common.saveOfflineIpByIndex = (index) => {
  let ipList = common.offlineIpList();

  if (!ipList) {
    common.toast("边缘服务地址数据缺失")
    return;
  }

  if (typeof ipList[index] === "undefined") {
    common.toast("请正确选择使用线路")
    return;
  }

  common.useOfflineData.ipIndex = index;
  common.useOfflineData.ip = ipList[common.useOfflineData.ipIndex]
  common.useOfflineData.ipTime = biz.getNowTime()

  // mqtt跟着一起换
  let mqttUrlList = common.offlineMqttUrlList()

  if (typeof mqttUrlList[common.useOfflineData.ipIndex] === "undefined") {
    common.useOfflineData.mqttIndex = 0; // 对应的不存在，则用第一个
  } else {
    common.useOfflineData.mqttIndex = common.useOfflineData.ipIndex;
  }
  common.useOfflineData.mqttUrl = mqttUrlList[common.useOfflineData.mqttIndex]
  common.useOfflineData.mqttTime = common.useOfflineData.ipTime;

  common.saveUseOfflineData(); // 保存正在使用的边缘数据，用来下次进入检测使用

  // 切换了mqtt，则重连
  if (mqttClient.client != null) {
    mqttClient.client.end();
  }

  setTimeout(() => {
    mqttClient.startMqtt()
  }, 200)
}

// 保存网络线路
common.setNetworkLineUrl = function (url) {
  var result = {
    'url': url,
    'time': (new Date().getTime() / 1000),
  }

  window.sessionStorage.setItem('activeNetworkLineInfo', JSON.stringify(result))
}

// 获取网络线路
common.getNetworkLineUrl = function () {
  var activeNetworkLineInfo = window.sessionStorage.getItem('activeNetworkLineInfo')

  if (!activeNetworkLineInfo) {
    return '';
  }

  try {
    activeNetworkLineInfo = JSON.parse(activeNetworkLineInfo)
  } catch (e) {
    return '';
  }

  if (!activeNetworkLineInfo) {
    return '';
  }

  if (activeNetworkLineInfo == undefined) {
    return '';
  }

  if (typeof activeNetworkLineInfo.url === "undefined") {
    return '';
  }

  if (!activeNetworkLineInfo.url) {
    return '';
  }

  if (activeNetworkLineInfo.url == undefined) {
    return '';
  }

  if (typeof activeNetworkLineInfo.time === "undefined") {
    return '';
  }

  if (!activeNetworkLineInfo.time) {
    return '';
  }

  if (activeNetworkLineInfo.time == undefined) {
    return '';
  }

  var tempTime

  try {
    tempTime = (new Date().getTime() / 1000) - activeNetworkLineInfo.time
  } catch (e) {
    return '';
  }

  if (tempTime < 0) {
    return '';
  }

  // 超过2小时
  if (tempTime > (2 * 60 * 60)) {
    return '';
  }

  return activeNetworkLineInfo.url;
}

// 为"common.apiUr"处理域名
common.handleLocationPreForApiUrl = function (api) {
  // var pre = window.location.protocol + "//" + window.location.host
  //
  // if (env === 'dev') {
  //   pre = baseApi
  // }
  var pre = baseApi

  // 如果是 www1.ku52.cn 替换为  www.ku52.cn
  // 如果是 test1.ku52.cn 替换为  test.ku52.cn
  pre = pre.replace(/www\d\./, "www.")
  pre = pre.replace(/test\d\./, "test.")

  if (window.KUSHU_BASE_URL !== undefined) {
    pre = window.KUSHU_BASE_URL
  }

  // 当session_storage里中有的时候，就用storage中的数据
  var tempNetworkLineUrl = common.getNetworkLineUrl()
  if (tempNetworkLineUrl) {
    pre = tempNetworkLineUrl
  }

  if (common.isOfflineMode()) {
    // 走离线版本
    var basic = common.offLineModularList.basic // 基础
    var finance = common.offLineModularList.finance // 收银
    var schedule = common.offLineModularList.schedule // 排钟

    if ((api.indexOf(basic) === 0) || (api.indexOf(finance) === 0) || (api.indexOf(schedule) === 0)) {
      pre = common.useOfflineData.ip; // 离线版本的前缀
    }

    return pre;
  }

  return pre;
}

// 为"common.apiUrl"处理api和前缀
common.handleApiForApiUrl = function (api) {
  var preStr = ''

  var basic = common.offLineModularList.basic // 基础
  var finance = common.offLineModularList.finance // 收银
  var schedule = common.offLineModularList.schedule // 排钟

  if (common.isOfflineMode()) {
    // 走离线版本

    // 检测模块名
    if (api.indexOf(basic) === 0) {
      // 基础模块
      preStr = '/taishan/basic'
    } else if (api.indexOf(finance) === 0) {
      // 首页模块
      preStr = '/taishan/finance'
    } else if (api.indexOf(schedule) === 0) {
      // 排钟模块
      preStr = '/taishan/schedule'
    }

  }

  // 将前缀去掉
  if (api.indexOf(basic) === 0) {
    // 基础模块
    api = api.substr(basic.length + 1);
  } else if (api.indexOf(finance) === 0) {
    // 首页模块
    api = api.substr(finance.length + 1);
  } else if (api.indexOf(schedule) === 0) {
    // 排钟模块
    api = api.substr(schedule.length + 1);
  }

  return {
    'preStr': preStr,
    'api': api
  };
}

common.getParamFromUrl = function (param) {
  var location = window.location

  if (!location) {
    return '';
  }

  if (typeof location.search === "undefined") {
    return '';
  }

  param = param.toString()

  var search = location.search

  if (search.indexOf('?') === 0) {
    search = search.substring(1);
  }

  // 根据&拆分
  var arr = search.split('&')

  var value = ''

  for (let i = 0; i < arr.length; i++) {
    let pos = arr[i].indexOf('=');

    if (pos === -1) {
      continue;
    }

    let tempParam = arr[i].substring(0, pos)

    if (tempParam !== param) {
      continue;
    }

    value = arr[i].substring(pos + 1);
    break;
  }

  return value
}

common.handleApiUrl = function (api, param) {
  let queryArr = []
  for (let k in param) {
    queryArr.push(encodeURIComponent(k) + '=' + encodeURIComponent(param[k]))
  }

  let queryStr = ''
  if (queryArr.length > 0) {
    const mark = api.indexOf('?') >= 0 ? '&' : '?'
    queryStr = mark + queryArr.join('&')
  }

  return api + queryStr
}

// 处理API的出参
common.handleApiResponse = (response, goLogin, checkCloudGoLogin) => {
  goLogin = goLogin || 'go'; // 未登陆的时候是否进入收银页
  checkCloudGoLogin = checkCloudGoLogin || "yes"; // 云端的API，是否检测 去登录页，默认检测

  return new Promise((resolve, reject) => {
    var status = response.status; // 状态码
    var result = response.data; // 返回的内容

    try {
      let noticeInfoList = [];
      if(typeof result['noticeInfoList'] !== "undefined") {
        noticeMessage.manualPushQueueList(result['noticeInfoList'])
      }
    } catch (e) {
      console.log("处理实时通知失败:" + e.toString())
    }

    /**
     * 兼容php和java
     *
     * php的API返回的格式：
     *  {
     *    "status": true或false,
     *    "data": ...,
     *    "code": xxx,
     *  }
     *
     * java的API返回的格式：
     *  {
     *    "code": "SUCCESS", "SUCCESS"表示正常，其他的表示失败，message表示错误时的提示信息
     *    "message": "xxx",
     *    "data": ...
     *  }
     *
     */

    var resultData; // 返回的数据
    var resultMessage = ''; // 返回的提示文字(用于错误信息)
    var checkResult = false; // API返回的是成功还是失败，默认是失败的
    var resultCode = '' // PHP的API返回专属

    if (typeof result.status !== "undefined") {
      /**
       * 格式：
       * {
       *   "status": xxx,
       *   "data": xxx,
       *   "code": xxx,
       * }
       */
      if (typeof result.data === "undefined") {
        console.log(result)
        reject({
          'status': status,
          'error': "格式有误[result]1",
          'code': (typeof result.code !== "undefined") ? result.code : '',
        });
        return;
      }

      resultData = result.data;
      resultMessage = result.data;

      checkResult = (result.status == true) ? true : false;
      resultCode = (typeof result.code !== "undefined") ? result.code : ''

    } else {
      /**
       * 格式：
       * {
       *   "code": "SUCCESS",
       *   "message": "xxx",
       *   "data": xxx,
       * }
       */

      if (typeof result.code === "undefined") {
        console.log(result)
        reject({
          'status': status,
          'error': "格式有误[result]2",
          'code': "",
        });
        return;
      }

      if (typeof result.data === "undefined") {
        console.log(result)
        reject({
          'status': status,
          'error': "格式有误[result]3",
          'code': "",
        });
        return;
      }

      resultData = result.data
      resultMessage = (typeof result.message !== "undefined") ? result.message : '缺失参数';
      resultMessage = resultMessage ? resultMessage : result.code; // 当message没有值的时候，报code的错误

      checkResult = (result.code == 'SUCCESS') ? true : false;
    }

    if (checkResult === true) {
      resolve(resultData)
      return;
    }

    switch (resultMessage) {
      case 'INVALID_TOKEN':

        // 202207: 黄总要求，边缘门店，走云的，不要直接去登录页，就提示不可操作
        if ((checkCloudGoLogin === "no") && (common.isOfflineMode())) {
          // 提示报错
          common.toast("此功能目前不用，请稍后在试，谢谢")
        } else {
          if (goLogin == 'go') {
            // 不要默认进入登录页
            common.redirectToLogin()
          } else {
            // 离线模式下不进入登录页
            if (common.isOfflineMode()) {
              // 离线模式不动
            } else {
              // 非离线模式下必然要进入登录页
              common.redirectToLogin()
            }
          }
        }

        reject({
          'status': status,
          'error': resultMessage,
          'code': resultCode
        });

        break;

      case 'expire over':
        //跳往账户中心界面
        common.redirectToAccount()

        reject({
          'status': status,
          'error': resultMessage,
          'code': resultCode
        });

        break;

      default:
        break;
    }

    reject({
      'status': status,
      'error': resultMessage,
      'code': resultCode
    });
  })
}

common.oem = function (cb) {
  common.axios('get', common.apiUrl('oem'), {
    domain: window.location.host
  }, 5000, 'no').then((resultData) => {
    cb(resultData)

    common.saveOemInfo(resultData)
  }).catch((errorObj) => {
    // 失败

    // 20210615: 离线模式下，屏蔽错误
    if (common.isOfflineMode()) {
      var tempResult = common.getOemInfo()

      if (tempResult) {
        cb(tempResult)
      }

    }

  }).finally(() => {

  })
}


common.saveOemInfo = function (data) {
  window.localStorage.setItem("oem_info", JSON.stringify(data))
}

common.getOemInfo = function () {
  var data = null;

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem("oem_info")

      data = JSON.parse(data)
    }
  }

  return data;
}


common.saveUserInfo = function (data) {
  window.localStorage.setItem("user_info", JSON.stringify(data))
}


common.getUserInfo = function () {
  var data = null;

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem("user_info")

      data = JSON.parse(data)
    }
  }

  return data;
}

common.deviceTagKey = "ks_pc_device_tag";
if (env === 'test') {
  common.deviceTagKey = env + "-" + common.deviceTagKey
}

common.saveDeviceTag = function (data) {
  let expireDays = 365; // 最新的限制是：最长是400天，这里就保存365天，登录成功后检测是否继续保存更新

  // 设置
  if (domainStr) {
    window.$.cookie(common.deviceTagKey, data, {'path': "/", domain: domainStr, expires: expireDays})
  } else {
    window.$.cookie(common.deviceTagKey, data, {'path': "/", expires: expireDays})
  }

  // https和http之间，localStorage不共享
  // window.localStorage.setItem(common.deviceTagKey, data)
}

common.getDeviceTag = function () {
  var data = "";

  // 去cookie里获取
  data = window.$.cookie(common.deviceTagKey)

  if(data && data !== "null" && (data !== "undefined")) {
    return data;
  }

  //不存在的时候，兼容一下localStorage，去localStorage里获取
  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem(common.deviceTagKey)
    }
  }

  if (data === "null" || data === "undefined") {
    data = ""
  }

  return data;
}

common.saveRoomAreaCondition = function (data) {
  window.localStorage.setItem("room_area_condition", JSON.stringify(data))
}

common.getRoomAreaCondition = function () {
  var data = null;

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem("room_area_condition")

      data = JSON.parse(data)
    }
  }

  return data;
}

common.saveRoomStatusCondition = function (data) {
  window.localStorage.setItem("room_status_condition", JSON.stringify(data))
}

common.getRoomStatusCondition = function () {
  var data = null;

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem("room_status_condition")

      data = JSON.parse(data)
    }
  }

  return data;
}

common.saveRoomTypeCondition = function (data) {
  window.localStorage.setItem("room_type_condition", JSON.stringify(data))
}

common.getRoomTypeCondition = function () {
  var data = null;

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem("room_type_condition")

      data = JSON.parse(data)
    }
  }

  return data;
}

common.axios = function (method, api, data, timeout, goLogin, checkCloudGoLogin) {
  data = data || {}

  data['apiVersion'] = '20240409'
  data['api_front_from'] = 'pc_v4'

  timeout = timeout || 10000; // 默认10秒

  goLogin = goLogin || 'go'; // 未登陆的时候是否进入收银页
  checkCloudGoLogin = checkCloudGoLogin || "yes"; // 云端的API，是否检测 去登录页，默认检测

  const isPost = method.toLowerCase() === 'post'
  const isGet = method.toLowerCase() === 'get'

  const config = {
    'contentType': 'application/json', // application/x-www-form-urlencoded、multipart/form-data、application/json
    'timeout': timeout,                  // 调用api超时时间为5秒
  }

  if (isPost) {
    config['contentType'] = 'application/x-www-form-urlencoded';
    data = qs.stringify(data)
  }

  let headers = {
    'Content-Type': config['contentType']
  }

  // token的处理: api里有"temp_need_t"为no的时候表示不要token
  let needToken = "yes";
  if(api.indexOf("?") !== -1) {
    let tempCheckKey = "temp_need_t"
    let tempPos = api.indexOf(tempCheckKey)
    if(tempPos !== -1) {
      let tempCheckStr = api.substr(tempPos + tempCheckKey.length + 1); // 剩下的
      if(tempCheckStr.startsWith("no")) {
        needToken = "no"
      }
    }
  }
  if (needToken === "yes") {
    headers['employee-token'] = common.getToken();
  }

  // 2023：支持边缘多个IP，自动检测第二天恢复默认的使用
  common.offlineCheckDefault();

  // 处理get传参
  api = common.handleApiUrl(api, isGet ? data : {})

  return new Promise((resolve, reject) => {

    axios({
      method: method,
      url: api,
      data: data,
      headers: headers,
      timeout: config.timeout
    }).then((response) => {
      common.handleApiResponse(response, goLogin, checkCloudGoLogin).then((resultData) => {
        resolve(resultData)
      }).catch((errorObj) => {
        reject(errorObj)
      })
    }).catch((err) => {
      // console.log(err.response)
      var status = '';
      var message = '网络出错哦';

      console.log("网络出错:" + api)

      if (typeof err != "undefined") {

        if (typeof err.response != "undefined") {
          var response = err.response;

          if (typeof response.status !== "undefined") {
            status = response.status
          }

          if (typeof response.data !== "undefined") {
            message = response.data
          }
        }

      }

      if(status == 429) {
        message = "操作失败，请稍后重试";
      }

      reject({
        'status': status,
        'error': message,
        'code': 'NETWORK_ERROR_FRONT'
      });
    });

  });
};

// alert 弹框
common.alert = function (content, callback, title) {
  window.popup.alert(content, callback)
}

// confirm 确认框
common.confirm = function (message, okCallback, cancelCallback, options) {
  window.popup.confirm(message, okCallback, cancelCallback, options)
}

// toast 提示框
common.toast = function (content, timeout, callback) {
  timeout = timeout || 1500;
  if (typeof timeout == "function") {
    callback = timeout;
    timeout = 1500;
  }
  window.popup.cute(content, timeout, callback)
}

// loading 开启
common.loadingStart = function () {
  window.popup.loading(true)
}

// loading 结束
common.loadingStop = function () {
  window.popup.loading(false)
}

//获取总页数
common.getPage = (count, page) => {

  if (count > 0) {
    try {
      let pagerCount = count % page.pageSize == 0 ? count / page.pageSize : count / page.pageSize + 1;
      let c = pagerCount.toFixed(0);//小数取整
      pagerCount = c > pagerCount ? c - 1 : c;//过滤四舍五入
      return pagerCount;

    } catch (error) {
      return 0;
    }

  } else {
    return 0;
  }
}

// cookie操作
common.setCookie = (cookieName, cookieValue, expireDays) => {
  expireDays = expireDays || 365

  let expires = new Date().getTime() + expireDays * 24 * 60 * 60 * 1000
  expires = new Date(expires)

  document.cookie = cookieName + "=" + cookieValue + "; expires=" + expires + "; path=/";
}

common.getCookie = (cookieName) => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find(cookie => cookie.includes(cookieName + '='));
  if (cookie) {
    return cookie.trim().split('=')[1];
  }
  return '';
}

common.deleteCookie = (cookieName) => {
  document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

//生成随机的长度的数字
common.randNumber = function (length) {
  var num = "";
  for (var i = 0; i < length; i++) {
    num += Math.floor(Math.random() * 10);
  }
  return num;
}

// 获取一个随机数
common.getRoundNum = function (min, max) {
  let num = parseInt(Math.random() * (max - min + 1) + min, 10);

  if (num > max) {
    num = max;
  }

  if (num < min) {
    num = min
  }

  return num;
}

/**
 * 将 Date 转化为指定格式的String
 * @param date     date = new Date();      date = new Date(Linux时间戳*1000):
 * @param format "yyyy-m-d h:i:s"
 * @returns string
 */
common.dateFormat = function (date, fmt) {
  // 月(m)、日(d)、小时(h)、分(i)、秒(s)、季度(q) 可以用 1-2 个占位符，
  // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
  // 例子：
  // (new Date()).Format("yyyy-m-dd hh:ii:ss.S") ==> 2006-07-02 08:09:04.423
  // (new Date()).Format("yyyy-m-d h:i:s.S")      ==> 2006-7-2 8:9:4.18

  var o = {
    "m+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "i+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  }
  return fmt;
};

// 处理mqtt连接的密码
common.handleMqttPwd = function (username) {
  /**
   * 密码：
   * md5(用户名 + 用户名单数取出来的字符串) 后的结果里，单数取出来，长度是16位
   */
  var pwd = username + common.handleStrForMqttPwd(username)
  pwd = md5(pwd)

  // 单数取出来做密码
  pwd = common.handleStrForMqttPwd(pwd)

  return pwd;
}

// 从一个字符串里将单数的字符拿出来，拼接为一个新的字符串
common.handleStrForMqttPwd = function (str) {
  let newStr = ''

  for (let i = 0; i < str.length; i++) {
    if ((i % 2) === 0) {
      // i 是 2的 倍数，那么对应到下标从0开始的，就是单数的数据
      newStr += str[i]
    }
  }

  return newStr
}


common.jQueryAjax = function (ajax) {

  //true：异步   false：同步   默认为true
  ajax = {
    ...ajax,
    async: (ajax.async === undefined) ? true : (!!ajax.async),
  }

  // 当url中有2048的，不加版本号参数
  var ajaxUrl = ajax.url
  ajaxUrl = ajaxUrl.toString()
  if (ajaxUrl.indexOf(':2048/') === -1) {
    if (((typeof ajax.data) == "undefined") || (!ajax.data)) {
      ajax = {
        ...ajax,
        data: {}
      }
    }

    ajax.data = {
      ...ajax.data,
      version: '20190712'
    }
  }

  var callback = ajax.success;

  ajax = {
    ...ajax,
    success: function (result) {
      if (typeof result === "object" && "status" in result && result.status == false && result.data === "INVALID_TOKEN") {

        // 离线模式下，云端的，不要跳走。这边的ajax的请求，也只有云端的在用了
        if (!common.isOfflineMode()) {
          common.redirectToLogin()
        }

        return;
      }

      //过期
      if (typeof result === "object" && "status" in result && result.status == false && result.data === 'expire over') {
        //跳往账户中心界面
        common.redirectToAccount();

        return false;
      }

      callback(result);
    }
  }

  ajax = {
    ...ajax,
    timeout: 20000, // 超时时间
  }

  window.$.ajax(ajax);
};

// 储存手机号, 保留前十位
// mobile: 手机号
// name: 姓名
// 用法如：common.saveMobile("13333333333", "tom")
common.saveMobile = (mobile, name) => {
  // 手机号格式不正确, 直接返回
  if (mobile.length !== 11) {
    return
  }

  // 姓名不传就为空
  name = name || ''

  // 获取已经存的手机号, 没有就为空数组
  let mobileArr = JSON.parse(localStorage.getItem('mobileList')) || []
  let data = []
  let isRepeat = false

  // 遍历获取的已存手机号, 为空直接跳过遍历
  mobileArr.forEach((item, key) => {
    if (item.mobile !== mobile) {
      data.push(item)
    } else {
      // 已存手机号若已存在, 则改变他的名称
      if (name !== '') {
        item.name = name
      }
      // 放第一个位置
      data.splice(0, 0, {"name": item.name, "mobile": item.mobile})

      // 已存手机号若已存在, 改变isRepeat状态, 直接去储存
      isRepeat = true
    }
  })

  // isRepeat任然是false, 则新增传入的手机号
  if (!isRepeat) {

    // 若已储存的手机号是已经>=10了, 则删除最后一个(更早期储存的手机号)
    if (data.length >= 10) {
      data.splice(9)
    }

    // 将新增的手机号和姓名放在第一个, 优先展示
    data.splice(0, 0, {"name": name, "mobile": mobile})
  }
  // 储存手机号到localStorage
  localStorage.setItem('mobileList', JSON.stringify(data))

  return {"name": name, "mobile": mobile}
}

// 判断一个数是否在数组中出现
common.in_array = function (val, arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] == val) {
      return true
    }
  }
  return false
}

common.sleep = async function (time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

// 去掉手机号前后以及中间的空格
common.trimBlank = function (str) {
  str = str.toString()
  return str.replace(/\s*/g, "")
}

common.dispatch = null

export default common;