import common from "./common";
import business from "./business";
import voice from "./voice";
import print from "./print";
import indexedDB from "./indexedDB";
import BigNumber from "bignumber.js";
import {v4 as uuidv4} from "uuid";

import {baseApi, env} from "../config-local";

import {
  setBeginBillType,
  setProjectList,
  setProjectObjById,
  setProjectObjByNumber,
  setHandNumberList,
  setHandNumberObjById,
  setHandNumberObjByNumber,
  setHandNumberObjByCode,
  setRoomList,
  setRoomObjById,
  setRoomObjByNumber,
  setEmployeeList,
  setEmployeeObjByEmployeeId,
  setEmployeeObjByNumber,
  setCodeList,
  setTagProjectList,
  setServeBillList,
  setBillObjByHandId,
  setBillObjById,
  setBillListGroupBatchId,
  setBillListGroupRoomId,
  setBatchIdsGroupRoom,
  setRoomIdsGroupBatch,
  setBillAllConsumeList,
  setBillAllConsumeObjById,
  setBillAllConsumeListGroupEmployeeId,
  setAllConsumeListGroupRoomId,
  setAllConsumeRoomIds,
  setAllConsumeRoomObjByRoomId,
  setNoEndConsumeListGroupEmployeeId,
  setRoomOpenList,
  setRoomOpenListGroupByRoomId,
  setCallServeList,
  setAppointOrderList,
  setNoEndConsumeList,
  setEmployeeTagList,
  setProjectTypeList,
  setRoomAreaList,
  setRoomTypeList,
  setBookingInfoList,
  setUserInfo,
  setIpadCode,
  setReportList,
  setCustomerBooking,
  setUserBookingOnlineData,
  setShiftList,
  setShiftObjById,
  setEmployeeStarLevelList,
  setPayTypeTypeList,
  setPayTypes,
  setBillTypeList,
  setAppointOrderListByEmployeeId,
  setAppointOrderListByRoomId,
  setFrontWaitRemindData,
  setCodeObjByCode,
  setCodeObjByDoorClock,
  setEmployeeBookingList,
  setPhraseList,
  setFinanceRoomContent,
  setEmployeeShowPercentage,
  setFrontRoomShowPercentage,
  setPcAppointShowTagProject,
  setManualPricePlanList,
  setSystemUseType,
  setRoomShowHandSetting,
  setRoomShowPageV4Finance,
  setBusyEmployeeShowClock,
  setMemberSplitOrder,
  setAppointWait,
  setRoomShowMoney,
  setCallServeRoomShow,
  setFrontBottomEmployeeAddClockFlicker,
  setStoreDue,
  setEmployeeClockType,
  setFrontEmployeeShow,
  setEmployeeInfo,
  setStoreWaitServeBookingList,
  setBottomShowTagObj,
} from "../reducers/app"
import noticeMessage from "./noticeMessage";
import mqttClient from "./mqttClient";
import {resolve} from "styled-jsx/css";

let biz = {}

// 当前时间的数据
biz.nowTime = parseInt((new Date().getTime() / 1000))

//时间
biz.dateTime = () => {
  var today, year, month, day, hour, minute, second, weekday, strDate;
  today = new Date(parseInt(biz.nowTime) * 1000);
  weekday = today.getDay();
  switch (weekday) {
    case 0: {
      strDate = "星期日";
    }
      break;
    case 1: {
      strDate = "星期一";
    }
      break;
    case 2: {
      strDate = "星期二";
    }
      break;
    case 3: {
      strDate = "星期三";
    }
      break;
    case 4: {
      strDate = "星期四";
    }
      break;
    case 5: {
      strDate = "星期五";
    }
      break;
    case 6: {
      strDate = "星期六";
    }
      break;
  }
  year = today.getFullYear();
  month = today.getMonth() + 1;
  day = today.getDate();
  hour = today.getHours();
  minute = today.getMinutes();
  second = today.getSeconds();
  if (month.toString().length < 2) month = "0" + month;
  if (day.toString().length < 2) day = "0" + day;
  if (hour.toString().length < 2) hour = "0" + hour;
  if (minute.toString().length < 2) minute = "0" + minute;
  if (second.toString().length < 2) second = "0" + second;
  // var clock = hour + ":" + minute + ":" + second;
  var clock = hour + ":" + minute;
  var str = year + "-" + month + "-" + day + " " + strDate;
  var date = year + '-' + month + '-' + day;

  return {
    clock: clock,
    str: str,
    date: date
  };
}

// 商户信息处理
biz.handleUser = (userData) => {
  common.setCookie("user.id", userData['user_id']);

  // 客房通讯码

  //获取是否已经有pad验证码
  var code = common.getCookie('pad-code');

  if (!code) {
    //生成随机的验证码
    code = common.randNumber(4);
    common.setCookie('pad-code', code);
  }

  code = common.getCookie('user.id') + '-' + code;

  common.dispatch(setIpadCode(code))
  common.dispatch(setReportList(userData['reports']))

  // 处理默认标签
  let tempBottomShowTagObj = biz.getBottomShowTagObj(userData['user_id'])
  common.dispatch(setBottomShowTagObj(tempBottomShowTagObj))
}

// 检测是否为超管
biz.checkManageForInitLoad = () => {
  return new Promise((resolve, reject) => {
    //检测是否为超管身份，如果是超管身份，检测是否可以进入收银，如果可以则进入booking

    biz.getDataForCheckManageForInitLoad().then((resultData) => {
      biz.checkManageAction(resultData['data'], resultData['cacheAction'])

      resolve();
    }).catch((error) => {
      reject(error);
    })
  });
}

// 检测是否为超管
biz.getDataForCheckManageForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('post', common.apiUrl('inti/check-manage'), {}, 5000, 'no').then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          resolve({
            'data': {
              'manage': false, // 默认非超管
            },
            'cacheAction': 0
          })
          return;
        }

        common.alert(errorObj.error);
        reject(errorObj.error);
        return;
      }

      // 20210615: 离线模式下，屏蔽错误
      if (common.isOfflineMode()) {
        resolve({
          'data': {
            'manage': false, // 默认非超管
          },
          'cacheAction': 0
        })
        return;
      }

      // 从缓存里获取超管数据
      indexedDB.getOneFromConfigByNameByIndexedDB('check_manage').then((resultData) => {
        resolve({'data': resultData.data, 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error + "[" + errorObj.status + "]");
      })
    })
  });
}

// 检测是否为超管的执行内容，只为"checkManageForInitLoad"提供
biz.checkManageAction = (checkManage, cacheAction) => {
  // 将数据放入缓存
  indexedDB.putIntoConfigByIndexedDB('check_manage', checkManage, cacheAction);

  //检测是否为超管
  if (checkManage && checkManage['manage']) {
    //检测是否可以进入收银
    if (!checkManage['front']) {
      common.alert('当前账户不可进入收银界面');
      return false;
    }

    // todo 202308: 新版的没有预约页面
    // //判断当前  的URL  是不是booking  如果不是的话，则进入booking
    // var locationHref = window.location.href;
    //
    // //检测booking是否存在，不存在的话，则跳往booking
    // if (locationHref.indexOf('booking') === -1) {
    //   window.location.href = locationHref + 'booking';
    // }
  }

  return true;
}


// 初始化界面加载需要的门店数据 --- 为"initLoad"服务
biz.initDataForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForInitDataForInitLoad().then((resultData) => {
      biz.handleForInitDataForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 初始化界面加载需要的门店数据-获取数据 --- 为"initLoad"服务
biz.getDataForInitDataForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('post', common.apiUrl('init/data-v3'), {}, 5000, 'no').then((result) => {
      biz.saveUserInfo(result['user']) // 将用户信息保存

      resolve({'data': result, 'cacheAction': 1})
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {

        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          let userInfo = biz.getUserInfo()

          resolve({
            'data': {
              'booking_list': [],
              'booking_online_list': [],
              'user': userInfo ? userInfo : {
                'user_id': 0
              },
              'user_list': [],
            }, 'cacheAction': 0
          })
          return;
        }

        reject(errorObj.error);
        return;
      }

      // 20210615: 离线模式下，屏蔽错误
      if (common.isOfflineMode()) {
        let userInfo = biz.getUserInfo()

        resolve({
          'data': {
            'booking_list': [],
            'booking_online_list': [],
            'user': userInfo ? userInfo : {
              'user_id': 0
            },
            'user_list': [],
          }, 'cacheAction': 0
        })
        return;
      }

      biz.getCacheDataForInitDataForInitLoad().then((resultData) => {
        resolve({'data': resultData, 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 初始化界面加载需要的门店数据-获取缓存数据 --- 为"initLoad"服务
biz.getCacheDataForInitDataForInitLoad = () => {
  return new Promise((resolve, reject) => {
    var resultData = {};

    var pList = [
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.customer_booking, 'booking_list'), // 预约客户信息  在店预约的
      indexedDB.getOneFromConfigByNameByIndexedDB('user'), // 商户信息
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.user_booking_online, 'booking_online_list'), // 查询在线预约的数据
    ];

    Promise.all(pList).then((resultList) => {

      for (let i = 0; i < resultList.length; i++) {
        var tempResult = resultList[i]

        resultData[resultList[i]['name']] = resultList[i]['data']
      }

      resolve(resultData);

    }).catch((tempData) => {
      reject(tempData);
    });
  })
}

// 初始化界面加载需要的门店数据-处理 --- 为"initLoad"服务
biz.handleForInitDataForInitLoad = (resultData, cacheAction) => {
  //预约客户信息  在店预约的
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.customer_booking, resultData['booking_list'], cacheAction) // 放入缓存
  business.customer_booking = resultData['booking_list']

  //商户信息
  indexedDB.putIntoConfigByIndexedDB('user', resultData['user'], cacheAction)
  biz.handleUser(resultData['user']);

  //查询在线预约的数据
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.user_booking_online, resultData['booking_online_list'], cacheAction) // 放入缓存
  business.userBookingOnlineData = resultData['booking_online_list'];
}

// 初始化
biz.initForInitLoad = () => {
  return new Promise((resolve, reject) => {
    biz.getDataForInitForInitLoad().then((resultData) => {
      // 处理数据
      biz.handleForInitForInitLoad(resultData['data'], resultData['cacheAction']).then(() => {
        resolve();
      }).catch((data) => {
        reject(data);
      })
    }).catch((error) => {
      reject(error)
    })
  })
}

biz.getDataForInitForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/init/v2')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (errorObj.status == 200) {
        reject(errorObj.error);
        return;
      }

      biz.getCacheDataForInitForInitLoad().then((resultData) => {
        resolve({'data': resultData, 'cacheAction': 0});
      }).catch((tempE) => {
        reject(errorObj.error  + "[" + errorObj.status + "]")
      })
    })
  });
}

biz.getCacheDataForInitForInitLoad = () => {
  return new Promise((resolve, reject) => {
    var resultData = {};

    var pList = [
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.shift, 'shift_list'), // 班次
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.star_level, 'star_level_list'), // 星级
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.project_type, 'project_type_list'), // 项目类型
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.room_type, 'room_type_list'), // 房间类型
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.room_area, 'room_area_list'), // 房间区域类型
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.pay_type_type, 'pay_type_type_list'), // 支付方式类型
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.pay_type, 'pay_type_list'), // 支付方式
      indexedDB.getOneFromConfigByNameByIndexedDB('create_bill_type'), // 开单方式
      indexedDB.getOneFromConfigByNameByIndexedDB('checkout_print_num'), // 结账打印小票数量
      indexedDB.getOneFromConfigByNameByIndexedDB('card_print_num'), // 会员操作打印小票数量
      indexedDB.getOneFromConfigByNameByIndexedDB('rounding_rule'), // 取整规则
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.employee_tag, 'employee_tag_list'), // 技师标签
      indexedDB.getOneFromConfigByNameByIndexedDB('front_room_and_bottom_employee'), // 收银房间、底部技师界面
      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.bill_type, 'bill_type_list'), // 账单类型
      indexedDB.getOneFromConfigByNameByIndexedDB('employee_info'), // 当前用户
      indexedDB.getOneFromConfigByNameByIndexedDB('show_not_shift_employee'), // 是否显示非当班人员
      indexedDB.getOneFromConfigByNameByIndexedDB('open_time'), // 营业开始时间
      indexedDB.getOneFromConfigByNameByIndexedDB('close_time'), // 营业结束时间
      indexedDB.getOneFromConfigByNameByIndexedDB('auto_begin_service'), // 自动起钟时间(分钟)
      indexedDB.getOneFromConfigByNameByIndexedDB('project_add'), // 增加品项时是否自动为加钟
      indexedDB.getOneFromConfigByNameByIndexedDB('booking_time'), // 获取 门店的预约的开始和结束时间  距离当天凌晨的秒数
      indexedDB.getOneFromConfigByNameByIndexedDB('front_begin_bill_with_project'), // 开单选项目
      indexedDB.getOneFromConfigByNameByIndexedDB('front_default_employee_show'), // 中间的技师状态
      indexedDB.getOneFromConfigByNameByIndexedDB('front_only_show_booking_employee'), // 是否只显示有预约的技师
      indexedDB.getOneFromConfigByNameByIndexedDB('front_employee_show'), // 收银轮次排钟显示方式 {"1":"轮次排钟下移","2":"轮次排钟后移"}
      indexedDB.getOneFromConfigByNameByIndexedDB('serve_front_notice'), // 服务是否通知收银
      indexedDB.getOneFromConfigByNameByIndexedDB('call_serve_room_show'), // 房间服务数据是否闪烁
      indexedDB.getOneFromConfigByNameByIndexedDB('front_hand_and_room_show_appoint_info'), // 收银 手牌/房间状态 是否展示预约信息
      indexedDB.getOneFromConfigByNameByIndexedDB('front_footer_show_consume_data'), // 收银底部是否展示动态消费数据
      indexedDB.getOneFromConfigByNameByIndexedDB('front_bottom_employee_add_clock_flicker'), // 收银底部 加钟技师 是否闪动
      indexedDB.getOneFromConfigByNameByIndexedDB('service_out_add_consume_begin_time'), // 服务外技师添加消费的起钟时间规则
      indexedDB.getOneFromConfigByNameByIndexedDB('front_show_appoint_man_quantity'), // 前台是否展示排钟客人数量
      indexedDB.getOneFromConfigByNameByIndexedDB('bill_room_show_consume'), // 跨房间消费是否显示在房间上
      indexedDB.getOneFromConfigByNameByIndexedDB('pc_appoint_show_tag_project'), // pc排钟是否只显示本标签下分配过的项目
      indexedDB.getOneFromConfigByNameByIndexedDB('pc_checkout_show_zero_goods'), // 结账是否默认显示0元商品
      indexedDB.getOneFromConfigByNameByIndexedDB('pc_call_service_notice_voice'), // PC是否声音提示服务信息
      indexedDB.getOneFromConfigByNameByIndexedDB('pc_bill_sort'), // PC账单排序
      indexedDB.getOneFromConfigByNameByIndexedDB('hand_number_show_remark'), // 手牌是否显示备注
      indexedDB.getOneFromConfigByNameByIndexedDB('hand_number_room_open'), // 手牌下是否启用开房
      indexedDB.getOneFromConfigByNameByIndexedDB('has_clock'), // 门店是否有门柜锁
      indexedDB.getOneFromConfigByNameByIndexedDB('bill_notice_stay'), // 手牌在场超过xxx分钟提醒 -- 20210929: 这是新上的功能，店端门店没有的，要过一端时间再考虑打开从缓存里读取
      indexedDB.getOneFromConfigByNameByIndexedDB('bill_notice_money'), // 手牌消费达xxx金额提醒
      indexedDB.getOneFromConfigByNameByIndexedDB('bill_notice_no_consume'), // 手牌xxx分钟未消费提醒
      indexedDB.getOneFromConfigByNameByIndexedDB('bill_show_content'), // 账单显示的内容
      indexedDB.getOneFromConfigByNameByIndexedDB('door_lock_type'), // 门柜锁类型
      indexedDB.getOneFromConfigByNameByIndexedDB('pay_close_door_lock'), // 结账是否关门
      indexedDB.getOneFromConfigByNameByIndexedDB('room_close_can_one_hand_rule'), // 关房是否可以单个手牌关房
      indexedDB.getOneFromConfigByNameByIndexedDB('offline_version'), // 边缘门店的离线版本
      indexedDB.getOneFromConfigByNameByIndexedDB('booking_version'), // 预约版本
      indexedDB.getOneFromConfigByNameByIndexedDB('pay_version'), // 支付版本
      indexedDB.getOneFromConfigByNameByIndexedDB('no_bill_can_group'), // 空手牌是否可以拼为团体
      indexedDB.getOneFromConfigByNameByIndexedDB('finance_room_content'), // 前台显示的房间内容
      indexedDB.getOneFromConfigByNameByIndexedDB('begin_bill_room_content'), // 开单过程中显示的房间内容
      indexedDB.getOneFromConfigByNameByIndexedDB('pc_checkout_room_content'), // 消费清单显示房间内容
      indexedDB.getOneFromConfigByNameByIndexedDB('pc_room_show_appoint_create'), // 房间上是否显示排钟按钮
      indexedDB.getOneFromConfigByNameByIndexedDB('door_qrcode'), // 门柜锁是否支持二维码开锁
      indexedDB.getOneFromConfigByNameByIndexedDB('employee_appoint_flash'), // 技师排钟超时xx分钟为其中是否闪烁
      indexedDB.getOneFromConfigByNameByIndexedDB('service_bill_show_content'), // 服务中手牌右上角显示内容
      indexedDB.getOneFromConfigByNameByIndexedDB('overtime_consume_setting'), // 使用加班消费
      indexedDB.getOneFromConfigByNameByIndexedDB('system_use_type'), // 系统使用类型
      indexedDB.getOneFromConfigByNameByIndexedDB('room_show_hand'), // 房间显示手牌
      indexedDB.getOneFromConfigByNameByIndexedDB('room_show_page_v4_finance'), // 房间展示是否分页[新版收银]
      indexedDB.getOneFromConfigByNameByIndexedDB('busy_employee_show_clock'), // 服务中的技师是否显示钟类型
      indexedDB.getOneFromConfigByNameByIndexedDB('member_split_order'), // 会员拆单是否自动显示上一次的手机号和验证码
      indexedDB.getOneFromConfigByNameByIndexedDB('appoint_can_wait'),
      indexedDB.getOneFromConfigByNameByIndexedDB('room_show_money'),
      indexedDB.getOneFromConfigByNameByIndexedDB('has_clock'),
    ];

    Promise.all(pList).then(function (resultList) {

      for (let i = 0; i < resultList.length; i++) {
        var tempResult = resultList[i]

        resultData[resultList[i]['name']] = resultList[i]['data']
      }

      resolve(resultData);

    }).catch(function (tempData) {
      reject(tempData);
    });
  })
}

// 初始化权限
biz.taskForInitLoad = () => {
  return new Promise((resolve, reject) => {
    biz.getDataForTaskForInitLoad().then((resultData) => {
      // 处理数据
      biz.handleForTaskForInitLoad(resultData['data'], resultData['cacheAction']).then(() => {
        resolve();
      }).catch((data) => {
        reject(data)
      })
    }).catch((error) => {
      reject(error)
    })
  })
}

biz.getDataForTaskForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('init/task'), {}, 5000, 'no').then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (errorObj.status == 200) {
        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          resolve({'data': {}, 'cacheAction': 0})
          return;
        }

        reject(errorObj.error);
        return;
      }

      // 20210615: 离线模式下，屏蔽错误
      if (common.isOfflineMode()) {
        resolve({'data': {}, 'cacheAction': 0})
        return;
      }

      biz.getCacheDataForTaskForInitLoad().then((resultData) => {
        resolve({'data': resultData, 'cacheAction': 0});
      }).catch(() => {
        reject(errorObj.error +  + "[" + errorObj.status + "]")
      })
    })
  });
}

biz.getCacheDataForTaskForInitLoad = () => {
  return new Promise((resolve, reject) => {
    var resultData = {};

    var pList = [
      indexedDB.getOneFromConfigByNameByIndexedDB('front_cancel_booking'), // 收银是否可以取消预约
      indexedDB.getOneFromConfigByNameByIndexedDB('front_update_sell_employee'), // 收银是否可以修改营销人员
      indexedDB.getOneFromConfigByNameByIndexedDB('can_member_use_pwd'), // 会员是否启用密码
      indexedDB.getOneFromConfigByNameByIndexedDB('tv_code'), // 技师房通讯码
      indexedDB.getOneFromConfigByNameByIndexedDB('front_room_show_percentage'), // 房间显示比例
      indexedDB.getOneFromConfigByNameByIndexedDB('front_bottom_employee_show_percentage'), // 底部技师显示比例
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_room'), // 实时营运报告房间
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_employee'), // 实时营运报告技师
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_wait_money'), // 实时营运报告待结金额
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_wait_person'), // 实时营运报告待结客流
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_finish_person'), // 实时营运报告已结客流
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_total_person'), // 实时营运报告总客流
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_booking'), // 实时营运报告预约人数
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_finish_order_money'), // 实时营运报告已结金额服务收入
      indexedDB.getOneFromConfigByNameByIndexedDB('front_report_finish_card_money'), // 实时营运报告已结金额售卡收入
      indexedDB.getOneFromConfigByNameByIndexedDB('hidden_list'), // 员工的隐藏列表
    ];

    Promise.all(pList).then(function (resultList) {

      for (let i = 0; i < resultList.length; i++) {
        var tempResult = resultList[i]

        let tempData = resultList[i]['data']
        let tempName = resultList[i]['name']

        if (tempData) {
          if (tempName == 'hidden_list') {
            tempData = JSON.parse(tempData)
          }
        }

        resultData[tempName] = tempData
      }

      resolve(resultData);

    }).catch(function (tempData) {
      reject(tempData);
    });
  })
}

// 实体卡 --- 为"initLoad"服务
biz.initCodeForInitLoad = () => {
  return new Promise((resolve, reject) => {

    biz.getDataForInitCodeForInitLoad().then((resultData) => {
      biz.handleForInitCodeForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 实体卡获取数据 --- 为"initLoad"服务
biz.getDataForInitCodeForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/code')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.code).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]")
      })
    })
  });
}

// 实体卡-处理数据 --- 为"initLoad"服务
biz.handleForInitCodeForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.code, resultData, cacheAction) // 放入缓存

  business.codeList = resultData;

  // 处理，将Code和门柜锁处理为小写
  biz.handleCodeList(business.codeList)
}


// 手牌 --- 为"initLoad"服务
biz.handNumberForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForHandNumberForInitLoad().then((resultData) => {
      biz.handleForHandNumberForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 手牌-获取数据 --- 为"initLoad"服务
biz.getDataForHandNumberForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/hand-number')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.hand_number).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 手牌-处理数据 --- 为"initLoad"服务
biz.handleForHandNumberForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.hand_number, resultData, cacheAction) // 放入缓存

  //所有手牌
  var handNumberList = biz.handleData(resultData);

  common.dispatch(setHandNumberList(handNumberList))

  // 处理手牌数据
  biz.handleHandNumberList(business.handNumberList);
}


// 初始化的数据处理
// 初始化数据-处理数据 --- 为"initLoad"服务
biz.handleForInitForInitLoad = (resultData, cacheAction) => {
  var dispatch = common.dispatch

  return new Promise((resolve, reject) => {
    // 当前登录用户
    // $(".js-employee-name").html('当前用户：' + resultData['employee_info'].name);
    indexedDB.putIntoConfigByIndexedDB('employee_info', resultData['employee_info'], cacheAction) // 放入缓存
    dispatch(setEmployeeInfo(resultData['employee_info']))

    // 班次
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.shift, resultData['shift_list'], cacheAction) // 放入缓存
    let shiftList = resultData['shift_list'];
    dispatch(setShiftList(resultData['shift_list']))

    // 班次id为key
    let shiftObjById = {}
    for (let i = 0; i < shiftList.length; i++) {
      shiftObjById[shiftList[i]['id']] = shiftList[i]
    }
    dispatch(setShiftObjById(shiftObjById))

    //星级
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.star_level, resultData['star_level_list'], cacheAction) // 放入缓存
    dispatch(setEmployeeStarLevelList(resultData['star_level_list']))

    //项目类型
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.project_type, resultData['project_type_list'], cacheAction) // 放入缓存
    dispatch(setProjectTypeList(resultData['project_type_list']))

    //房间类型
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.room_type, resultData['room_type_list'], cacheAction) // 放入缓存
    dispatch(setRoomTypeList(resultData['room_type_list']))

    //房间区域类型
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.room_area, resultData['room_area_list'], cacheAction) // 放入缓存
    dispatch(setRoomAreaList(resultData['room_area_list']));

    //支付方式类型
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.pay_type_type, resultData['pay_type_type_list'], cacheAction) // 放入缓存
    dispatch(setPayTypeTypeList(resultData['pay_type_type_list']));

    //支付方式
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.pay_type, resultData['pay_type_list'], cacheAction) // 放入缓存
    dispatch(setPayTypes(resultData['pay_type_list']));

    //开单方式
    dispatch(setBeginBillType(resultData['create_bill_type']))
    indexedDB.putIntoConfigByIndexedDB('create_bill_type', resultData['create_bill_type'], cacheAction) // 放入缓存

    // 初始化中间内容
    business.front_room_and_bottom_employee = resultData['front_room_and_bottom_employee']
    indexedDB.putIntoConfigByIndexedDB('front_room_and_bottom_employee', resultData['front_room_and_bottom_employee'], cacheAction) // 放入缓存

    //结账打印小票数量
    business.checkoutPrintNum = resultData['checkout_print_num'];
    indexedDB.putIntoConfigByIndexedDB('checkout_print_num', resultData['checkout_print_num'], cacheAction) // 放入缓存

    //会员操作打印小票数量
    business.cardPrintNum = resultData['card_print_num'];
    indexedDB.putIntoConfigByIndexedDB('card_print_num', resultData['card_print_num'], cacheAction) // 放入缓存

    //取整规则
    business.userRoundingRule = resultData['rounding_rule'];
    indexedDB.putIntoConfigByIndexedDB('rounding_rule', resultData['rounding_rule'], cacheAction) // 放入缓存

    //技师标签
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.employee_tag, resultData['employee_tag_list'], cacheAction) // 放入缓存
    dispatch(setEmployeeTagList(resultData['employee_tag_list']))

    //收银房间、底部技师界面
    business.front_room_and_bottom_employee = resultData['front_room_and_bottom_employee'];

    //账单类型
    indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.bill_type, resultData['bill_type_list'], cacheAction) // 放入缓存
    dispatch(setBillTypeList(resultData['bill_type_list']));

    //是否显示非当班人员
    business.show_not_shift_employee = resultData['show_not_shift_employee'];
    indexedDB.putIntoConfigByIndexedDB('show_not_shift_employee', resultData['show_not_shift_employee'], cacheAction) // 放入缓存

    //获取  门店的营业开始时间、结束时间  时间需要处理
    business.openTime = parseInt(resultData['open_time']);
    business.closeTime = parseInt(resultData['close_time']);
    indexedDB.putIntoConfigByIndexedDB('open_time', resultData['open_time'], cacheAction) // 放入缓存
    indexedDB.putIntoConfigByIndexedDB('close_time', resultData['close_time'], cacheAction) // 放入缓存

    //自动起钟时间(分钟)
    business.autoBeginService = resultData['auto_begin_service'];
    indexedDB.putIntoConfigByIndexedDB('auto_begin_service', resultData['auto_begin_service'], cacheAction) // 放入缓存

    //增加品项时是否自动为加钟
    business.projectAdd = resultData['project_add'];
    indexedDB.putIntoConfigByIndexedDB('project_add', resultData['project_add'], cacheAction) // 放入缓存

    //获取 门店的预约的开始和结束时间  距离当天凌晨的秒数
    business.booking_time = {
      "openTime": parseInt(resultData['booking_time']['openTime']),
      "closeTime": parseInt(resultData['booking_time']['closeTime']),
    };
    indexedDB.putIntoConfigByIndexedDB('booking_time', resultData['booking_time'], cacheAction) // 放入缓存

    // 开单选项目
    business.front_begin_bill_with_project = resultData['front_begin_bill_with_project'];
    indexedDB.putIntoConfigByIndexedDB('front_begin_bill_with_project', resultData['front_begin_bill_with_project'], cacheAction) // 放入缓存

    // 中间的技师状态
    business.front_default_employee_show = resultData['front_default_employee_show'];
    indexedDB.putIntoConfigByIndexedDB('front_default_employee_show', resultData['front_default_employee_show'], cacheAction) // 放入缓存

    // 是否只显示有预约的技师
    business.front_only_show_booking_employee = resultData['front_only_show_booking_employee'];
    indexedDB.putIntoConfigByIndexedDB('front_only_show_booking_employee', resultData['front_only_show_booking_employee'], cacheAction) // 放入缓存

    // 收银轮次排钟显示方式 {"1":"轮次排钟下移","2":"轮次排钟后移"}
    dispatch(setFrontEmployeeShow(resultData['front_employee_show']))
    indexedDB.putIntoConfigByIndexedDB('front_employee_show', resultData['front_employee_show'], cacheAction) // 放入缓存

    // 服务是否通知收银
    business.serveFrontNotice = resultData['serve_front_notice']
    indexedDB.putIntoConfigByIndexedDB('serve_front_notice', resultData['serve_front_notice'], cacheAction) // 放入缓存

    // 服务是否通知收银
    dispatch(setCallServeRoomShow(resultData['call_serve_room_show']))
    indexedDB.putIntoConfigByIndexedDB('call_serve_room_show', resultData['call_serve_room_show'], cacheAction) // 放入缓存

    // 收银 手牌/房间状态 是否展示预约信息
    business.front_hand_and_room_show_appoint_info = resultData['front_hand_and_room_show_appoint_info']
    indexedDB.putIntoConfigByIndexedDB('front_hand_and_room_show_appoint_info', resultData['front_hand_and_room_show_appoint_info'], cacheAction) // 放入缓存

    // 收银底部是否展示动态消费数据
    business.front_footer_show_consume_data = resultData['front_footer_show_consume_data'];
    indexedDB.putIntoConfigByIndexedDB('front_footer_show_consume_data', resultData['front_footer_show_consume_data'], cacheAction) // 放入缓存

    // 收银底部 加钟技师 是否闪动
    dispatch(setFrontBottomEmployeeAddClockFlicker(resultData['front_bottom_employee_add_clock_flicker']))
    indexedDB.putIntoConfigByIndexedDB('front_bottom_employee_add_clock_flicker', resultData['front_bottom_employee_add_clock_flicker'], cacheAction) // 放入缓存

    // 服务外技师添加消费的起钟时间规则
    business.service_out_add_consume_begin_time = resultData['service_out_add_consume_begin_time'];
    indexedDB.putIntoConfigByIndexedDB('service_out_add_consume_begin_time', resultData['service_out_add_consume_begin_time'], cacheAction) // 放入缓存

    // 前台是否展示排钟客人数量
    business.frontShowAppointManQuantity = resultData['front_show_appoint_man_quantity'];
    indexedDB.putIntoConfigByIndexedDB('front_show_appoint_man_quantity', resultData['front_show_appoint_man_quantity'], cacheAction) // 放入缓存

    // 跨房间消费是否显示在房间上
    business.billMoreRoomShowConsume = resultData['bill_room_show_consume'];
    indexedDB.putIntoConfigByIndexedDB('bill_room_show_consume', resultData['bill_room_show_consume'], cacheAction) // 放入缓存

    // pc排钟是否只显示本标签下分配过的项目
    dispatch(setPcAppointShowTagProject(resultData['pc_appoint_show_tag_project']))
    indexedDB.putIntoConfigByIndexedDB('pc_appoint_show_tag_project', resultData['pc_appoint_show_tag_project'], cacheAction) // 放入缓存

    // 结账是否默认显示0元商品
    business.pcCheckoutShowZeroGoods = resultData['pc_checkout_show_zero_goods'];
    indexedDB.putIntoConfigByIndexedDB('pc_checkout_show_zero_goods', resultData['pc_checkout_show_zero_goods'], cacheAction) // 放入缓存

    // PC是否声音提示服务信息
    business.pcCallServiceNoticeVoice = resultData['pc_call_service_notice_voice'];
    indexedDB.putIntoConfigByIndexedDB('pc_call_service_notice_voice', resultData['pc_call_service_notice_voice'], cacheAction) // 放入缓存

    // PC账单排序
    business.pcBillSort = resultData['pc_bill_sort'];
    indexedDB.putIntoConfigByIndexedDB('pc_bill_sort', resultData['pc_bill_sort'], cacheAction) // 放入缓存

    // 手牌是否显示备注
    if (typeof resultData['hand_number_show_remark'] !== "undefined") {
      business.hand_number_show_remark = resultData['hand_number_show_remark'];
      indexedDB.putIntoConfigByIndexedDB('hand_number_show_remark', resultData['hand_number_show_remark'], cacheAction) // 放入缓存
    }

    // 手牌下是否启用开房占房
    if (typeof resultData['hand_number_room_open'] !== "undefined") {
      business.handNumberEnableRoomOpen = resultData['hand_number_room_open'];
      indexedDB.putIntoConfigByIndexedDB('hand_number_room_open', resultData['hand_number_room_open'], cacheAction) // 放入缓存
    }

    // 账单超过xx分钟提醒规则
    if (typeof resultData['bill_notice_stay'] !== "undefined") {
      business.billNoticeStay = resultData['bill_notice_stay'];
      indexedDB.putIntoConfigByIndexedDB('bill_notice_stay', resultData['bill_notice_stay'], cacheAction) // 放入缓存
    }

    // 手牌消费达xxx金额提醒
    if (typeof resultData['bill_notice_money'] !== "undefined") {
      business.billNoticeMoney = resultData['bill_notice_money'];
      indexedDB.putIntoConfigByIndexedDB('bill_notice_money', resultData['bill_notice_money'], cacheAction) // 放入缓存
    }

    // 手牌xxx分钟未消费提醒
    if (typeof resultData['bill_notice_no_consume'] !== "undefined") {
      business.billNoticeNoConsume = resultData['bill_notice_no_consume'];
      indexedDB.putIntoConfigByIndexedDB('bill_notice_no_consume', resultData['bill_notice_no_consume'], cacheAction) // 放入缓存
    }

    // 账单显示的内容
    if (typeof resultData['bill_show_content'] !== "undefined") {
      business.billShowContent = resultData['bill_show_content'];
      indexedDB.putIntoConfigByIndexedDB('bill_show_content', resultData['bill_show_content'], cacheAction) // 放入缓存
    }

    // 门柜锁类型
    if (typeof resultData['door_lock_type'] !== "undefined") {
      business.doorLockType = resultData['door_lock_type'];
      indexedDB.putIntoConfigByIndexedDB('door_lock_type', resultData['door_lock_type'], cacheAction) // 放入缓存
    }

    // 结账是否关门
    if (typeof resultData['pay_close_door_lock'] !== "undefined") {
      business.payCloseDoorLock = resultData['pay_close_door_lock'];
      indexedDB.putIntoConfigByIndexedDB('pay_close_door_lock', resultData['pay_close_door_lock'], cacheAction) // 放入缓存
    }

    // 关房是否可以单个手牌关房
    if (typeof resultData['room_close_can_one_hand_rule'] !== "undefined") {
      business.roomCloseCanOneHandRule = resultData['room_close_can_one_hand_rule'];
      indexedDB.putIntoConfigByIndexedDB('room_close_can_one_hand_rule', resultData['room_close_can_one_hand_rule'], cacheAction) // 放入缓存
    }

    // 离线门店的离线版本
    if (typeof resultData['offline_version'] !== "undefined") {
      business.offlineVersion = resultData['offline_version'];
      indexedDB.putIntoConfigByIndexedDB('offline_version', resultData['offline_version'], cacheAction) // 放入缓存
    }

    // 预约的版本
    if (typeof resultData['booking_version'] !== "undefined") {
      business.bookingVersion = resultData['booking_version'];
      indexedDB.putIntoConfigByIndexedDB('booking_version', resultData['booking_version'], cacheAction) // 放入缓存

      // 不知道有没有老版的预约了
      // if (business.bookingVersion != business.bookingVersionList.v1) {
      //   // 不是老板的，将"预约信息"移除
      //   $(".js-default-booking-status-type").remove();
      // }
    }

    // 支付版本
    if (typeof resultData['pay_version'] !== "undefined") {
      business.payVersion = resultData['pay_version'];
      indexedDB.putIntoConfigByIndexedDB('pay_version', resultData['pay_version'], cacheAction) // 放入缓存
    }

    // 空手牌是否可以拼为团体
    if (typeof resultData['no_bill_can_group'] !== "undefined") {
      business.noBillCanGroup = resultData['no_bill_can_group'];
      indexedDB.putIntoConfigByIndexedDB('no_bill_can_group', resultData['no_bill_can_group'], cacheAction) // 放入缓存
    }

    // 前台显示的房间内容
    if (typeof resultData['finance_room_content'] !== "undefined") {
      common.dispatch(setFinanceRoomContent(resultData['finance_room_content']))
      indexedDB.putIntoConfigByIndexedDB('finance_room_content', resultData['finance_room_content'], cacheAction) // 放入缓存
    }

    // 开单过程中显示的房间内容
    if (typeof resultData['begin_bill_room_content'] !== "undefined") {
      business.beginBillRoomContent = resultData['begin_bill_room_content'];
      indexedDB.putIntoConfigByIndexedDB('begin_bill_room_content', resultData['begin_bill_room_content'], cacheAction) // 放入缓存
    }

    // 消费清单显示房间内容
    if (typeof resultData['pc_checkout_room_content'] !== "undefined") {
      business.pcCheckoutRoomContent = resultData['pc_checkout_room_content'];
      indexedDB.putIntoConfigByIndexedDB('pc_checkout_room_content', resultData['pc_checkout_room_content'], cacheAction) // 放入缓存
    }

    // 房间上是否显示排钟按钮
    if (typeof resultData['pc_room_show_appoint_create'] !== "undefined") {
      business.pcRoomShowAppointCreate = resultData['pc_room_show_appoint_create'];
      indexedDB.putIntoConfigByIndexedDB('pc_room_show_appoint_create', resultData['pc_room_show_appoint_create'], cacheAction) // 放入缓存
    }

    // 门柜锁是否支持二维码开锁
    if (typeof resultData['door_qrcode'] !== "undefined") {
      business.doorQrcode = resultData['door_qrcode'];
      indexedDB.putIntoConfigByIndexedDB('door_qrcode', resultData['door_qrcode'], cacheAction) // 放入缓存
    }

    // 技师排钟超时xx分钟为其中是否闪烁
    if (typeof resultData['employee_appoint_flash'] !== "undefined") {
      business.employeeAppointFlash = resultData['employee_appoint_flash'];
      indexedDB.putIntoConfigByIndexedDB('employee_appoint_flash', resultData['employee_appoint_flash'], cacheAction) // 放入缓存
    }

    // 服务中手牌右上角显示内容
    if (typeof resultData['service_bill_show_content'] !== "undefined") {
      business.serviceBillShowContent = resultData['service_bill_show_content'];
      indexedDB.putIntoConfigByIndexedDB('service_bill_show_content', resultData['service_bill_show_content'], cacheAction) // 放入缓存
    }

    // 使用加班消费记录
    if (typeof resultData['overtime_consume_setting'] !== "undefined") {
      business.overtimeConsumeSetting = resultData['overtime_consume_setting'];
      indexedDB.putIntoConfigByIndexedDB('overtime_consume_setting', resultData['overtime_consume_setting'], cacheAction) // 放入缓存
    }

    // 系统使用类型
    if (typeof resultData['system_use_type'] !== "undefined") {
      dispatch(setSystemUseType(resultData['system_use_type']))
      indexedDB.putIntoConfigByIndexedDB('system_use_type', resultData['system_use_type'], cacheAction) // 放入缓存
    }

    // 房间显示手牌
    if (typeof resultData['room_show_hand'] !== "undefined") {
      dispatch(setRoomShowHandSetting(resultData['room_show_hand']))
      indexedDB.putIntoConfigByIndexedDB('room_show_hand', resultData['room_show_hand'], cacheAction) // 放入缓存
    }

    // 房间展示是否分页[新版收银]
    if (typeof resultData['room_show_page_v4_finance'] !== "undefined") {
      dispatch(setRoomShowPageV4Finance(resultData['room_show_page_v4_finance']))
      indexedDB.putIntoConfigByIndexedDB('room_show_page_v4_finance', resultData['room_show_page_v4_finance'], cacheAction) // 放入缓存
    }

    // 服务中的技师是否显示钟类型
    if (typeof resultData['busy_employee_show_clock'] !== "undefined") {
      dispatch(setBusyEmployeeShowClock(resultData['busy_employee_show_clock']))
      indexedDB.putIntoConfigByIndexedDB('busy_employee_show_clock', resultData['busy_employee_show_clock'], cacheAction) // 放入缓存
    }

    // 会员拆单是否自动显示上一次的手机号和验证码
    if (typeof resultData['member_split_order'] !== "undefined") {
      dispatch(setMemberSplitOrder(resultData['member_split_order']))
      indexedDB.putIntoConfigByIndexedDB('member_split_order', resultData['member_split_order'], cacheAction) // 放入缓存
    }

    if(typeof resultData['appoint_can_wait'] !== "undefined") {
      dispatch(setAppointWait(resultData['appoint_can_wait']))
      indexedDB.putIntoConfigByIndexedDB('appoint_can_wait', resultData['appoint_can_wait'], cacheAction) // 放入缓存
    }

    if(typeof resultData['room_show_money'] !== "undefined") {
      dispatch(setRoomShowMoney(resultData['room_show_money']))
      indexedDB.putIntoConfigByIndexedDB('room_show_money', resultData['room_show_money'], cacheAction) // 放入缓存
    }

    business.hasDoorLock = resultData['has_clock'];
    indexedDB.putIntoConfigByIndexedDB('has_clock', resultData['has_clock'], cacheAction) // 放入缓存

    // 是否到期
    biz.getExpire().finally(() => {

      if (business.cloudServiceExpire) {
        biz.expire();
      }

      // 收银通知
      biz.callServeNoHandleInfoForInit();

      resolve();
    })
  })
}


// 初始化权限-数据-处理数据 --- 为"initLoad"服务
biz.handleForTaskForInitLoad = (resultData, cacheAction) => {
  return new Promise((resolve, reject) => {
    //收银是否可以取消预约
    business.front_cancel_booking = resultData['front_cancel_booking'] ? resultData['front_cancel_booking'] : business.front_cancel_booking_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_cancel_booking', resultData['front_cancel_booking'], cacheAction) // 放入缓存

    //收银是否可以修改营销人员
    business.front_update_sell_employee = resultData['front_update_sell_employee'] ? resultData['front_update_sell_employee'] : business.front_update_sell_employee_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_update_sell_employee', resultData['front_update_sell_employee'], cacheAction) // 放入缓存

    // 会员是否启用密码
    business.can_member_use_pwd = resultData['can_member_use_pwd'] ? resultData['can_member_use_pwd'] : false;
    indexedDB.putIntoConfigByIndexedDB('can_member_use_pwd', resultData['can_member_use_pwd'], cacheAction) // 放入缓存

    // 技师房通讯码
    business.tvCode = resultData['tv_code'] ? resultData['tv_code'] : '';
    indexedDB.putIntoConfigByIndexedDB('tv_code', resultData['tv_code'], cacheAction) // 放入缓存

    // 房间显示比例
    common.dispatch(setFrontRoomShowPercentage(resultData['front_room_show_percentage'] ? resultData['front_room_show_percentage'] : 100))
    indexedDB.putIntoConfigByIndexedDB('front_room_show_percentage', resultData['front_room_show_percentage'], cacheAction) // 放入缓存

    // 底部技师显示比例
    common.dispatch(setEmployeeShowPercentage(resultData['front_bottom_employee_show_percentage'] ? resultData['front_bottom_employee_show_percentage'] : 100))
    indexedDB.putIntoConfigByIndexedDB('front_bottom_employee_show_percentage', resultData['front_bottom_employee_show_percentage'], cacheAction) // 放入缓存

    //实时营运报告房间
    business.front_report_room = resultData['front_report_room'] ? resultData['front_report_room'] : business.front_report_room_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_room', resultData['front_report_room'], cacheAction) // 放入缓存

    //实时营运报告技师
    business.front_report_employee = resultData['front_report_employee'] ? resultData['front_report_employee'] : business.front_report_employee_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_employee', resultData['front_report_employee'], cacheAction) // 放入缓存

    // 实时营运报告待结金额
    business.front_report_wait_money = resultData['front_report_wait_money'] ? resultData['front_report_wait_money'] : business.front_report_wait_money_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_wait_money', resultData['front_report_wait_money'], cacheAction) // 放入缓存

    // 实时营运报告待结客流
    business.front_report_wait_person = resultData['front_report_wait_person'] ? resultData['front_report_wait_person'] : business.front_report_wait_person_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_wait_person', resultData['front_report_wait_person'], cacheAction) // 放入缓存

    // 实时营运报告已结客流
    business.front_report_finish_person = resultData['front_report_finish_person'] ? resultData['front_report_finish_person'] : business.front_report_finish_person_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_finish_person', resultData['front_report_finish_person'], cacheAction) // 放入缓存

    // 实时营运报告总客流
    business.front_report_total_person = resultData['front_report_total_person'] ? resultData['front_report_total_person'] : business.front_report_total_person_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_total_person', resultData['front_report_total_person'], cacheAction) // 放入缓存

    // 实时营运报告预约人数
    business.front_report_booking = resultData['front_report_booking'] ? resultData['front_report_booking'] : business.front_report_booking_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_booking', resultData['front_report_booking'], cacheAction) // 放入缓存

    // 实时营运报告已结金额服务收入
    business.front_report_finish_order_money = resultData['front_report_finish_order_money'] ? resultData['front_report_finish_order_money'] : business.front_report_finish_order_money_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_finish_order_money', resultData['front_report_finish_order_money'], cacheAction) // 放入缓存

    // 实时营运报告已结金额售卡收入
    business.front_report_finish_card_money = resultData['front_report_finish_card_money'] ? resultData['front_report_finish_card_money'] : business.front_report_finish_card_money_list.no;
    indexedDB.putIntoConfigByIndexedDB('front_report_finish_card_money', resultData['front_report_finish_card_money'], cacheAction) // 放入缓存

    // 员工的隐藏模块
    if ('hidden_list' in resultData) {
      business.hiddenList = resultData['hidden_list']
      indexedDB.putIntoConfigByIndexedDB('hidden_list', JSON.stringify(resultData['hidden_list']), cacheAction) // 放入缓存

      // // 处理隐藏模块 不处理，模块都变了
      // app.functions.handleHiddenForEmployee();
    }

    resolve();
  })
}


// 项目 --- 为"initLoad"服务
biz.projectForInitLoad = () => {
  return new Promise((resolve, reject) => {

    biz.getDataForProjectForInitLoad().then((resultData) => {
      biz.handleForProjectForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })

  });
}

// 项目-获取数据 --- 为"initLoad"服务
biz.getDataForProjectForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/project')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.project).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 项目-处理数据 --- 为"initLoad"服务
biz.handleForProjectForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.project, resultData, cacheAction) // 放入缓存

  //所有项目
  var projectList = biz.handleData(resultData, 9, false);

  common.dispatch(setProjectList((projectList)))

  // 将项目数据处理为obj
  biz.handProjectToObjForInit(projectList)
}

// 将项目数据处理为ID为key，项目数据为value的对象
biz.handProjectToObjForInit = (list) => {
  var objById = {}
  var objByNumber = {}

  for (let i = 0; i < list.length; i++) {
    let one = list[i]

    objById[one['id']] = one

    if (one['status'] != business.projectStatus.status_delete) {
      //将前面的“0”去掉
      let number = one['number']
      if (typeof number == 'string') {
        number = number.replace(/\b(0+)/gi, "");
      }
      objByNumber[number] = one
    }
  }

  common.dispatch(setProjectObjById(objById))
  common.dispatch(setProjectObjByNumber(objByNumber))
}

// 标签项目对应数据 --- 为"initLoad"服务
biz.tagProjectForInitLoad = () => {
  return new Promise((resolve, reject) => {

    biz.getDataForTagProjectForInitLoad().then((resultData) => {
      biz.handleForTagProjectForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })

  });
}

// 项目-获取数据 --- 为"initLoad"服务
biz.getDataForTagProjectForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/project/tag-project-list')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getOneFromConfigByNameByIndexedDB('tag_project').then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 项目-处理数据 --- 为"initLoad"服务
biz.handleForTagProjectForInitLoad = (resultData, cacheAction) => {
  indexedDB.putIntoConfigByIndexedDB('tag_project', resultData, cacheAction) // 放入缓存

  //所有 标签项目数据
  common.dispatch(setTagProjectList(resultData))
}


// 手动执行的优惠方案 --- 为"initLoad"服务
biz.manualPricePlanForInitLoad = () => {
  return new Promise((resolve, reject) => {

    // 20230701: 边缘目前没有手动执行的优惠方案的处理，故不用处理，直接返回
    if (common.isOfflineMode()) {
      resolve()
      return;
    }

    biz.getDataForManualPricePlanForInitLoad().then((resultData) => {
      biz.handleForManualPricePlanForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })

  });
}

// 手动执行的优惠方案-获取数据 --- 为"initLoad"服务
biz.getDataForManualPricePlanForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('FINANCE/price-plan/manual')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.manual_price_plan).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 手动执行的优惠方案-处理数据 --- 为"initLoad"服务
biz.handleForManualPricePlanForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.manual_price_plan, resultData, cacheAction) // 放入缓存

  //所有 标签项目数据
  common.dispatch(setManualPricePlanList(resultData))
}


// 获取还未结账的账单 --- 为"initLoad"服务
biz.billServeForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForBillServeForInitLoad().then((resultData) => {
      biz.handleForBillServeForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 获取还未结账的账单 --- 为"initLoad"服务
biz.getDataForBillServeForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('FINANCE/bill/serve')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.bill).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}


// 获取还未结账的账单-处理数据 --- 为"initLoad"服务
biz.handleForBillServeForInitLoad = (resultData, cacheAction) => {
  //获取还未结账的账单
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.bill, resultData, cacheAction) // 放入缓存
  biz.handleBillList(resultData); // 处理一下账单数据
}

// 获取开房使用中的数据 --- 为"initLoad"服务
biz.ingRoomOpenForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据

    // 启用了开房功能的，才能去获取，否则不动
    if ((parseInt(business.begin_bill_type)) !== (parseInt(business.beginBillTypeList.handNumber))) {
      resolve();
      return;
    }

    if ((parseInt(business.handNumberEnableRoomOpen)) !== (parseInt(business.handNumberEnableRoomOpenList.yes))) {
      resolve();
      return;
    }

    biz.getIngRoomOpenForInitLoad().then((resultData) => {
      biz.handleForIngRoomOpenForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 获取开房使用中的数据 --- 为"initLoad"服务
biz.getIngRoomOpenForInitLoad = () => {
  return new Promise((resolve, reject) => {
    let tempUrl = common.apiUrl("/app.php/service-pad-api/room-open")
    if(common.isOfflineMode()) {
      tempUrl = common.apiUrl('FINANCE/room-open');
    }
    common.axios('get', tempUrl).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.room_open).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 获取开房使用中的数据-处理数据 --- 为"initLoad"服务
biz.handleForIngRoomOpenForInitLoad = (resultData, cacheAction) => {
  //获取还未结账的账单
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.room_open, resultData, cacheAction) // 放入缓存
  biz.handle_for_room_open(); // 处理一下房间开房数据
}

// 对开房间留处理一下
biz.handle_for_room_open = () => {
  // 房间的开房使用中的记录 ： 房间ID => [使用记录, 使用记录, ...]

  // 按照开房时间排序 从小到大
  if (business.roomOpenList && business.roomOpenList.length > 0) {
    let tempRoomOpenList = [...business.roomOpenList]
    tempRoomOpenList.sort((a, b) => {
      return a.open_time - b.open_time
    })

    common.dispatch(setRoomOpenList(tempRoomOpenList))
  }

  var roomOpenListGroupByRoomId = {}
  for (let kk = 0; kk < business.roomOpenList.length; kk++) {
    let tempRoomOpenInfo = business.roomOpenList[kk]

    if (typeof roomOpenListGroupByRoomId[tempRoomOpenInfo['room_id']] === "undefined") {
      roomOpenListGroupByRoomId[tempRoomOpenInfo['room_id']] = []
    }

    roomOpenListGroupByRoomId[tempRoomOpenInfo['room_id']].push(tempRoomOpenInfo)
  }

  common.dispatch(setRoomOpenListGroupByRoomId(roomOpenListGroupByRoomId))
  business.roomOpenListGroupByRoomId = roomOpenListGroupByRoomId
}

// 获取门店的预约中的数据 --- 为"initLoad"服务
biz.employeeBookingForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据

    // 边缘的不要
    if (common.isOfflineMode()) {
      resolve()
      return;
    }

    // 非V2不要
    if (business.bookingVersion != business.bookingVersionList.v2) {
      resolve();
      return;
    }

    biz.getEmployeeBookingForInitLoad().then((resultData) => {
      biz.handleForEmployeeBookingForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 获取 技师预约 的数据 --- 为"initLoad"服务
biz.getEmployeeBookingForInitLoad = () => {
  return new Promise((resolve, reject) => {
    let url = common.apiUrl('/app.php/service-pad-api/employee-booking/front-info')
    if(common.isOfflineMode()) {
      url = common.apiUrl('SCHEDULE/employee-booking/info')
    }

    common.axios('get', url).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.employee_booking).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 获取 技师预约 的数据-处理数据 --- 为"initLoad"服务
biz.handleForEmployeeBookingForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.employee_booking, resultData, cacheAction) // 放入缓存
  common.dispatch(setEmployeeBookingList(resultData))
}

// 初始化的预约客户信息(在店、未支付、待消费)(是为了支持老的预约信息展示) --- 为"initLoad"服务
biz.bookingStoreWaitServiceForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForBookingStoreWaitServiceForInitLoad().then((resultData) => {
      biz.handleForBookingStoreWaitServiceForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 初始化的预约客户信息(在店、未支付、待消费)(是为了支持老的预约信息展示)-获取数据 --- 为"initLoad"服务
biz.getDataForBookingStoreWaitServiceForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('post', common.apiUrl('booking/store-wait-service'), {}, 5000, 'no').then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        if (typeof errorObj.code !== "undefined") {
          if (errorObj.code = 'DEGRADE') {
            // 被降级了，不提示报错
            resolve({'data': [], 'cacheAction': 0})
            return;
          }
        }

        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          resolve({'data': [], 'cacheAction': 0})
          return;
        }

        reject(errorObj.error);
        return;
      }

      // 20210615: 离线模式下，屏蔽错误
      if (common.isOfflineMode()) {
        resolve({'data': [], 'cacheAction': 0})
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.store_wait_serve_booking).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 初始化的预约客户信息(在店、未支付、待消费)(是为了支持老的预约信息展示)-处理数据 --- 为"initLoad"服务
biz.handleForBookingStoreWaitServiceForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.store_wait_serve_booking, resultData, cacheAction) // 放入缓存

  common.dispatch(setStoreWaitServeBookingList(resultData))
}

// 所有房间基础数据 --- 为"initLoad"服务
biz.roomForInitLoad = () => {
  return new Promise((resolve, reject) => {

    // 获取数据
    biz.getDataForRoomForInitLoad().then((resultData) => {
      biz.handleForRoomForInitLoad(resultData['data'], resultData['cacheAction'])

      // 房间动态数据
      biz.roomTrendsForInitLoad() // 房间动态数据

      resolve()
    }).catch((error) => {
      reject(error)
    })

  });
}

// 所有房间基础数据 --- 为"initLoad"服务
biz.getDataForRoomForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/room/basic')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.room).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0});
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 所有房间基础数据 --- 为"initLoad"服务
biz.handleForRoomForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.room, resultData, cacheAction) // 放入缓存

  var arr = biz.handleData(resultData);

  //根据ID倒序
  arr.sort((a, b) => {
    return b.id - a.id;
  });

  // 房间的基础数据-不带状态
  business.roomBasicList = arr;

  var objById = {}
  for (let i = 0; i < arr.length; i++) {
    objById[arr[i]['id']] = arr[i]
  }
  business.roomBasicObjById = objById
}

// 所有房间-动态数据 --- 为"initLoad"服务
biz.roomTrendsForInitLoad = () => {
  return new Promise((resolve, reject) => {

    // 获取数据
    biz.getDataForRoomTrendsForInitLoad().then((resultData) => {
      biz.handleForRoomTrendsForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })

  });
}

// 所有房间-状态 --- 为"initLoad"服务
biz.getDataForRoomTrendsForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('FINANCE/room/trends')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.room_trends).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0});
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 所有房间-状态 --- 为"initLoad"服务
biz.handleForRoomTrendsForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.room_trends, resultData, cacheAction) // 放入缓存

  var roomList = biz.updateRoomTrendsToBasic(resultData); // 将动态状态数据 更新赋值到 房间基础数据上

  roomList = business.handleRoomListForInit(business.room_type_list, roomList)

  // 处理房间数据
  biz.handleRoomData(roomList)
}

biz.handleRoomData = (roomList) => {
  common.dispatch(setRoomList(roomList))

  biz.handleRoomsToObjForInit(roomList); // 将房间处理为id为key，数据为value的对象
}

// 为房间数据将动态的状态相关数据 更新赋值上去
biz.updateRoomTrendsToBasic = (statusList) => {
  // 转成已房间ID为key的对象，方便下方循环给到房间上
  var statusObj = {}
  for (let i = 0; i < statusList.length; i++) {
    statusObj[statusList[i]['id']] = statusList[i]
  }

  // 将房间状态对应数据，替换到房间是哪个，然后再给到对应的数据上
  var roomList = [...business.roomBasicList]

  for (let i = 0; i < roomList.length; i++) {
    let roomId = roomList[i]['id'];

    if (typeof statusObj[roomId] === "undefined") {
      continue;
    }

    roomList[i] = {...roomList[i], ...statusObj[roomId]};
  }

  return roomList;
}

// 小程序叫钟的数据 --- 为"initLoad"服务
biz.appointOrderListFromFrontForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForAppointOrderListFromFrontForInitLoad().then((resultData) => {
      biz.handleForAppointOrderListFromFrontForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 小程序叫钟的数据 --- 为"initLoad"服务
biz.getDataForAppointOrderListFromFrontForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('SCHEDULE/appoint-order/list-from-front')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.appoint_order).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0});
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 小程序叫钟的数据 app.appointOrder-处理数据 --- 为"initLoad"服务 
biz.handleForAppointOrderListFromFrontForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.appoint_order, resultData, cacheAction) // 放入缓存

  biz.handleForAppointOrderInit(resultData); // 处理排钟数据
}

// 常用语 --- 为"initLoad"服务
biz.phraseListForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForPhraseListForInitLoad().then((resultData) => {
      biz.handleForPhraseListForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 常用语 --- 为"initLoad"服务
biz.getDataForPhraseListForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('/app.php/service-pad-api/phrase')).then((result) => {
      resolve({'data': result['data'], 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.phrase).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0});
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 常用语-处理数据 --- 为"initLoad"服务
biz.handleForPhraseListForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.phrase, resultData, cacheAction) // 放入缓存

  biz.handleForPhraseInit(resultData); // 处理排钟数据
}

// 处理常用语
biz.handleForPhraseInit = (resultData) => {
  let dispatch = common.dispatch

  dispatch(setPhraseList(resultData))
}

// 服务未到钟的数据 --- 为"initLoad"服务
biz.consumeNoEndForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForConsumeNoEndForInitLoad().then((resultData) => {
      biz.handleForConsumeNoEndForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 服务未到钟的数据-获取数据 --- 为"initLoad"服务
biz.getDataForConsumeNoEndForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('FINANCE/consume/no-end')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.consumes_no_service_end).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 服务未到钟的数据-处理数据 --- 为"initLoad"服务
biz.handleForConsumeNoEndForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.consumes_no_service_end, resultData, cacheAction) // 放入缓存
  common.dispatch(setNoEndConsumeList(resultData))

  biz.handleNoEndConsumeInfoInit(business.consumesNoServiceEnd); // 未落钟的消费数据处理
}

// 查询默认当天的日期的技师预约占用时间 --- 为"initLoad"服务
biz.reserveForInitLoad = () => {
  return new Promise((resolve, reject) => {

    // 获取数据
    biz.getDataForReserveForInitLoad().then((resultData) => {
      biz.handleForReserveForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 查询默认当天的日期的技师预约占用时间-获取数据 --- 为"initLoad"服务
biz.getDataForReserveForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('post', common.apiUrl('reserve'), {}, 5000, 'no').then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        if (typeof errorObj.code !== "undefined") {
          if (errorObj.code = 'DEGRADE') {
            // 被降级了，不提示报错
            resolve({'data': [], 'cacheAction': 0})
            return;
          }
        }

        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          resolve({'data': [], 'cacheAction': 0})
          return;
        }

        reject(errorObj.error);
        return;
      }

      // 20210615: 离线模式下，屏蔽错误
      if (common.isOfflineMode()) {
        resolve({'data': [], 'cacheAction': 0})
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.reserve).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 查询默认当天的日期的技师预约占用时间-处理数据 --- 为"initLoad"服务
biz.handleForReserveForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.reserve, resultData, cacheAction) // 放入缓存

  business.reserve_list = resultData;
}

// 服务类型 --- 为"initLoad"服务
biz.settingForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForSettingForInitLoad().then((resultData) => {
      biz.handleForSettingForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}

// 服务类型-获取数据 --- 为"initLoad"服务
biz.getDataForSettingForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/setting')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.service_type).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 服务类型-处理数据 --- 为"initLoad"服务
biz.handleForSettingForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.service_type, resultData, cacheAction) // 放入缓存

  business.service_type = [];
  business.serviceTypeList = [];

  for (var i = 0; i < resultData.length; i++) {
    business.service_type.push({
      'value': resultData[i]['key'],
      'name': resultData[i]['value'],
    });

    business.serviceTypeList.push({
      'value': resultData[i]['key'],
      'name': resultData[i]['value'],
    });
  }
}


// 服务类型 --- 为"initLoad"服务
biz.settingV2ForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForSettingV2ForInitLoad().then((resultData) => {
      biz.handleForSettingV2ForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}

// 服务类型-获取数据 --- 为"initLoad"服务
biz.getDataForSettingV2ForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/setting/v2')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (errorObj.status == 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.setting).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 服务类型-处理数据 --- 为"initLoad"服务
biz.handleForSettingV2ForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.setting, resultData, cacheAction) // 放入缓存

  // 服务类型
  business.serviceTypeList = [];

  for (let i = 0; i < resultData.length; i++) {
    if (resultData[i]['type'] != business.settingTypeList.serviceType) {
      continue;
    }

    business.serviceTypeList.push({
      'value': resultData[i]['key'],
      'name': resultData[i]['value'],
    });
  }

  // 钟类型
  let arr = [];
  for (var i = 0; i < resultData.length; i++) {
    if (resultData[i]['type'] != business.settingTypeList.clockType) {
      continue;
    }

    arr.push({
      'value': resultData[i]['key'],
      'name': resultData[i]['value'],
    });
  }
  business.clock_type_list = arr
  for (let i = 0; i < arr.length; i++) {
    business.clock_type_obj[parseInt(arr[i]['value'])] = arr[i]['name']
  }
}


// 收银提醒规则 --- 为"initLoad"服务
biz.remindForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForRemindForInitLoad().then((resultData) => {
      biz.handleForRemindForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 收银提醒规则 --- 为"initLoad"服务
biz.getDataForRemindForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/remind')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        if (typeof errorObj.code !== "undefined") {
          if (errorObj.code = 'DEGRADE') {
            // 被降级了，不提示报错
            resolve({'data': [], 'cacheAction': 0})
            return;
          }
        }
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.front_remind).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 收银提醒规则 --- 为"initLoad"服务
biz.handleForRemindForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.front_remind, resultData, cacheAction) // 放入缓存
  business.frontRemindList = resultData
}


// 收银待提醒数据 --- 为"initLoad"服务
biz.waitRemindForInitLoad = () => {
  return new Promise((resolve, reject) => {
    biz.getWaitRemind().then(() => {
      resolve();
    }).catch((data) => {
      reject(data)
    })
  });
}

// 技师排钟方式 --- 为"initLoad"服务
biz.dictionaryEmployeeClockForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForDictionaryEmployeeClockForInitLoad().then((resultData) => {
      biz.handleForDictionaryEmployeeClockForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 技师排钟方式 --- 为"initLoad"服务
biz.getDataForDictionaryEmployeeClockForInitLoad = () => {
  return new Promise((resolve, reject) => {
    //技师排钟方式
    common.axios('get', common.apiUrl('BASIC/dictionary/employee-clock')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getOneFromConfigByNameByIndexedDB('employee_clock_type').then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]")
      })
    })
  });
}

// 技师排钟方式 --- 为"initLoad"服务
biz.handleForDictionaryEmployeeClockForInitLoad = (resultData, cacheAction) => {
  common.dispatch(setEmployeeClockType(resultData))
  indexedDB.putIntoConfigByIndexedDB('employee_clock_type', resultData, cacheAction) // 放入缓存
}

// 处理 服务中的账单
biz.handleBillList = (bills) => {
  // 对账单里的消费数据做排序
  let dispatch = common.dispatch

  var projectObjById = business.projectObjById

  let tempBillList = bills ? [...bills] : []

  for (let i = 0; i < tempBillList.length; i++) {
    let tempConsumeList = tempBillList[i]['consume'] ? [...tempBillList[i]['consume']] : []

    if (!tempConsumeList) {
      continue;
    }

    // 排序：商品在最后，同时按照起钟时间排序
    //有预约的显示在前面  当同时都是预约的时候，工号从小到大显示
    tempConsumeList.sort((a, b) => {
      let projectA = biz.indexOfReturnInObj(a['project_id'], projectObjById)
      let projectB = biz.indexOfReturnInObj(b['project_id'], projectObjById)

      if (projectA['is_normal_goods'] != projectB['is_normal_goods']) {
        return projectA['is_normal_goods'] - projectB['is_normal_goods']
      }

      return a['service_begin'] - b['service_begin']
    });

    tempBillList[i] = {
      ...tempBillList[i],
      consume: tempConsumeList,
    }
  }

  var billObjByHand = {} // 手牌Id为key的账单对象
  var billObjById = {} // 账单Id为key的账单对象
  var billListGroupBatch = {} // 账单根据批次ID分组

  for (let i = 0; i < tempBillList.length; i++) {
    let tempBill = tempBillList[i]

    billObjByHand[tempBill['hand_id']] = tempBill // 手牌ID为key
    billObjById[tempBill['id']] = tempBill // 账单ID为key

    // 批次分组
    let tempBatchId = tempBill['batch_id']
    if (tempBatchId in billListGroupBatch) {
      billListGroupBatch[tempBatchId].push(tempBill)
    } else {
      billListGroupBatch[tempBatchId] = [tempBill]
    }
  }

  dispatch(setServeBillList(tempBillList))
  dispatch(setBillObjByHandId(billObjByHand))
  dispatch(setBillObjById(billObjById))
  dispatch(setBillListGroupBatchId(billListGroupBatch))

  // 处理账单下的消费数据
  biz.handleBillAllConsumeList(tempBillList, billObjById);
}

// 将账单下的所有消费处理组装好
biz.handleBillAllConsumeList = (bills, billObjById) => {
  let dispatch = common.dispatch

  var consumeList = []
  var consumeObjById = {} // 消费用消费ID作为key

  for (let i = 0; i < bills.length; i++) {
    for (let j = 0; j < bills[i]['consume'].length; j++) {
      consumeList.push(bills[i]['consume'][j])
      consumeObjById[bills[i]['consume'][j]['id']] = bills[i]['consume'][j]
    }
  }

  dispatch(setBillAllConsumeList(consumeList))
  dispatch(setBillAllConsumeObjById(consumeObjById))

  // 消费数据，根据技师分组
  var consumeListGroupEmployeeId = {}

  // 将消费数据按照房间分组
  var consumeListGroupRoom = {}

  // 消费下的所有的房间ID
  var allConsumeRoomIds = {};

  // 按照房间分组的账单
  var billListGroupRoom = {}

  // 按照房间分组的批次
  var batchIdsObjGroupRoom = {}

  // 按照批次分组的房间
  var roomIdsObjByBatch = {}

  for (let i = 0; i < consumeList.length; i++) {
    var tempConsume = consumeList[i];

    let tempBill = (typeof billObjById[tempConsume['bill_id']] === "undefined") ? null : billObjById[tempConsume['bill_id']];

    // 用对象，是因为要将技师去重
    let employeeIdObj = {}

    for (let j = 0; j < tempConsume['work'].length; j++) {
      let tempEmployeeId = tempConsume['work'][j]['employee_id']

      if (!tempEmployeeId) {
        continue;
      }

      employeeIdObj[tempEmployeeId] = tempEmployeeId
    }

    for (let tempEmployeeId in employeeIdObj) {
      if (tempEmployeeId in consumeListGroupEmployeeId) {
        consumeListGroupEmployeeId[tempEmployeeId].push(tempConsume)
      } else {
        consumeListGroupEmployeeId[tempEmployeeId] = [tempConsume]
      }
    }

    // 按照房间分组
    let tempRoomId = tempConsume['room_id']
    if (tempRoomId) {
      // 所有的消费的房间
      allConsumeRoomIds[tempConsume['room_id']] = tempConsume['room_id']

      // 按照房间分组的消费
      if (typeof consumeListGroupRoom[tempRoomId] === "undefined") {
        consumeListGroupRoom[tempRoomId] = []
      }

      consumeListGroupRoom[tempRoomId].push(tempConsume)

      if (tempBill) {
        // 按照房间分组的账单
        if (typeof billListGroupRoom[tempRoomId] === "undefined") {
          billListGroupRoom[tempRoomId] = []
        }

        billListGroupRoom[tempRoomId].push(tempBill)

        // 按照房间分组的批次
        if (typeof batchIdsObjGroupRoom[tempRoomId] === "undefined") {
          batchIdsObjGroupRoom[tempRoomId] = []
        }

        batchIdsObjGroupRoom[tempRoomId][tempBill['batch_id']] = tempBill['batch_id'];

        // 按照批次分组的房间
        if (typeof roomIdsObjByBatch[tempBill['batch_id']] === "undefined") {
          roomIdsObjByBatch[tempBill['batch_id']] = []
        }

        roomIdsObjByBatch[tempBill['batch_id']][tempRoomId] = tempRoomId;
      }
    }
  }

  dispatch(setBillAllConsumeListGroupEmployeeId(consumeListGroupEmployeeId)) // 按照技师分组的小覅额
  dispatch(setAllConsumeListGroupRoomId(consumeListGroupRoom)) // 按照房间分组的消费
  dispatch(setBillListGroupRoomId(billListGroupRoom)) // 按照房间分组的账单

  let batchIdsGroupRoom = [];
  let roomIdsByBatch = [];

  for (let k in batchIdsObjGroupRoom) {
    batchIdsGroupRoom[k] = []

    for (let n in batchIdsObjGroupRoom[k]) {
      batchIdsGroupRoom[k].push(n)
    }
  }

  for (let k in roomIdsObjByBatch) {
    roomIdsByBatch[k] = []

    for (let n in roomIdsObjByBatch[k]) {
      roomIdsByBatch[k].push(n)
    }
  }

  dispatch(setBatchIdsGroupRoom(batchIdsGroupRoom)) // 按照房间分组的批次
  dispatch(setRoomIdsGroupBatch(roomIdsByBatch)) // 按照批次分组的房间

  let allConsumeRoomObjByRoomId = {}
  let tempRoomIds = [];
  for (let i in allConsumeRoomIds) {
    tempRoomIds.push(allConsumeRoomIds[i])
    allConsumeRoomObjByRoomId[allConsumeRoomIds[i]] = {'room_id': allConsumeRoomIds[i]}
  }

  allConsumeRoomIds = tempRoomIds

  dispatch(setAllConsumeRoomIds(allConsumeRoomIds))
  dispatch(setAllConsumeRoomObjByRoomId(allConsumeRoomObjByRoomId))
}

// 处理未落钟的消费数据
biz.handleNoEndConsumeInfoInit = (noEndConsumeList) => {
  let dispatch = common.dispatch

  // 根据技师ID分组
  var consumeListGroupEmployeeId = {}

  for (let i = 0; i < noEndConsumeList.length; i++) {
    var tempConsume = noEndConsumeList[i];

    // 已落钟的跳过
    if (parseInt(tempConsume['service_end']) != 0) {
      continue;
    }

    // 没有服务时间
    if (!tempConsume['service_time']) {
      continue;
    }

    let employeeIdObj = {} // 用对象，是因为要将技师去重

    for (let j = 0; j < tempConsume['work'].length; j++) {
      let tempEmployeeId = tempConsume['work'][j]['employee_id']

      if (!tempEmployeeId) {
        continue;
      }

      employeeIdObj[tempEmployeeId] = tempEmployeeId
    }

    for (let tempEmployeeId in employeeIdObj) {
      if (tempEmployeeId in consumeListGroupEmployeeId) {
        consumeListGroupEmployeeId[tempEmployeeId].push(tempConsume)
      } else {
        consumeListGroupEmployeeId[tempEmployeeId] = [tempConsume]
      }
    }
  }

  dispatch(setNoEndConsumeListGroupEmployeeId(consumeListGroupEmployeeId))
}

// 对开房间留处理一下
biz.handleForRoomOpen = (roomOpenList) => {
  // 房间的开房使用中的记录 ： 房间ID => [使用记录, 使用记录, ...]

  let dispatch = common.dispatch

  // 按照开房时间排序 从小到大
  roomOpenList.sort((a, b) => {
    return a.open_time - b.open_time
  })

  dispatch(setRoomOpenList(roomOpenList))

  var roomOpenListGroupByRoomId = {}
  for (let kk = 0; kk < roomOpenList.length; kk++) {
    let tempRoomOpenInfo = roomOpenList[kk]

    if (typeof roomOpenListGroupByRoomId[tempRoomOpenInfo['room_id']] === "undefined") {
      roomOpenListGroupByRoomId[tempRoomOpenInfo['room_id']] = []
    }

    roomOpenListGroupByRoomId[tempRoomOpenInfo['room_id']].push(tempRoomOpenInfo)
  }

  dispatch(setRoomOpenListGroupByRoomId(roomOpenListGroupByRoomId))
}

// 为房间数据将动态的状态相关数据 更新赋值上去
biz.updateRoomTrendsToBasic = (statusList, roomBasicList) => {
  // 转成已房间ID为key的对象，方便下方循环给到房间上
  var statusObj = {}
  for (let i = 0; i < statusList.length; i++) {
    statusObj[statusList[i]['id']] = statusList[i]
  }

  // 将房间状态对应数据，替换到房间是哪个，然后再给到对应的数据上
  var roomList = [...business.roomBasicList]

  for (let i = 0; i < roomList.length; i++) {
    let roomId = roomList[i]['id'];

    if (typeof statusObj[roomId] === "undefined") {
      continue;
    }

    roomList[i] = {...roomList[i], ...statusObj[roomId]};
  }

  return roomList;
}

// 将房间数据处理为ID为key，房间数据为value的对象
biz.handleRoomsToObjForInit = (list) => {
  var obj = {}
  var objByNumber = {}

  for (let i = 0; i < list.length; i++) {
    let one = list[i]

    obj[one['id']] = one

    if (one['status'] != business.roomStatus.status_delete) {
      //将前面的“0”去掉
      let number = one['number']
      if (typeof number == 'string') {
        number = number.replace(/\b(0+)/gi, "");
      }
      objByNumber[number] = one
    }
  }

  common.dispatch(setRoomObjById(obj))
  common.dispatch(setRoomObjByNumber(objByNumber))
}

// 处理手牌卡数据
biz.handleHandNumberList = (list) => {
  let arr = [];
  for (let i = 0; i < list.length; i++) {
    let temp = {...list[i]}
    temp['code'] = temp['code'].toString().toLowerCase()
    arr.push(temp)
  }

  // 处理一个手牌ID为key，手牌数据为value的对象
  biz.handHandNumbersToObjForInit(arr)

  return arr;
}

// 将手牌数据处理为为ID为key，手牌数据为value的对象
biz.handHandNumbersToObjForInit = (list) => {
  var objById = {} // id作为key
  var objByNumber = {} // number作为key
  var objByCode = {} // 用code作为key

  for (let i = 0; i < list.length; i++) {
    let one = list[i];

    objById[one['id']] = one

    if (one['code']) {
      objByCode[one['code']] = one
    }

    if (one['status'] != business.handNumberStatus.status_delete) {
      //将前面的“0”去掉
      let number = one['number']
      if (typeof number == 'string') {
        number = number.replace(/\b(0+)/gi, "");
      }
      objByNumber[number] = one
    }
  }

  common.dispatch(setHandNumberObjById(objById))
  common.dispatch(setHandNumberObjByNumber(objByNumber))
  common.dispatch(setHandNumberObjByCode(objByCode))
}

//处理数据
biz.handleData = (arr, len, supply) => {
  arr = biz.leftZeroPadArr(arr);

  supply = supply == undefined ? true : supply;

  if (len) {
    //处理名称
    for (var i = 0; i < arr.length; i++) {
      if (typeof arr[i]['name'] !== "undefined") {
        if (arr[i]['name']) {
          arr[i]['name_small'] = biz.cutStr(arr[i]['name'], len, supply);
        }
      }
    }
  }

  return arr;
}

// 处理实体卡数据
biz.handleCodeList = (list) => {
  for (var i = 0; i < list.length; i++) {
    if (list[i]['code'] == null) {
      list[i]['code'] = '';
    }

    if (list[i]['door_clock'] == null) {
      list[i]['door_clock'] = '';
    }

    list[i]['code'] = list[i]['code'].toString().toLowerCase()
    list[i]['door_clock'] = list[i]['door_clock'].toString().toLowerCase()
  }

  var objByCode = {} // 处理一下用code为key
  var objByDoorClock = {} // 处理一下用door_clock为key

  for (let i = 0; i < list.length; i++) {
    let tempOne = list[i]

    if (tempOne['code']) {
      objByCode[tempOne['code']] = tempOne
    }

    if (tempOne['door_clock']) {
      objByDoorClock[tempOne['door_clock']] = tempOne
    }
  }

  common.dispatch(setCodeObjByCode(objByCode))
  common.dispatch(setCodeObjByDoorClock(objByDoorClock))

  return list;
}


// 技师信息 --- 为"initLoad"服务
biz.employeeForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForEmployeeForInitLoad().then((resultData) => {
      biz.handleForEmployeeForInitLoad(resultData['data'], resultData['cacheAction'])

      // 20210301: 技师动态数据
      biz.employeeTrendsForInitLoad().then(() => {

      })

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 技师信息 --- 为"initLoad"服务
biz.getDataForEmployeeForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('BASIC/employee/basic')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (errorObj.status == 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.employee).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 技师信息 --- 为"initLoad"服务
biz.handleForEmployeeForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.employee, resultData, cacheAction) // 放入缓存

  var arr = biz.handleData(resultData, 4);

  business.employees = arr
}

// 技师动态数据 --- 为"initLoad"服务
biz.employeeTrendsForInitLoad = () => {
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForEmployeeTrendsForInitLoad().then((resultData) => {
      biz.handleForEmployeeTrendsForInitLoad(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 技师动态数据 --- 为"initLoad"服务
biz.getDataForEmployeeTrendsForInitLoad = () => {
  return new Promise((resolve, reject) => {
    common.axios('get', common.apiUrl('SCHEDULE/employee/trends')).then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (errorObj.status == 200) {
        reject(errorObj.error);
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.employee_trends).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  });
}

// 技师信息 --- 为"initLoad"服务
biz.handleForEmployeeTrendsForInitLoad = (resultData, cacheAction) => {
  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.employee_trends, resultData, cacheAction) // 放入缓存

  var employeeList = biz.updateEmployeeTrendsToBasic(resultData); // 将动态状态数据 更新赋值到 基础数据上

  business.employees = biz.handleEmployees(employeeList);

  common.dispatch(setEmployeeList((business.employees)))

  biz.handEmployeeToObjForInit(business.employees); // 将员工数据处理为employee_id为key，员工数据为value的对象
  biz.handEmployeeToObjByNumberForInit(business.employees);
}

//获取到期信息
biz.getExpire = () => {
  //服务到期
  return new Promise((resolve, reject) => {
    common.axios('post', common.apiUrl('support')).then((resultData) => {

      //获取服务到期时间
      var data = resultData;

      //20180327 修改：当前时间的后面5天
      // 当天的日期

      let tempNowDateTime = biz.dateTime()

      var laterTime = tempNowDateTime.date;
      laterTime = new Date(laterTime.replace(/-/g, '/'));
      laterTime.setDate(laterTime.getDate() + 5); //20180327 修改为到期前5天开始提醒
      var BigTime = laterTime.getFullYear() + '-' + parseInt(parseInt(laterTime.getMonth()) + parseInt(1)) + '-' + laterTime.getDate();

      BigTime = BigTime.replace(/-/g, '/');
      BigTime = new Date(BigTime);

      //当前时间
      var nowTime = tempNowDateTime.date;
      nowTime = new Date(nowTime.replace(/-/g, '/'));

      let cloudServiceExpire = false; // 没到期，默认没到期
      for (let i = 0; i < data.length; i++) {
        // 只处理店务管家
        if (data[i]['service'] != business.cloudServiceTypeList.store) {
          continue;
        }

        common.dispatch(setStoreDue(data[i]['expire']))

        //过期时间
        var dueTime = data[i]['expire'];
        dueTime = new Date(dueTime.replace(/-/g, '/'));

        //查看是否未开通，还是未到期
        if (!data[i] || dueTime <= nowTime) {
          cloudServiceExpire = true;
        } else if (dueTime <= BigTime) {
          cloudServiceExpire = true;
        }

        break;
      }

      business.cloudServiceExpire = cloudServiceExpire;

      if (business.cloudServiceExpire) {
        biz.expire();
      }

      resolve();

    }).catch((errorObj) => {
      // 失败

      if (errorObj.status != 200) {
        // 离线模式，屏蔽错误
        if (common.isOfflineMode()) {
          resolve();
          return;
        }
      }

      if (typeof errorObj.error !== "undefined") {
        if (errorObj.error == 'INVALID_TOKEN') {
          // 离线模式，屏蔽错误
          if (common.isOfflineMode()) {
            resolve();
            return;
          }
        }
      }

      console.log(errorObj)

      reject(errorObj.error);
    })
  })
}

// 获取并处理呼叫服务信息
biz.callService = () => {
  // 20190227 根据设置是否可以要通知收银
  // 不需要通知的话，就不需要获取数据了
  if (business.serveFrontNotice != business.serveFrontNoticeList.yes) {
    return;
  }

  // 延时不能删除，防止连续通知

  clearTimeout(noticeMessage.ajaxCallServeTimer);

  noticeMessage.ajaxCallServeTimer = setTimeout(() => {
    // 最大的id
    var maxId = 0;
    for (var i = 0; i < business.call_serve.length; i++) {
      if (maxId < business.call_serve[i]['id']) {
        maxId = business.call_serve[i]['id']
      }
    }

    var url = common.apiUrl('call-serve')

    // todo: 20220124 暂时不启用边缘的呼叫该功能，未审核测试，不能保证
    // if (biz.sortVersion(common.offlineVersion, '20211129') !== 1) {
    //   url = common.apiUrl('FINANCE/call-serve')
    // }

    common.axios('get', url, {
      'minId': maxId
    }).then((resultData) => {

      var tempCallServeData = resultData;

      // 处理数据
      var callServeData = [];

      var i;

      for (i = 0; i < tempCallServeData.length; i++) {
        // 当没有在数据中出现时，添加进去
        var temp = biz.inArrayByColumn('id', tempCallServeData[i]['id'], business.call_serve);

        if (temp) {
          continue;
        }

        callServeData.push(tempCallServeData[i]);
      }

      if (callServeData.length > 0) {
        voice.newServeVideoPlay();
      }

      //界面中显示最新拉取的数据
      let tempCallServeList = [...business.call_serve]
      for (i = 0; i < callServeData.length; i++) {
        tempCallServeList.push(callServeData[i]);
      }
      common.dispatch(setCallServeList(tempCallServeList))

      //打印数据
      print.printCallServe(callServeData);

    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error)
    }).finally(() => {

    })

  }, 1000);
}

// 获取 3分钟内 被确认的呼叫服务
biz.getCallServiceUpdateList = () => {
  // 20190227 根据设置是否可以要通知收银
  // 不需要通知的话，就不需要获取数据了
  if (business.serveFrontNotice != business.serveFrontNoticeList.yes) {
    return;
  }

  // 延时不能删除，防止连续通知

  clearTimeout(noticeMessage.ajaxGetCallServeConfirmListTimer);

  noticeMessage.ajaxGetCallServeConfirmListTimer = setTimeout(() => {
    //待处理的呼叫服务信息
    var url = common.apiUrl('call-serve/update-info')

    // todo: 20220124 暂时不启用边缘的呼叫该功能，未审核测试，不能保证
    // if (biz.sortVersion(common.offlineVersion, '20211129') !== 1) {
    //   url = common.apiUrl('FINANCE/call-serve/update-info')
    // }

    common.axios('get', url, {}).then((resultData) => {

      var callServeData = resultData;

      if (callServeData.length <= 0) {
        return;
      }

      var i;

      let tempCallServeList = [...business.call_serve]

      //界面中显示最新拉取的数据
      for (i = 0; i < callServeData.length; i++) {

        for (let j = 0; j < tempCallServeList.length; j++) {
          if (tempCallServeList[j]['id'] != callServeData[i]['id']) {
            continue;
          }

          tempCallServeList[j] = {
            ...tempCallServeList[j],
            status: callServeData[i]['status'],
            status_string: callServeData[i]['status_string'],
          }
        }
      }

      common.dispatch(setCallServeList(tempCallServeList))

    }).catch((errorObj) => {
      // 失败
      common.toast(errorObj.error);
    }).finally(() => {

    })

  }, 1000);

}

//每隔一个小时提醒一次到期信息
biz.expire = () => {
  if (business.cloudServiceExpire) {
    business.serviceDue.show();

    setTimeout(() => {
      biz.expire();
    }, 1000 * 60 * 60);
  }
}


// 待处理的呼叫服务信息 --- 为"init"API服务
biz.callServeNoHandleInfoForInit = () => {
  return new Promise((resolve, reject) => {
    // 20190227 根据设置是否可以要通知收银

    if (business.serveFrontNotice != business.serveFrontNoticeList.yes) {
      resolve();
      return;
    }

    //待处理的呼叫服务信息
    var url = common.apiUrl('call-serve/no-handle-info')

    // todo: 20220124 暂时不启用边缘的呼叫该功能，未审核测试，不能保证
    // if(biz.sortVersion(common.offlineVersion, '20211129') !== 1) {
    //   url = common.apiUrl('FINANCE/call-serve/no-handle-info')
    // }

    common.axios('get', url, {}, 5000, 'no').then((resultData) => {
      var callServeData = resultData;

      if (callServeData.length > 0) {
        voice.newServeVideoPlay();
      }

      common.dispatch(setCallServeList(callServeData))

      //打印数据
      print.printCallServe(callServeData);

      resolve();
    }).catch((errorObj) => {
      // 失败

      if (errorObj.status != 200) {
        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          resolve()
          return;
        }
      }

      if (typeof errorObj.error !== "undefined") {
        if (errorObj.error == 'INVALID_TOKEN') {
          // 离线模式，屏蔽错误
          if (common.isOfflineMode()) {
            resolve();
            return;
          }
        }
      }

      reject(errorObj.error);
    }).finally(() => {

    })
  });
}


//判断一个值是否在二维数组中出现
biz.inArrayByColumn = (key, value, arr, conditionNotKey, conditionNotValue) => {
  //不等的条件
  conditionNotKey = conditionNotKey || false;
  conditionNotValue = conditionNotValue || false;

  if (arr == undefined) {
    return false;
  }

  if (!arr) {
    return false;
  }

  for (var i = 0; i < arr.length; i++) {

    var temp = arr[i][key];

    //将前面的“0”去掉
    if (key == 'number' && typeof temp == 'string') {
      temp = temp.replace(/\b(0+)/gi, "");

      if (typeof value == 'string') {
        value = value.replace(/\b(0+)/gi, "");
      }
    }

    if (value == temp) {

      //判断不等的条件
      if (conditionNotKey && conditionNotValue) {
        if (arr[i][conditionNotKey] == conditionNotValue) {
          continue;
        }
      }

      return arr[i];
    }
  }

  return false;
}

//在二维数组根据某一个特定键值，返回匹配的数据  数组
biz.getDataInArrayByColumn = (key, value, arr, conditionNotKey, conditionNotValue) => {
  //不等的条件
  conditionNotKey = conditionNotKey || false;
  conditionNotValue = conditionNotValue || false;

  var data = [];

  for (var i = 0; i < arr.length; i++) {

    var temp = arr[i][key];

    //将前面的“0”去掉
    if (key == 'number' && typeof temp == 'string') {
      temp = temp.replace(/\b(0+)/gi, "");

      if (typeof value == 'string') {
        value = value.replace(/\b(0+)/gi, "");
      }
    }

    if (value == temp) {

      //判断不等的条件
      if (conditionNotKey && conditionNotValue) {
        if (arr[i][conditionNotKey] == conditionNotValue) {
          continue;
        }
      }

      data.push(arr[i]);
    }
  }

  return data;
}

//从数组中筛选符合条件的数据
biz.filter = (key, value, arr) => {
  var data = [];
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][key] == value) {
      data.push(arr[i]);
    }
  }

  return data;
}

//根据某一个键值将数组对应的值移除
biz.removeArrayByColumn = (key, value, arr) => {
  for (var i = 0; i < arr.length; i++) {
    var temp = arr[i][key];

    //将前面的“0”去掉
    if (key == 'number' && typeof temp == 'string') {
      temp = temp.replace(/\b(0+)/gi, "");

      if (typeof value == 'string') {
        value = value.replace(/\b(0+)/gi, "");
      }
    }

    if (value == temp) {
      arr.splice(i, 1);
      break;
    }
  }
}

//根据值将数组对应的值移除
biz.removeArrayByValue = (arr, value) => {

  if (arr && arr.length > 0) {
    for (var i = 0; i < arr.length; i++) {

      if (arr[i] == value) {
        arr.splice(i, 1);
        break;
      }
    }
  }

}

// 判断一个值，在一个对象的key是否出现
biz.indexOfReturnInObj = (key, obj, conditionNotKey, conditionNotValue, keyType) => {
  if (obj == undefined) {
    return false;
  }

  keyType = keyType || ""

  //将前面的“0”去掉 -- 编号，将前面的0去掉查找
  if (keyType == 'number' && (typeof key == 'string')) {
    key = key.replace(/\b(0+)/gi, "");
  }

  // key不存在
  if (!(key in obj)) {
    return false;
  }

  conditionNotKey = conditionNotKey || false;
  conditionNotValue = conditionNotValue || false;

  // 值: 不应该相同的key的值相同了，说明不符合条件
  if (conditionNotKey && conditionNotValue) {
    if (obj[key][conditionNotKey] == conditionNotValue) {
      return false;
    }
  }

  return {...obj[key]}
}

//从对象中筛选符合条件的数据
biz.filterByObj = (key, value, obj) => {
  var data = {};
  for (var i in obj) {
    if (obj[i][key] == value) {
      data[i] = obj[i];
    }
  }

  return data;
}


//判断一个值是否在对象中的数据中对应的key上出现
biz.inObjByColumn = (key, value, obj, conditionNotKey, conditionNotValue) => {
  //不等的条件
  conditionNotKey = conditionNotKey || false;
  conditionNotValue = conditionNotValue || false;

  if (obj == undefined) {
    return false;
  }

  if (!obj) {
    return false;
  }

  for (var i in obj) {

    var temp = obj[i][key];

    //将前面的“0”去掉
    if (key == 'number' && typeof temp == 'string') {
      temp = temp.replace(/\b(0+)/gi, "");

      if (typeof value == 'string') {
        value = value.replace(/\b(0+)/gi, "");
      }
    }

    if (value == temp) {

      //判断不等的条件
      if (conditionNotKey && conditionNotValue) {
        if (obj[i][conditionNotKey] == conditionNotValue) {
          continue;
        }
      }

      return obj[i];
    }
  }

  return false;
}

// 将对象转为数组
biz.objToArr = (obj) => {
  var arr = []

  for (let i in obj) {
    arr.push(obj[i])
  }

  return arr
}

//解决精度问题
biz.formatFloat = (f, digit) => {
  if (isNaN(f) == true) {
    return f;
  }

  if (digit.length == 0) {
    digit = 2;
  }

  //用math处理一下
  f = window.math.format(f, {precision: 14});
  f = new Number(f.toString()) // 禁止使用科学计数法

  if (digit == 0) {
    var m = Math.pow(10, digit);
    var result = parseInt(f * m, 10) / m;
  } else {
    var m = Math.pow(10, digit);

    var temp = f.toString().replace('.', '');

    var tempA = temp.length - parseInt(f).toString().length;
    if (tempA < digit) {
      //没有达到长度，补足
      for (var i = 0; i < (digit - tempA); i++) {
        temp = temp.toString() + "0";
      }
    } else {
      //超过之后，裁剪
      var tempLength = temp.length - (tempA - digit);
      temp = temp.substring(0, tempLength);
    }

    var result = parseInt(temp, 10) / m;
  }

  return biz.handleNumber(result, digit);

}


//处理为几位小数显示
biz.handleNumber = function (number, digit) {
  //转化为字符串
  number = number + "";

  //处理默认小数位数
  if (digit.length == 0) {
    digit = 2;
  }

  //如果digit为0
  if (digit == 0) {
    return parseInt(number);
  }

  //查找小数位数
  var position = number.indexOf('.'); //小数点起始位置

  //判断是否含有小数点，没有小数点，直接判断小数保留位数
  if (position < 0) {
    number = number + ".";
    position = number.length;
  } else {
    position = position + 1;
  }

  number = number + "00000000000000";

  return number.substring(0, position + digit);
}

// 处理排钟数据
biz.handleForAppointOrderInit = (resultData) => {
  let dispatch = common.dispatch

  dispatch(setAppointOrderList(resultData))

  // 根据技师分组
  var obj = {}

  for (let i = 0; i < resultData.length; i++) {
    let item = resultData[i]

    let employeeId = item['employee_id']

    // 没有技师，跳过
    if (!employeeId) {
      continue;
    }

    if (employeeId in obj) {
      obj[employeeId].push(item)
    } else {
      obj[employeeId] = [item]
    }
  }

  dispatch(setAppointOrderListByEmployeeId(obj))

  // 根据房间分组
  var objByRoom = {}

  for (let i = 0; i < resultData.length; i++) {
    let item = resultData[i]

    let roomId = item['room_id']

    // 没有技师，跳过
    if (!roomId) {
      continue;
    }

    if (roomId in objByRoom) {
      objByRoom[roomId].push(item)
    } else {
      objByRoom[roomId] = [item]
    }
  }

  dispatch(setAppointOrderListByRoomId(objByRoom))
}


// 获取待提醒数据
biz.getWaitRemind = () => {
  //收银待提醒数据
  return new Promise((resolve, reject) => {
    // 获取数据
    biz.getDataForGetWaitRemind().then((resultData) => {
      biz.handleForGetWaitRemind(resultData['data'], resultData['cacheAction'])

      resolve()
    }).catch((error) => {
      reject(error)
    })
  });
}

// 获取待提醒数据-获取数据
biz.getDataForGetWaitRemind = () => {
  //收银待提醒数据
  return new Promise((resolve, reject) => {
    common.axios('post', common.apiUrl('remind/wait'), {}, 5000, 'no').then((result) => {
      resolve({'data': result, 'cacheAction': 1}); // 成功
    }).catch((errorObj) => {
      // 失败
      if (parseInt(errorObj.status) === 200) {
        if (typeof errorObj.code !== "undefined") {
          if (errorObj.code = 'DEGRADE') {
            // 被降级了，不提示报错
            resolve({'data': [], 'cacheAction': 0})
            return;
          }
        }

        // 20210615: 离线模式下，屏蔽错误
        if (common.isOfflineMode()) {
          resolve({'data': [], 'cacheAction': 0})
          return;
        }

        reject(errorObj.error);
        return;
      }

      // 20210615: 离线模式下，屏蔽错误
      if (common.isOfflineMode()) {
        resolve({'data': [], 'cacheAction': 0})
        return;
      }

      indexedDB.getListForStoreNameByIndexedDb(indexedDB.indexedDBStoreNameList.front_remind).then((resultData) => {
        resolve({'data': resultData['data'], 'cacheAction': 0})
      }).catch(() => {
        reject(errorObj.error  + "[" + errorObj.status + "]");
      })
    })
  })
}

// 获取待提醒数据-处理数据
biz.handleForGetWaitRemind = (resultData, cacheAction) => {
  let dispatch = common.dispatch

  indexedDB.putAllIntoForStoreNameByIndexedDB(indexedDB.indexedDBStoreNameList.front_remind, resultData, cacheAction) // 放入缓存

  dispatch(setFrontWaitRemindData(resultData))
}

// 为 技师数据 将动态的状态相关数据 更新赋值上去
biz.updateEmployeeTrendsToBasic = (statusList) => {
  // 转成已技师ID为key的对象，方便下方循环给到技师上
  var statusObj = {}

  for (let i = 0; i < statusList.length; i++) {
    statusObj[statusList[i]['employee_id']] = statusList[i]
  }

  // 将 技师的动态数据 替换到对应的 技师数据上
  var employeeList = [...business.employees]

  for (let i = 0; i < employeeList.length; i++) {
    let employeeId = employeeList[i]['employee_id'];

    if (typeof statusObj[employeeId] === "undefined") {
      continue;
    }

    employeeList[i] = {...employeeList[i], ...statusObj[employeeId]};
  }

  return employeeList;
}

//处理技师信息
biz.handleEmployees = (data) => {
  for (var i = 0; i < data.length; i++) {
    if (data[i]['type'] != business.employeeTypeList.employee) {
      continue;
    }

    if (data[i]['appoint_order_clock_info']) {
      for (var j = 0; j < data[i]['appoint_order_clock_info'].length; j++) {
        data[i]['appoint_order_clock_info'][j]['clock_name'] = data[i]['appoint_order_clock_info'][j]['clock_name'].substring(0, 1);
      }
    }

    // 是否为优先排钟
    var checkClockPrior = 0

    if (data[i]['status_list']['clock_status'] == business.employeeClockStatusList.prior) {
      checkClockPrior = 1
    }

    data[i].check_clock_prior = checkClockPrior

    // 将标签排钟数据，处理为标签id为key的数据
    data[i].employee_clock_info_obj = {}
    if (typeof data[i]['employee_clock_info'] !== "undefined") {
      for (let k = 0; k < data[i]['employee_clock_info'].length; k++) {
        let tempClockInfo = data[i]['employee_clock_info'][k]

        data[i]['employee_clock_info_obj'][tempClockInfo['tag_id']] = tempClockInfo
      }
    }
  }

  return data
}


// 将技师数据处理为ID为key，即时数据为value的对象
biz.handEmployeeToObjForInit = (list) => {
  var obj = {}

  for (let i = 0; i < list.length; i++) {
    obj[list[i]['employee_id']] = list[i]
  }

  common.dispatch(setEmployeeObjByEmployeeId(obj))
}

// 将技师数据处理为number为key，即时数据为value的对象
biz.handEmployeeToObjByNumberForInit = (list) => {
  var obj = {}

  for (let i = 0; i < list.length; i++) {
    obj[parseInt(list[i]['number'])] = list[i]
  }

  common.dispatch(setEmployeeObjByNumber(obj))
}


//获取营业日
biz.getBusinessDate = (time) => {
  var morningTime = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;

  var openTime = morningTime + business.openTime;

  //确定时间   如果当前时间比营业开始时间早，则算成前一天
  if (time < openTime) {
    time = morningTime - 1;
  }

  var timeData = biz.handleTime(time);

  business.businessDate = timeData.year + '-' + timeData.month + '-' + timeData.day;
}


//处理为年月日时分秒
biz.handleTime = (time) => {
  var today, year, month, day, hour, minute, second, weekday, strDate;
  today = new Date(parseInt(time) * 1000);
  weekday = today.getDay();
  switch (weekday) {
    case 0: {
      strDate = "星期日";
    }
      break;
    case 1: {
      strDate = "星期一";
    }
      break;
    case 2: {
      strDate = "星期二";
    }
      break;
    case 3: {
      strDate = "星期三";
    }
      break;
    case 4: {
      strDate = "星期四";
    }
      break;
    case 5: {
      strDate = "星期五";
    }
      break;
    case 6: {
      strDate = "星期六";
    }
      break;
  }
  year = today.getFullYear();
  month = today.getMonth() + 1;
  day = today.getDate();
  hour = today.getHours();
  minute = today.getMinutes();
  second = today.getSeconds();
  if (month.toString().length < 2) month = "0" + month;
  if (day.toString().length < 2) day = "0" + day;
  if (hour.toString().length < 2) hour = "0" + hour;
  if (minute.toString().length < 2) minute = "0" + minute;
  if (second.toString().length < 2) second = "0" + second;
  var clock = hour + ":" + minute + ":" + second;

  return {
    weekString: strDate,
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second
  };
}


biz.saveUserInfo = (data) => {
  common.dispatch(setUserInfo(data))
  window.localStorage.setItem("user_info", JSON.stringify(data))
}

biz.getUserInfo = () => {
  var data = null;

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      data = window.localStorage.getItem("user_info")

      data = JSON.parse(data)
    }
  }

  return data;
}

// 保存底部技师的展示的标签
biz.saveBottomShowTagObj = (data) => {
  let userInfo = biz.getUserInfo()
  if(!userInfo) {
    common.toast("门店信息有误")
    return false;
  }

  if((typeof userInfo['user_id'] === "undefined") || (!userInfo['user_id'])) {
    common.toast("门店信息异常")
    return false;
  }

  let userId = userInfo['user_id'];

  let tempObj = window.localStorage.getItem("bottomShowTagObj")
  if(!tempObj) {
    tempObj = {}
  } else {
    try {
      tempObj = JSON.parse(tempObj)
    } catch (e) {
      common.toast("保存失败:" + e.toString())
      return false;
    }
  }

  tempObj[userId] = data;

  let tempKeys = Object.keys(tempObj)

  // 只保留最后10个门店
  if(tempKeys.length > 10) {
    // 删掉第一个
    delete tempObj[tempKeys[0]]
  }

  window.localStorage.setItem("bottomShowTagObj", JSON.stringify(tempObj))
  common.dispatch(setBottomShowTagObj(data))

  return true;
}

// 获取底部展示的技师标签
biz.getBottomShowTagObj = (userId) => {
  var obj = {};

  if (typeof window !== "undefined") {
    if (typeof window.localStorage !== "undefined") {
      let data = window.localStorage.getItem("bottomShowTagObj")
      if(data) {
        try {
          data = JSON.parse(data)

          if(typeof data[userId] !== "undefined") {
            obj = data[userId]
          }
        } catch (e) {
          console.log("解析底部展示技师的标签失败:" + e.toString())
        }
      }
    }
  }

  return obj;
}

//将数组中编号处理为三位显示
biz.leftZeroPadArr = function (arr) {
  if (arr.length <= 0) {
    return arr;
  }

  // 没有编号则直接返回
  if (typeof arr[0]['number'] === "undefined") {
    return arr;
  }

  for (var i = 0; i < arr.length; i++) {
    arr[i]['number'] = biz.leftZeroPad(arr[i]['number'], 3);
  }

  return arr;
}

var MANY_ZEROS = "000000000000000000";

//左填充"0"
biz.leftZeroPad = function (val, minLength) {
  if (typeof (val) != "string")
    val = String(val);
  return (MANY_ZEROS.substring(0, minLength - val.length)) + val;
}

// json_encode，处理了中文
biz.jsonEncode = (text) => {
  var json = JSON.stringify(text)
  json = json.replace(/[\u007F-\uFFFF]/g, function (chr) {
    return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)
  })

  return json
}

// 对比版本号 a、b  a>b: -1; a == b: 0; a < b; 1
biz.sortVersion = (a, b) => {
  a = a.toString()
  b = b.toString()

  var sources = a.split('.')
  var dests = b.split('.')
  var minL = Math.min(sources.length, dests.length)

  var r = null

  for (let i = 0; i < minL; i++) {
    if (sources[i] < dests[i]) {
      r = 1
      break
    } else if (sources[i] > dests[i]) {
      r = -1
      break
    } else if (i === minL && sources[i] === dests[i]) {
      r = 0
    }
  }
  return r
}

biz.cutStr = (str, len, supply) => {
  supply = supply == undefined ? true : supply;

  var str_length = 0;
  var str_len = 0;
  var str_cut = new String();
  str_len = str.length;
  for (var i = 0; i < str_len; i++) {
    var a = str.charAt(i);
    str_length++;
    if (escape(a).length > 4) {
      //中文字符的长度经编码之后大于4
      //str_length++;
    }
    str_cut = str_cut.concat(a);

    if (str_length >= len) {

      if (supply) {
        str_cut = str_cut.concat("...");
        return str_cut;
      } else {
        return str_cut;
      }

    }
  }
  //如果给定字符串小于指定长度，则返回源字符串；
  if (str_length < len) {
    return str;
  }
}

// 获取当前时间
biz.getNowTime = () => {
  return parseInt(new Date().getTime() / 1000);
}

biz.isNumber = (str) => {
  var reg = /^\d+$/;
  return reg.test(str);
}


//对比两个数是否相等 这里注意如果是编号对比，请排除编号之前的0
biz.checkEqual = (newValue, oldValue, number) => {
  number = number || false;

  if (number && typeof oldValue == 'string') {
    oldValue = oldValue.replace(/\b(0+)/gi, "");

    if (typeof newValue == 'string') {
      newValue = newValue.replace(/\b(0+)/gi, "");
    }
  }

  return (newValue === oldValue);
}

//排除重复值
biz.uniqueArray = (a) => {
  if (!a) {
    return [];
  }

  var arr = [];
  for (var i = 0; i < a.length; i++) {
    if (arr.indexOf(a[i]) === -1) {
      arr.push(a[i])
    }
  }

  return arr;
}


// 精度加法或减法
biz.calcPlusOrMinus = function (num1, num2, symbol) {
  symbol = symbol || 'add'

  var r1, r2, m;
  try {
    r1 = num1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = num2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }

  m = Math.pow(10, Math.max(r1, r2));

  //减法
  if (symbol === 'reduce') {
    return Math.round(biz.calcMul(num1, m) - biz.calcMul(num2, m)) / m
  }

  //加法
  return Math.round(biz.calcMul(num1, m) + biz.calcMul(num2, m)) / m
}

// 精度乘法
biz.calcMul = function (arg1, arg2) {
  var r1 = arg1 + '', r2 = arg2 + '', r3 = 0, r4 = 0;
  try {
    r3 = r1.split('.')[1].length;
  } catch (err) {
    r3 = 0;
  }
  try {
    r4 = r2.split('.')[1].length;
  } catch (err) {
    r4 = 0;
  }
  return Number(r1.replace('.', '')) * Number(r2.replace('.', '')) / Math.pow(10, r4 + r3);
}

/**
 * 手机验证
 *
 * 示例
 * biz.isMobile('13888888888');
 *
 * @param str
 * @returns boolean
 */
biz.isMobile = function (str) {
  var reg = /^1\d{10}$/;
  return reg.test(str);
}

// 判断名称和编号是否相符
biz.checkNameEqualName = function(name, number) {
  if(!name) {
    // 没有名称，则不相等
    return false;
  }

  // isNaN() 函数用于检查其参数是否是非数字值。
  // 如果参数值为 NaN 或字符串、对象、undefined等非数字值则返回 true, 否则返回 false。

  // 名字不是纯数字
  if(isNaN(name) === true) {
    return false;
  }

  // 纯数字的，判断
  let tempName = parseInt(name)
  let tempNumber = parseInt(number)

  return tempName === tempNumber
}

biz.plus = (a, b, len=2) => {
  return new BigNumber(a).plus(new BigNumber(b)).toFixed(len)
}

biz.minus = (a, b, len = 2) => {
  return new BigNumber(a).minus(new BigNumber(b)).toFixed(len)
}

biz.multipliedBy = (a,b,len=2) => {
  return new BigNumber(a).multipliedBy(new BigNumber(b)).toFixed(len);
}

biz.divide = (a,b,len=2) => {
  return new BigNumber(a).dividedBy(new BigNumber(b)).toFixed(len);
}

// 获取唯一字符串
biz.uuid = () => {
  let str = uuidv4()
  let once = str.replace(/-/g, '')

  return once;
}

export default biz;